<template>
    <div v-if="RandomAnime[3]" class="random-anime">
        <div class="random-anime_header">
            <p>Случайные аниме</p>
            <font-awesome-icon class="random-anime_header_upload" @click="randomAnime()" icon="fa-solid fa-rotate-right" />
        </div>
        <div class="random-anime_body">
            <div v-if="RandomAnime[0]" class="random-anime_body_main-anime">
                <router-link :to="'/view/anime-' + RandomAnime[0].id">
                    <img v-if="RandomAnime[0].screenshots" :src="RandomAnime[0].screenshots[0]" alt="Фрагмент из аниме" draggable="false">
                    <img v-else src="../../assets/images/MainView/RandomAnime/ScreenNone.png" alt="Фрагмент из аниме" draggable="false">
                </router-link>
                <p v-if="RandomAnime[0].enshi_rating >= 7" class="random-anime_body_main-anime_rating green-rating">
                    <img src="../../assets/images/MainView/RandomAnime/rating_icon.svg" alt="Иконка рейтинга Enshi" draggable="false">
                    {{ RandomAnime[0].enshi_rating }}
                </p>
                <p v-else-if="RandomAnime[0].enshi_rating >= 0" class="random-anime_body_main-anime_rating orange-rating">
                    <img src="../../assets/images/MainView/RandomAnime/rating_icon.svg" alt="Иконка рейтинга Enshi" draggable="false">
                    {{ RandomAnime[0].enshi_rating }}
                </p>
                <p class="random-anime_body_main-anime_title">{{ RandomAnime[0].title }}</p>
                <div class="random-anime_body_main-anime_description">
                    <p>
                        Год:
                        <span>
                            {{ RandomAnime[0].year }} г.
                        </span>
                    </p>
                    <p>
                        Эпизоды:
                        <span>
                            {{ RandomAnime[0].episodes_count }}
                        </span>
                    </p>
                </div>
                <router-link :to="'/view/anime-' + RandomAnime[0].id" class="random-anime_body_main-anime_link">
                    Смотреть
                    <font-awesome-icon icon="fa-solid fa-play" />
                </router-link>
            </div>
            <div v-if="RandomAnime[1]" class="random-anime_body_other-anime">
                <div class="random-anime_body_other-anime_item">
                    <div>
                        <p v-if="RandomAnime[1].enshi_rating >= 7" class="random-anime_body_other-anime_item_rating green-rating">
                            <img src="../../assets/images/MainView/RandomAnime/rating_icon.svg" alt="Иконка рейтинга Enshi" draggable="false">
                            {{ RandomAnime[1].enshi_rating }}
                        </p>
                        <p v-else-if="RandomAnime[1].enshi_rating >= 0" class="random-anime_body_other-anime_item_rating orange-rating">
                            <img src="../../assets/images/MainView/RandomAnime/rating_icon.svg" alt="Иконка рейтинга Enshi" draggable="false">
                            {{ RandomAnime[1].enshi_rating }}
                        </p>
                        <router-link :to="'/view/anime-' + RandomAnime[1].id">
                            <img v-if="RandomAnime[1].screenshots" :src="RandomAnime[1].screenshots[0]" alt="Фрагмент из аниме" draggable="false">
                            <img v-else src="../../assets/images/MainView/RandomAnime/ScreenNone.png" alt="Фрагмент из аниме" draggable="false">
                        </router-link>
                    </div>
                    <div class="random-anime_body_other-anime_item_description">
                        <p class="random-anime_body_other-anime_item_description_title">{{ RandomAnime[1].title }}</p>
                        <div class="random-anime_body_other-anime_item_description_year-and-episodes">
                            <p>
                                Год: <span> {{ RandomAnime[1].year }} г.</span>
                            </p>
                            <p>
                                Эпизоды: <span> {{ RandomAnime[1].episodes_count }}</span>
                            </p>
                        </div>
                        <router-link :to="'/view/anime-' + RandomAnime[1].id" class="random-anime_body_other-anime_item_link">
                            Смотреть
                            <font-awesome-icon icon="fa-solid fa-play" />
                        </router-link>
                    </div>
                </div>

                <div v-if="RandomAnime[2]" class="random-anime_body_other-anime_item">
                    <div>
                        <p v-if="RandomAnime[2].enshi_rating >= 7" class="random-anime_body_other-anime_item_rating green-rating">
                            <img src="../../assets/images/MainView/RandomAnime/rating_icon.svg" alt="Иконка рейтинга Enshi" draggable="false">
                            {{ RandomAnime[2].enshi_rating }}
                        </p>
                        <p v-else-if="RandomAnime[2].enshi_rating >= 0" class="random-anime_body_other-anime_item_rating orange-rating">
                            <img src="../../assets/images/MainView/RandomAnime/rating_icon.svg" alt="Иконка рейтинга Enshi" draggable="false">
                            {{ RandomAnime[2].enshi_rating }}
                        </p>
                        <router-link :to="'/view/anime-' + RandomAnime[2].id">
                            <img v-if="RandomAnime[2].screenshots" :src="RandomAnime[2].screenshots[0]" alt="Фрагмент из аниме" draggable="false">
                            <img v-else src="../../assets/images/MainView/RandomAnime/ScreenNone.png" alt="Фрагмент из аниме" draggable="false">
                        </router-link>
                    </div>
                    <div class="random-anime_body_other-anime_item_description">
                        <p class="random-anime_body_other-anime_item_description_title"> {{ RandomAnime[2].title }} </p>
                        <div class="random-anime_body_other-anime_item_description_year-and-episodes">
                            <p>
                                Год: <span> {{ RandomAnime[2].year }} г.</span>
                            </p>
                            <p>
                                Эпизоды: <span> {{ RandomAnime[2].episodes_count }}</span>
                            </p>
                        </div>
                        <router-link :to="'/view/anime-' + RandomAnime[2].id" class="random-anime_body_other-anime_item_link">
                            Смотреть
                            <font-awesome-icon icon="fa-solid fa-play" />
                        </router-link>
                    </div>
                </div>

                <div v-if="RandomAnime[3]" class="random-anime_body_other-anime_item">
                    <div>
                        <p v-if="RandomAnime[3].enshi_rating >= 7" class="random-anime_body_other-anime_item_rating green-rating">
                            <img src="../../assets/images/MainView/RandomAnime/rating_icon.svg" alt="Иконка рейтинга Enshi" draggable="false">
                            {{ RandomAnime[3].enshi_rating }}
                        </p>
                        <p v-else-if="RandomAnime[3].enshi_rating >= 0" class="random-anime_body_other-anime_item_rating orange-rating">
                            <img src="../../assets/images/MainView/RandomAnime/rating_icon.svg" alt="Иконка рейтинга Enshi" draggable="false">
                            {{ RandomAnime[3].enshi_rating }}
                        </p>
                        <router-link :to="'/view/anime-' + RandomAnime[3].id">
                            <img v-if="RandomAnime[3].screenshots" :src="RandomAnime[3].screenshots[0]" alt="Фрагмент из аниме" draggable="false">
                            <img v-else src="../../assets/images/MainView/RandomAnime/ScreenNone.png" alt="Фрагмент из аниме" draggable="false">
                        </router-link>
                    </div>
                    <div class="random-anime_body_other-anime_item_description">
                        <p class="random-anime_body_other-anime_item_description_title"> {{ RandomAnime[3].title }} </p>
                        <div class="random-anime_body_other-anime_item_description_year-and-episodes">
                            <p>
                                Год: <span> {{ RandomAnime[3].year }} г.</span>
                            </p>
                            <p>
                                Эпизоды: <span>{{ RandomAnime[3].episodes_count }}</span>
                            </p>
                        </div>
                        <router-link :to="'/view/anime-' + RandomAnime[3].id" class="random-anime_body_other-anime_item_link">
                            Смотреть
                            <font-awesome-icon icon="fa-solid fa-play" />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'RandomAnime',
    components: {

    },
    data() {
        return {
            RandomAnime: [],
        }
    },
    created() {
        axios.get(this.$store.getters.BACKENDURL + '/api/randomanime/').then((response) => {
            this.RandomAnime = response.data;
        }).catch((errors) => {
            console.log(errors);
        });
    },
    methods: {
        randomAnime()
        {
            axios.get(this.$store.getters.BACKENDURL + '/api/randomanime/').then((response) => {
                this.RandomAnime = response.data;
            }).catch((errors) => {
                console.log(errors);
            });
        }
    }
}
</script>

<style lang="sass" scoped>
    .random-anime
        margin-top: 100px
        &_header
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            p
                color: rgb(245, 245, 245)
                font-family: Montserrat
                font-size: 23px
                font-weight: 600
                line-height: 28px
                letter-spacing: 4%
                text-align: left
            &_upload
                color: rgb(180, 180, 180)
                width: 18px
                height: 18px
                padding: 13px 15px
                border-radius: 5px
                background: rgb(45, 45, 45)
                cursor: pointer
                transition: 0.3s
            &_upload:active,
            &_upload:hover
                background: #4D4D4D
        &_body
            margin-top: 35px
            display: flex
            gap: 20px
            &_main-anime
                img
                    width: 618px
                    height: 306px
                    border-radius: 10px
                    object-fit: cover
                a
                    border-radius: 10px
                    transition: 0.2s
                a:active,
                a:hover
                    background-color: rgb(177, 126, 255)
                &_rating
                    color: rgb(255, 255, 255)
                    background: rgb(140, 83, 253)
                    font-family: Montserrat
                    font-size: 16px
                    font-weight: 600
                    line-height: 20px
                    letter-spacing: 2%
                    text-align: left
                    display: flex
                    justify-content: center
                    gap: 10px
                    border-radius: 0px 10px 0px 10px
                    width: 120px
                    height: 50px
                    align-items: center
                    margin-top: -54px
                    position: relative
                    img
                        width: 22px
                        height: 22px
                &_title
                    margin-top: 26px
                    color: rgb(245, 245, 245)
                    font-family: Montserrat
                    font-size: 28px
                    font-weight: 600
                    line-height: 34px
                    letter-spacing: 4%
                    text-align: left
                    -webkit-line-clamp: 1
                    overflow: hidden
                    text-overflow: ellipsis
                    display: -webkit-box
                    -webkit-box-orient: vertical
                &_description
                    display: flex
                    flex-direction: row
                    font-family: Montserrat
                    font-size: 14px
                    font-weight: 500
                    line-height: 17px
                    letter-spacing: 0%
                    text-align: left
                    gap: 25px
                    margin-top: 7px
                    p
                        color: #9B9B9D
                        span
                            color: #FFFFFF
                &_link
                    text-decoration: none
                    color: rgb(255, 255, 255)
                    font-family: Montserrat
                    font-size: 15px
                    font-weight: 600
                    line-height: 18px
                    letter-spacing: 6%
                    text-align: left
                    border-radius: 5px
                    background: rgb(140, 83, 253)
                    width: 154px
                    height: 44px
                    display: flex
                    gap: 7px
                    flex-direction: row
                    justify-content: center
                    align-items: center
                    margin-top: 20px
                    transition: 0.2s
                &_link:active,
                &_link:hover
                    background: #9370d8
            &_other-anime
                display: flex
                flex-direction: column
                gap: 9px
                &_item
                    display: flex
                    gap: 20px
                    img
                        width: 326px
                        height: 146px
                        border-radius: 10px
                        transition: 0.2
                        object-fit: cover
                    a
                        border-radius: 10px
                        transition: 0.2s
                    a:active,
                    a:hover
                        background-color: rgb(177, 126, 255)
                    &_rating
                        background: rgb(140, 83, 253)
                        color: rgb(255, 255, 255)
                        font-family: Montserrat
                        font-size: 14px
                        font-weight: 600
                        line-height: 17px
                        letter-spacing: 2%
                        border-radius: 0px 10px 0px 10px
                        width: 100px
                        height: 32px
                        display: flex
                        flex-direction: row
                        justify-content: center
                        align-items: center
                        gap: 10px
                        margin-bottom: -32px
                        margin-left: auto
                        position: relative
                        img
                            width: 20px
                            height: 20px
                    &_description
                        &_title
                            -webkit-line-clamp: 1
                            overflow: hidden
                            text-overflow: ellipsis
                            display: -webkit-box
                            -webkit-box-orient: vertical
                            color: rgb(245, 245, 245)
                            font-family: Montserrat
                            font-size: 20px
                            font-weight: 600
                            line-height: 24px
                            letter-spacing: 4%
                            text-align: left
                        &_year-and-episodes
                            font-family: Montserrat
                            font-size: 14px
                            font-weight: 500
                            line-height: 17px
                            letter-spacing: 0%
                            display: flex
                            gap: 20px
                            margin-top: 7px
                            p
                                color: #9B9B9D
                                span
                                    color: #FFFFFF
                    &_link
                        color: rgb(255, 255, 255)
                        font-family: Montserrat
                        font-size: 12px
                        font-weight: 600
                        line-height: 15px
                        letter-spacing: 6%
                        text-decoration: none
                        box-sizing: border-box
                        border: 0.85px solid rgb(177, 126, 255)
                        border-radius: 17px
                        width: 130px
                        height: 35px
                        display: flex
                        justify-content: center
                        align-items: center
                        gap: 7px
                        margin-top: 20px
                        transition: 0.3s
                    &_link:active,
                    &_link:hover
                        background-color: rgb(177, 126, 255)

@media (max-width: 1200px)
    .random-anime
        padding: 0px 25px
        &_body
            flex-wrap: wrap
            justify-content: center
            gap: 45px
            &_main-anime
                width: 100%
                max-width: 718px
                img
                    width: 100%
                    max-width: 718px
                &_rating
                    img
                        width: 22px
            &_other-anime
                display: flex
                width: 100%
                max-width: 718px
                justify-content: flex-start
                gap: 30px

@media (max-width: 590px)
    .random-anime
        margin-top: 52px
        padding: 0px 10px
        &_header
            p
                font-size: 18px
                line-height: 22px
            &_upload
                padding: 7px 7px
        &_body
            margin-top: 27px
            gap: 36px
            &_main-anime
                img
                    height: 230px
                &_rating
                    font-size: 12px
                    line-height: 15px
                    width: 90px
                    height: 38px
                    margin-top: -42px
                    img
                        width: 16px
                        height: 16px
                &_title
                    margin-top: 20px
                    font-size: 18px
                    line-height: 22px
                &_description
                    margin-top: 5px
                    font-size: 12px
                    line-height: 15px
                &_link
                    width: 110px
                    height: 31px
                    border-radius: 5px
                    font-size: 10px
                    line-height: 12px
            &_other-anime
                gap: 20px
                &_item
                    img
                        width: 171px
                        height: 109px
                    &_rating
                        width: 75px
                        height: 24px
                        margin-bottom: -24px
                        line-height: 15px
                        font-size: 12px
                        img
                            width: 16px
                            height: 16px
                    &_description
                        &_title
                            font-size: 15px
                            line-height: 18px
                        &_year-and-episodes
                            font-size: 12px
                            line-height: 15px
                            flex-wrap: wrap
                            gap: 15px
                    &_link
                        margin-top: 13.88px
                        width: 97.5px
                        height: 26.25px
                        font-size: 9px
                        line-height: 11px
                        border: 0.64px solid rgb(177, 126, 255)
                        border-radius: 12.75px

</style>