<template>
    <div v-if="user !== ''">
        <div v-if="user.cover" :style="`background-image: url(${user.cover}`" class="cover">

        </div>
        <div v-else class="cover">

        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'AccountCover',
    components: {

    },
    data() {
        return {
            user: '',
        }
    },
    mounted() {
        axios.get(this.$store.getters.BACKENDURL + '/api/userview/' + this.$route.params.id).then((response) => {
            this.user = response.data;
            this.$emit('closeLoader', false);
        }).catch((errors) => {
            console.log(errors);
            this.$router.push('/404')
        });
    }
}
</script>

<style lang="sass" scoped>
    .cover
        opacity: 0.8
        height: 300px
        left: 0px
        width: 100%
        background-size: cover
        background-position: 50%!important
        position: absolute
        pointer-events: none
        background-image: url(../../assets/images/AccountView/AccountCoverExample.png)
        clip: rect(20px, 90%, 30px, 10%)
        z-index: -1

@media (max-width: 850px)
    .cover
        height: 180px
</style>