<script>
  import axios from "axios";
  import LasCommentsList from "@/components/LastComments/LasCommentsList.vue";

  export default {
    name: 'LastComments',
    components: {LasCommentsList},
    data() {
      return {
        data_comments: []
      }
    },
    created() {
      axios.get(this.$store.getters.BACKENDURL + '/api/last_comments/').then((response) => {
        this.data_comments = response.data.comments;
      }).catch((errors) => {
        console.log(errors);
      });
    }
  }
</script>

<template>
<section id="last_comments" class="LastComments">
    <div class="LastComments__container">
      <div class="LastComments__innerWrap">
        <h3>Последние комментарии</h3>
        <LasCommentsList :data="data_comments" />
      </div>
    </div>
</section>
</template>

<style lang="sass">
.LastComments__container
  padding: 40px 71px 40px 30%
  box-sizing: border-box
.LastComments
  box-sizing: border-box
  width: 100%
  min-height: 593px
  margin-top: 100px
  margin-bottom: 114px
  border-radius: 10px
  background: rgb(31, 30, 35) url(/src/assets/images/LastComments/tyan.svg) no-repeat left bottom
  background-size: 50% / cover
.LastComments__innerWrap
  display: grid
  grid-template-columns: 1fr
  gap: 30px
  box-sizing: border-box
  h3
    color: rgb(255, 255, 255)
    font-family: Montserrat
    font-size: 24px
    font-weight: 600
    line-height: 29px
    letter-spacing: 0
    text-align: center
    box-sizing: border-box

@media (max-width: 970px)
  .LastComments
    background-image: none
  .LastComments__container
    padding: 30px

</style>