import { createStore } from 'vuex'
import { getCookie } from '@/custom/getCookie';
import axios from 'axios'

export default createStore({
  state: {
    account: '',
    token: false,
    settingUser: false,
    backendURL: 'https://backend.enshi.ru',
    userRole: 'user',
  },
  getters: {
    ACCOUNT: state => {
      return state.account;
    },
    TOKEN: state => {
      return state.token;
    },
    SETTINGUSER: state => {
      return state.settingUser;
    },
    BACKENDURL: state => {
      return state.backendURL;
    }
  },
  mutations: {
    SET_ACCOUNT: (state, payload) => {
      state.account = payload;
    },
    SET_TOKEN: (state, payload) => {
      state.token = payload;
    },
    SET_SETTINGUSER: (state, payload) => {
      state.settingUser = payload;
    }
  },
  actions: {
    getToken ({commit, getters})
    {
      if(getCookie('access_token') !== null) {
        let token = getCookie('access_token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        axios.get(this.state.backendURL + '/api/user').then((response) => {
          this.state.account = response.data.user,
          this.state.userRole = response.data.role
        }).catch((errors) => {
          console.log(errors),
          localStorage.removeItem('user'),
          document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        });
        commit('SET_TOKEN', true);
      } else {
        commit('SET_TOKEN', false);
      }
    }
  },
  modules: {
  }
})
