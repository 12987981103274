<template>
    <div class="preview">
        <div class="preview_account">
            <div class="pos-preview_account">
                <img v-if="user.avatar != null" class="preview_account_avatar" :src="user.avatar" alt="Аватар аккаунта" draggable="false">
                <img v-else class="preview_account_avatar" src="../../assets/images/AccountView/AvatarExample.png" alt="Аватар аккаунта" draggable="false">
                <div>
                    <p class="preview_account_nickname">{{ user.name }}</p>
                    <div class="preview_account_block-rank">
                        <p class="preview_account_block-rank_rank">{{ user.rank }}</p>
                    </div>
                </div>
            </div>
            <div v-if="this.$store.getters.ACCOUNT">
                <div v-if="this.$store.getters.ACCOUNT.id === user.id" class="preview_account_options">
                    <button @click="openStats()" class="preview_account_options_exit">
                        <font-awesome-icon icon="fa-solid fa-chart-column" />
                    </button>
                    <router-link to="/account/settings" class="preview_account_options_settings">
                        <font-awesome-icon icon="fa-solid fa-gear" />
                    </router-link>
                    <button class="preview_account_options_exit" @click="Logout()">
                        <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
                    </button>
                </div>
            </div>
        </div>
        <div class="preview_stats">
            <ul>
                <p>Статистика</p>
                <!-- <li>-<span>0</span></li> -->
                <li>В избранном<span>{{ statUser.coun_favorites }}</span></li>
            </ul>
            <div class="line"></div>
            <ul>
                <p>Всего</p>
                <li>Оценок<span>{{ statUser.coun_estimations }}</span></li>
                <li>Комментариев<span>{{ statUser.coun_comments }}</span></li>
                <!-- <li>-<span>0</span></li> -->
            </ul>
        </div>
    </div>
</template>

<script>
import { getCookie } from "@/custom/getCookie";
import axios from 'axios'

export default {
    name: 'AccountPreview',
    components: {
    },
    data() {
        return {
            user: '',
            mainUser: '',
            settingUser: false,
            statUser: '',
        }
    },
    watch: {
        '$route.params.id': {
            handler(newVal, oldVal) {
                this.fetchData(newVal);
            },
            immediate: true
        }
    },
    mounted() {
        this.mainUser = JSON.parse(localStorage.getItem('user'));
        axios.get(this.$store.getters.BACKENDURL + '/api/userview/' + this.$route.params.id).then((response) => {
            this.user = response.data;
            this.$emit('closeLoader', false);
            this.$emit('dataUser', response.data);
            this.getUserStat();
        }).catch((errors) => {
            console.log(errors);
            this.$router.push('/404')
        });
    },
    methods: {
        fetchData(id) {
            this.$emit('openLoader', true);
            axios.get(this.$store.getters.BACKENDURL + '/api/userview/' + id).then((response) => {
                this.user = response.data;
                this.$emit('closeLoader', false);
                this.$emit('dataUser', response.data);
            }).catch((errors) => {
                console.log(errors)
            });
        },
        getUserStat() {
            axios.get(this.$store.getters.BACKENDURL + '/api/stats/' + this.$route.params.id).then((response) => {
                this.statUser = response.data;
            }).catch((errors) => {
                console.log(errors)
            });
        },
        Logout() {
            axios.defaults.headers.common['Authorization'] = `Bearer ${getCookie('access_token')}`
            axios.post(this.$store.getters.BACKENDURL + '/api/logout').then((response) => {
                document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                this.$store.commit('SET_TOKEN', false)
                this.$store.commit('SET_ACCOUNT', '')
                localStorage.removeItem('user')
                setTimeout(() => {
                    this.$router.push('/');
                }, 800)
            }).catch((errors) => {
                console.log(errors)
            });
        },
        openStats() {
            let statsblock = document.querySelector('.preview_stats');

            if(window.getComputedStyle(statsblock).display === 'none') {
                statsblock.style.display = 'flex';
            } else {
                statsblock.style.display = 'none';
            }
        }
    },
}
</script>

<style lang="sass" scoped>

    .pos-preview_account
        display: flex
        align-items: center
        flex-direction: column
    .line
        border: 1.5px solid rgb(45, 45, 45)
    .preview
        margin-top: -130px
        display: flex
        flex-direction: column
        gap: 40px
        &_account
            border-radius: 10px
            background: rgb(31, 30, 35)
            width: 258px
            height: 359px
            display: flex
            flex-direction: column
            align-items: center
            &_avatar
                border-radius: 10px
                object-fit: cover
                object-position: center
                width: 130px
                height: 130px
                margin-top: 30px
            &_nickname
                color: rgb(255, 255, 255)
                font-family: Montserrat
                font-size: 20px
                font-weight: 500
                line-height: 24px
                letter-spacing: 5%
                margin-top: 20px
                text-align: center
            &_block-rank
                border-radius: 5px
                background: rgb(140, 83, 253)
                padding: 7px 26px
                margin-top: 7px
                &_rank
                    color: rgb(255, 255, 255)
                    font-family: Montserrat
                    font-size: 16px
                    font-weight: 500
                    line-height: 20px
                    letter-spacing: 4%
                    text-align: center
            &_options
                margin-top: 35px
                display: flex
                gap: 25px
                &_settings
                    text-decoration: none
                    color: #6E6E6E
                    border-radius: 10px
                    background: rgb(45, 45, 45)
                    width: 50px
                    height: 50px
                    display: flex
                    justify-content: center
                    align-items: center
                    transition: 0.3s
                    svg
                        width: 20px
                        height: 20px
                &_settings:active,
                &_settings:hover
                    background: #4D4D4D
                &_exit
                    color: #6E6E6E
                    border: none
                    outline: none
                    border-radius: 10px
                    background: rgb(45, 45, 45)
                    width: 50px
                    height: 50px
                    display: flex
                    justify-content: center
                    align-items: center
                    transition: 0.3s
                    cursor: pointer
                    svg
                        width: 20px
                        height: 20px
                &_exit:active,
                &_exit:hover
                    background: #4D4D4D
                &_exit:first-child
                    display: none
        &_stats
            border-radius: 10px
            background: rgb(31, 30, 35)
            max-width: 257px
            ul
                margin-left: 25px
                margin-right: 25px
                padding-top: 40px
                padding-bottom: 31px
                p
                    color: rgb(255, 255, 255)
                    font-family: Montserrat
                    font-size: 20px
                    font-weight: 500
                    line-height: 24px
                    letter-spacing: 4%
                    margin-bottom: 30px
                li
                    color: rgb(255, 255, 255)
                    font-family: Montserrat
                    font-size: 16px
                    font-weight: 500
                    line-height: 20px
                    letter-spacing: 4%
                    list-style-type: none
                    display: flex
                    flex-direction: row
                    justify-content: space-between
                    margin-bottom: 7.5px
                    margin-top: 7.5px
                    span
                        color: rgb(177, 126, 255)
                        font-family: Montserrat
                        font-size: 16px
                        font-weight: 500
                        line-height: 20px
                        letter-spacing: 4%

@media (max-width: 1025px)
    .pos-preview_account
        display: flex
        gap: 41px
        align-items: center
        flex-direction: row
    .preview
        &_account
            flex-direction: row
            width: 688px
            height: auto
            padding: 40px
            margin: 0px 40px
            justify-content: space-between
            &_avatar
                margin-top: 0px
            &_nickname
                margin-top: 0px
                text-align: left
            &_options
                margin-top: 0px
                &_exit:first-child
                    display: flex
        &_stats
            display: none
            max-width: 100%
            width: 768px
            height: auto
            margin: 0px 40px
            justify-content: space-between
            ul
                padding: 40px
                width: 233px

@media (max-width: 850px)
    .pos-preview_account
        margin-left: 40px
    .preview
        margin-top: -40px
        width: 100%
        &_account
            margin: 0px
            width: 100%
            padding: 40px 0px
            border-radius: 0px
            &_options
                margin-right: 40px
        &_stats
            width: 100%
            margin: 0px

@media (max-width: 580px)
    .pos-preview_account
        gap: 20px
        margin-left: 20px
    .preview
        &_account
            padding: 20px 0px
            &_avatar
                width: 100px
                height: 100px
            &_block-rank
                margin-top: 10px
                padding: 7.5px 22.5px
                &_rank
                    font-size: 12px
                    line-height: 15px
            &_nickname
                font-size: 18px
                line-height: 22px
            &_options
                flex-direction: column
                gap: 15px
                margin-right: 20px
                &_settings
                    width: 40px
                    height: 40px
                    svg
                        width: 15px
                        height: 15px
                &_exit
                    width: 40px
                    height: 40px
                    svg
                        width: 15px
                        height: 15px
        &_stats
            flex-direction: column
            align-items: center
            ul
                text-align: center
    .line
        width: 100%
</style>