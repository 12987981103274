<template>
    <div v-if="this.$store.getters.ACCOUNT" class="ac-anime">
        <div v-if="this.$store.getters.ACCOUNT.id == dataU.id" class="ac-anime_header">
            <p class="ac-anime_header_button">
                В обновлении...
            </p>
            <p class="ac-anime_header_button active">
                Избранное
            </p>
        </div>
        <div class="line"></div>
        <div class="ac-anime_content">
            <FavouritesVue
                v-if="check === 'favorites' && this.$store.getters.ACCOUNT.id == dataU.id"
            />
            <div class="info" v-if="check === 'favorites' && this.$store.getters.ACCOUNT.id !== dataU.id">
                В будущих обновлениях тут появяться достижения, оценка пользователя, комментарии, посты, добавление в друзья и другое.
                <br><br>Свои идеи, что добавить, что улучшить, можете писать в сообщения нашей группы в ВК.
            </div>
        </div>
    </div>
</template>

<script>
import FavouritesVue from './AccountAnime/Favourites.vue'

export default {
    name: 'AccountPreview',
    components: {
        FavouritesVue,
    },
    props: [
        'dataU'
    ],
    data() {
        return {
            check: 'favorites',
        }
    },

}
</script>

<style lang="sass" scoped>
    .info
        color: rgb(255, 255, 255)
        font-family: Montserrat
        font-size: 18px
        line-height: 22px
    .active
        color: rgb(255, 255, 255) !important
        border-bottom: 3px solid rgb(110, 110, 110) !important
        margin-bottom: -3px !important
    .line
        border: 1.5px solid rgb(45, 45, 45)
    .ac-anime
        border-radius: 10px
        background: rgb(31, 30, 35)
        width: 100%
        padding: 35px 50px
        height: fit-content
        &_header
            display: flex
            gap: 40px
            &_button
                padding: 23px 0px
                color: rgb(110, 110, 110)
                font-family: Montserrat
                font-size: 18px
                font-weight: 500
                line-height: 22px
                letter-spacing: 0%
                cursor: pointer
                transition: 0.2s
            &_button:active,
            &_button:hover
                color: rgb(255, 255, 255)
                border-bottom: 3px solid rgb(110, 110, 110)
                margin-bottom: -3px
        &_content
            padding-top: 35px

@media (max-width: 580px)
    .ac-anime
        padding: 15px 0px
        &_header
            padding: 0px 20px
            &_button
                font-size: 16px
                line-height: 20px
</style>