<template>
    <div class="change-pass">
        <form @submit.prevent="onChangePassword">
            <p>Сменить пароль</p>
            <input type="password" v-model="changePassword.current_password" id="current_password" placeholder="Старый пароль">
            <input type="password" v-model="changePassword.new_password" id="new_password" placeholder="Новый пароль">
            <input type="password" v-model="changePassword.new_password_confirmation" id="new_password_confirmation" placeholder="Повторите пароль">
            <div class="pos-button">
                <button type="submit" class="button-form">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>
import { getCookie } from '@/custom/getCookie';
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import axios from 'axios';

export default {
    name: 'ChangePassword',
    components: {

    },
    data() {
        return {
            changePassword: {
                current_password: '',
                new_password: '',
                new_password_confirmation: '',
            }
        }
    },
    methods: {
        onChangePassword()
        {
            let token = getCookie('access_token');

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            axios.post(this.$store.getters.BACKENDURL + '/api/update-password', this.changePassword).then((response) => {
                toast(response.data.message, {
                "theme": "colored",
                "type": "success",
                "dangerouslyHTMLString": true
                })
                document.getElementById('current_password').style.border = 'none'
                document.getElementById('new_password').style.border = 'none'
                document.getElementById('new_password_confirmation').style.border = 'none'
            })
            .catch((error) => {
                console.log(error)
                toast("Ошибка! Поля не должны быть пустые! Проверьте правильность ввода старого пароля, а также совпадает ли новый пароль с проверкой пароля!", {
                "theme": "colored",
                "type": "error",
                "dangerouslyHTMLString": true
                })

                document.getElementById('current_password').style.border = '1px solid red'
                document.getElementById('new_password').style.border = '1px solid red'
                document.getElementById('new_password_confirmation').style.border = '1px solid red'
            });
        }
    }
}
</script>

<style lang="sass" scoped>
    p
        color: white
    .pos-button
        display: flex
        justify-content: flex-end
        width: 365px
    .change-pass
        form
            border-radius: 10px
            background: rgb(31, 30, 35)
            width: 625px
            display: flex
            align-items: center
            flex-direction: column
            padding: 15px 0px
            min-height: 390px
            p
                font-size: 20px
                width: 365px
                font-weight: 500
                line-height: 24px
                letter-spacing: 4%
                margin-top: 30px
                margin-bottom: -5px
            input[type="password"]
                width: 325px
                margin-top: 25px
            button
                margin-top: 30px

@media (max-width: 1250px)
    .change-pass
        form
            width: 525px

@media (max-width: 530px)
    .pos-button
        width: 246px
    .change-pass
        width: 100%
        form
            border-radius: 7px
            width: 100%
            min-height: 285px
            p
                font-size: 16px
                line-height: 20px
                width: 246px
                margin-top: 15px
            input[type="text"]
                width: 216px
                margin-top: 15px
            button
                margin-top: 25px
</style>