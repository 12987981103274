<template>
    <Register />
</template>

<script>
import Register from './../components/RegisterView/Register.vue'

export default {
    name: 'RegisterView',
    components: {
        Register,
    },
}
</script>

<style lang="sass" scoped>

</style>