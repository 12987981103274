<template>
    <div v-if="userHasAdminRole || userHasModerRole" class="suser">
        <div class="suser_search">
            <input type="text" v-model="searchInput" placeholder="Введите имя пользователя">
            <button @click="searchUser(searchInput)">Поиск</button>
        </div>
        <div class="suser_content">
            <div v-for="user in userSearch" :key="user.id" class="suser_content_item">
                <img v-if="user.avatar !== null" :src="`${this.$store.getters.BACKENDURL}${user.avatar}`" alt="Аватар пользователя" draggable="false">
                <img v-else src="../../assets/images/AccountView/AvatarExample.png" alt="Аватар пользователя" draggable="false">
                <p>ID: {{ user.id }}</p>
                <p>Имя: {{ user.name }}</p>
                <p>Ранг: {{ user.rank }}</p>
                <div class="suser_content_item_btns">
                    <button>
                        <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                    </button>
                    <button>
                        <font-awesome-icon icon="fa-solid fa-user-slash" />
                    </button>
                    <button>
                        <font-awesome-icon icon="fa-solid fa-user-minus" />
                    </button>
                </div>
            </div>
            <EditUser
            />
        </div>
    </div>
    <div v-else class="access-error">
        <p>У вас нету доступа!</p>
        <p>У вас должен быть доступ "Администратор"</p>
    </div>
</template>

<script>
import { getCookie } from "@/custom/getCookie";
import axios from 'axios'
import EditUser from '@/components/AdminPanelView/SearchUsers/EditUser.vue'

export default {
    name: 'SearchUsers',
    components: {
        EditUser,
    },
    data() {
        return {
            searchInput: '',
            userSearch: '',
        }
    },
    computed: {
        userHasAdminRole() {
            return this.$store.state.userRole.some(role => role === 'admin');
        },
        userHasModerRole() {
            return this.$store.state.userRole.some(role => role === 'moder');
        }
    },
    methods: {
        searchUser(user) {
            if(user !== '') {
                let token = getCookie('access_token');
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                axios.get(this.$store.getters.BACKENDURL + '/api/admin/users/show', {
                    params: {
                        name: user
                    }
                }).then((response) => {
                    this.userSearch = response.data
                }).catch((errors) => {
                    console.log(errors);
                });
            }
        },
    },
}
</script>

<style lang="sass" scoped>

    .access-error
        width: 100%
        height: 30%
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        p
            margin: auto
            font-size: 30px
            text-align: center
            color: white
    .suser
        border-radius: 10px
        background: rgb(31, 30, 35)
        width: 100%
        &_search
            display: flex
            flex-direction: row
            margin: 40px
            gap: 40px
            justify-content: space-between
            input
                flex-grow: 1
            button
                width: 170px
                height: 54px
                border-radius: 5px
                background: rgb(140, 83, 253)
                font-size: 18px
                font-weight: 500
                line-height: 22px
                letter-spacing: 2%
                transition: 0.2s
            button:active,
            button:hover
                background: #9370d8
        &_content
            padding: 0px 40px
            display: flex
            flex-direction: column
            gap: 10px
            &_item
                border: 1.5px solid rgb(46, 45, 50)
                border-radius: 10px
                padding: 10px
                display: flex
                flex-direction: row
                align-items: center
                justify-content: space-between
                gap: 20px
                img
                    width: 60px
                    height: 60px
                    border-radius: 10px
                p
                    color: rgb(255, 255, 255)
                    font-family: Montserrat
                    font-size: 18px
                    font-weight: 500
                    line-height: 22px
                    letter-spacing: 4%
                &_btns
                    display: flex
                    flex-direction: row
                    gap: 10px
                    button
                        padding: 10px
                        transition: 0.2s
                        border-radius: 8.15px
                        background: rgb(46, 45, 50)
                        svg
                            width: 20px
                            height: 20px
                            transition: 0.2s
                        svg:active,
                        svg:hover
                            color: rgb(140, 83, 253)

@media (max-width: 1025px)
    .suser
        margin-right: 25px

@media (max-width: 650px)
    .suser
        border-radius: 7px
        &_search
            margin: 28px 15px
            gap: 15px
            input
                font-size: 12px
                line-height: 15px
                padding: 11.2px 15px 11.8px 15px
                border-radius: 5px
            button
                width: 90px
                height: 38px
                font-size: 12px
                line-height: 15px
        &_content
            padding: 0px 15px
            flex-direction: row
            flex-wrap: wrap
            justify-content: center
            &_item
                flex-direction: column
                gap: 5px
                img
                    width: 46px
                    height: 46px
                p
                    font-size: 12px
                    line-height: 15px
                &_btns
                    gap: 8px
                    button
                        padding: 0px 10px
                        border-radius: 5px
                        height: 30px
                        svg
                            width: 15px
                            height: 15px

@media (max-width: 550px)
    .suser
        margin: 0px 15px
        width: auto
</style>