<template>
    <div class="block-text">
        <p class="block-text_title">Вот, что мне удалось найти</p>
        <p class="block-text_desc">по вашему запросу ( 10 ответов )</p>
    </div>
    <form action="" class="Search">
        <div class="pos-input">
            <input class="Search_input" type="text" placeholder="Поиск аниме">
            <div>
                <button class="Search_button">
                    <font-awesome-icon size="xl" style="color: #b17eff;" icon="fa-solid fa-magnifying-glass" />
                </button>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: 'Search',
    components: {

    }
}
</script>

<style lang="sass" scoped>
    .block-text
        margin-top: 100px
        &_title
            font-size: 33px
            font-weight: 600
            line-height: 40px
            letter-spacing: 4%
            text-align: center
        &_desc
            font-size: 21px
            font-weight: 500
            line-height: 26px
            letter-spacing: 4%
            color: rgb(130, 130, 130)
            text-align: center
            margin-top: 5px
    .pos-input
        display: flex
        align-items: center
        justify-content: center
        div
            display: flex
            align-items: center
    .Search
        display: flex
        justify-content: center
        flex-direction: row
        align-items: center
        margin-top: 35px
        margin-bottom: 60px
        &_input
            border: none
            border-radius: 5px
            background: #2D2D2D
            outline: none
            padding: 14px 30px 13px 30px
            color: #6E6E6E
            font-family: Montserrat
            font-size: 14px
            font-style: normal
            font-weight: 500
            line-height: normal
            letter-spacing: 0.28px
            width: 584px
        &_button
            position: absolute
            margin-left: -45px
            background: none
            border: none
            outline: none
            cursor: pointer
            width: 20px
            height: 20px

@media (max-width: 650px)
    .block-text
        margin-top: 70px
        &_title
            font-size: 21px
            line-height: 26px
        &_desc
            font-size: 16px
            line-height: 20px
    .pos-input
        width: 100%
        margin: 0px 15px
    .Search
        margin-top: 25px
        width: 100%
        margin-bottom: 50px
        &_input
            width: 100%
            font-size: 12.6px
            line-height: 15px
            padding: 12.1px 20px 10.9px 20px
        &_button
            position: absolute
            right: 33.57px
</style>