<template>
  <div
      class="video-container"
      id="video-container"
      @mousemove="showControls"
      @mouseleave="hideControls"
      @click="showControls"
      @touchstart="showControls"
  >
    <video
        @pause="is_played = false"
        @play="is_played = true"
        @timeupdate="updateTime"
        @loadedmetadata="updateTime"
        @volumechange="updateVolume"
        id="playerV"
        ref="videoPlayer"
        class="video-js">
    </video>
    <div class="player-interface" :class="{ hidden: !controlsVisible }">
      <div class="header-controls">
        <img
            @click="goToPreviousEpisode"
            style="cursor: pointer;position: relative;z-index: 10"
            class="header-controls_back-video"
            src="../../assets/player/img/back-arrow.svg"
            alt="Предыдущая серия"
        />
        <div class="header-controls_right-controls">
          <div v-if="videos?.length" class="video-block-quality">
            <img v-if="quality >= 1080" src="../../assets/player/img/hq.svg" alt="1080p"/>
            <div class="select-quality">
              <select @change="changeQuality">
                <option
                    v-for="video in videos"
                    :key="video.id"
                    :value="video.quality.name"
                >{{ video.quality.name }}p
                </option>
              </select>
            </div>
          </div>
          <img src="../../assets/player/img/settings.svg" alt="Настройки плеера"/>
        </div>
      </div>
      <div class="player-controls-center">
        <div class="player-controls-center__dbClick">
          <div @dblclick="rewindPrev"></div>
          <div @dblclick="rewindNext"></div>
        </div>
        <div class="player-controls-center_left" @click="goToPreviousEpisode">
          <img src="../../assets/player/img/left-arrow.svg" alt="Предыдущая серия"/>
        </div>
        <div @click="togglePlay" id="player-play" class="player-controls-center_center">
          <img
              v-if="!is_played"
              class="player-controls-center_center_play"
              src="../../assets/images/MainView/RandomAnime/rating_icon.svg"
              alt="Иконка Enshi"
          />
          <font-awesome-icon
              v-else
              class="player-controls-center_center_pause"
              icon="fa-solid fa-pause"
          />
        </div>
        <div class="player-controls-center_right" @click="goToNextEpisode">
          <img src="../../assets/player/img/right-arrow.svg" alt="Следующая серия"/>
        </div>
      </div>
      <div class="video-desc">
        <h5 v-if="episode && anime">{{ episode.series_name ?? anime.title }}</h5>
        <p v-if="episode">{{ episode.series_number }} серия</p>
      </div>
      <div id="controls" class="controls">
        <div class="time">{{ currentTime }} / {{ duration }}</div>
        <div class="progress">
          <input
              id="progress"
              class="progress-bar"
              type="range"
              min="0"
              max="100"
              value="0"
              @input="seek"
          />
        </div>
        <div class="block-right">
          <div class="volume">
            <div @click="toggleVolume" class="volume-icon-container">
              <font-awesome-icon
                  :class="{ 'd-none': volumeLevel <= 0.5 }"
                  class="volume-icon"
                  id="fa-volume-high"
                  icon="fa-solid fa-volume-high"
              />
              <font-awesome-icon
                  :class="{ 'd-none': volumeLevel >= 0.5 || volumeLevel <= 0 }"
                  class="volume-icon"
                  id="fa-volume-low"
                  icon="fa-solid fa-volume-low"
              />
              <font-awesome-icon
                  :class="{ 'd-none': volumeLevel > 0 }"
                  class="volume-icon"
                  id="fa-volume-xmark"
                  icon="fa-solid fa-volume-xmark"
              />
            </div>
            <input
                id="volume"
                class="volume-bar"
                type="range"
                min="0"
                max="100"
                value="100"
                @input="setVolume"
            />
          </div>
          <div class="block-pip" @click="openVideoWindow">
            <img class="pip" src="../../assets/player/PiP.svg" alt="Режим PiP"/>
          </div>
          <div v-if="!isFullscreen" class="block-fullscreen">
            <font-awesome-icon
                class="icon-fullscreen"
                @click="toggleFullScreen()"
                icon="fa-solid fa-expand"
            />
          </div>
          <div v-if="isFullscreen" class="block-fullscreen">
            <font-awesome-icon
                class="icon-fullscreen"
                @click="toggleFullScreen()"
                icon="fa-solid fa-maximize"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="loading-overlay">
      <div class="loading"></div>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js';
import axios from 'axios';
import 'video.js/dist/video-js.css';

export default {
  name: 'VideoPlayer',
  props: {
    episode: {},
    anime: {},
  },
  data() {
    return {
      hls: null,
      currentTime: '00:00',
      duration: '00:00',
      volumeLevel: 1,
      controlsVisible: true,
      quality: 720,
      is_played: false,
      hideControlsTimeout: null,
      isFullscreen: false,
      containerPlayer: null,
      videos: {},
      videosource: {
        url: '',
        type: '',
      },
      loading: false,
      dataLoaded: false, // Добавлено
      allEpisodesLoaded: false, // Добавлено
    };
  },
  watch: {
    episode: {
      handler(newEpisode) {
        this.updateVideo(newEpisode);
      },
    },
  },
  mounted() {
    this.loadplayer();
    document.addEventListener('keyup', this.handleKeyboard)
  },
  unmounted() {
    document.removeEventListener('keyup', this.handleKeyboard)
  },
  methods: {
    togglePlay() {
      this.is_played ? this.$refs.videoPlayer.pause() : this.$refs.videoPlayer.play()
    },
    handleKeyboard(event) {
      if (!document.fullscreenElement) return
      event.preventDefault();
      switch (event.key) {
        case ' ':
          this.togglePlay()
          break
        case 'ArrowLeft':
          this.rewindPrev()
          break
        case 'ArrowRight':
          this.rewindNext()
          break
      }
    },
    rewindNext() {
      this.$refs.videoPlayer.currentTime += 15
    },
    rewindPrev() {
      this.$refs.videoPlayer.currentTime -= 15
    },
    loadplayer() {
      this.containerPlayer = document.querySelector('.video-container');
      this.hls = new Hls();

      const video = this.$refs.videoPlayer;
      if (Hls.isSupported()) {
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          console.log('video and hls.js are now bound together!');
        });
        this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
          console.log(
              `manifest loaded, found ${data.levels.length} quality level`
          );
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.videosource.url;
        video.addEventListener('loadedmetadata', () => {
          video.play();
        });
      }

      document.addEventListener('fullscreenchange', this.handleFullscreenChange);
      document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange); // Safari support
      document.addEventListener('mozfullscreenchange', this.handleFullscreenChange); // Firefox support
      document.addEventListener('MSFullscreenChange', this.handleFullscreenChange); // IE/Edge support

      this.showControls();
    },
    updateVideo(newEpisode) {
      axios.get(`${this.$store.getters.BACKENDURL}/api/anime/${this.$route.params.id}/${newEpisode?.voice_series_id || ''}/all-episodes`,
          {
            params: {number: newEpisode.series_number},
          }
      )
          .then((response) => {
            this.videos = response.data;

            // максимальное качество
            const maxQuality = this.videos.reduce((max, video) => {
              return video.quality.value > max.quality.value ? video : max;
            }, this.videos[0]);

            this.dataLoaded = true; // Установлено при успешной загрузке данных

            // Установка максимального качества в select
            this.$nextTick(() => {
              const selectElement = this.$el.querySelector('.select-quality select');
              if (selectElement) {
                selectElement.value = maxQuality.quality.name;
              }
            });

            // Установка нового источника
            if (maxQuality) {
              this.videosource.url = maxQuality.series_url;
              this.videosource.type = maxQuality.video_format.format;

              this.allEpisodesLoaded = true;

              if (this.dataLoaded && this.allEpisodesLoaded) {
                this.loadVideoSource(this.videosource.url, this.videosource.type);
              }
            }
          })
          .catch((errors) => {
            console.log(errors);
          });
    },
    loadVideoSource(url, type) {
      this.loading = true;

      const video = this.$refs.videoPlayer;

      if (Hls.isSupported() && type === 'application/x-mpegURL') {
        this.hls.loadSource(url);
        this.hls.attachMedia(video);
      } else {
        video.src = url;
      }

      video.load();
      video.addEventListener('error', () => {
        this.loading = false
      })
      video.addEventListener('canplay', this.onVideoCanPlay);
      document.querySelector('#player-play').classList.remove('d-none');
      document.querySelector('#player-pause').classList.add('d-none');

    },
    onVideoCanPlay() {
      this.loading = false;
      this.$refs.videoPlayer.removeEventListener('canplay', this.onVideoCanPlay);

    },
    changeQuality(event) {
      this.quality = +event.target.value
      const selectedVideo = this.videos.find((video) => video.quality.name === +event.target.value);

      if (selectedVideo) {
        this.videosource.url = selectedVideo.series_url;
        this.videosource.type = selectedVideo.video_format.format;

        this.loadVideoSource(this.videosource.url, this.videosource.type);
      }
    },
    goToNextEpisode() {
      this.$emit('next-episode');
    },
    goToPreviousEpisode() {
      this.$emit('previous-episode');
    },
    play() {
      this.$refs.videoPlayer.play();
      document.querySelector('#player-pause').classList.remove('d-none');
      document.querySelector('#player-play').classList.add('d-none');
    },
    pause() {
      this.$refs.videoPlayer.pause();
      document.querySelector('#player-play').classList.remove('d-none');
      document.querySelector('#player-pause').classList.add('d-none');
    },
    updateTime() {
      const video = this.$refs.videoPlayer;
      this.currentTime = this.formatTime(video.currentTime);
      this.duration = this.formatTime(video.duration);
      const progress = (video.currentTime / video.duration) * 100;
      this.updateProgressBar(progress);
      progress === 100 && this.goToNextEpisode();
    },
    updateVolume(event) {
      this.volumeLevel = event.target.volume;
      this.updateVolumeBar(this.volumeLevel * 100);
    },
    toggleVolume() {
      this.$refs.videoPlayer.volume = this.volumeLevel = Number(!this.volumeLevel)
    },
    setVolume(event) {
      this.$refs.videoPlayer.volume = event.target.value / 100;
    },
    updateVolumeBar(volume) {
      const volumeBar = document.getElementById('volume');
      volumeBar.value = volume;
      const gradient = `-webkit-linear-gradient(left, rgba(147, 93, 253, 1) ${volume}%, rgba(255, 255, 255, 0.3) ${volume}%, rgba(255, 255, 255, 0.3) 100%)`;
      volumeBar.style.background = gradient;
    },
    formatTime(seconds) {
      const time = new Date(0, 0, 0, 0, 0, seconds || 0, 0)
      const hour = time.getHours() && {hour: 'numeric'}
      return time.toLocaleTimeString(navigator.language, {...hour, minute: 'numeric', second: 'numeric'})
    },
    seek(event) {
      const progress = event.target.value;
      const video = this.$refs.videoPlayer;
      const time = (progress / 100) * video.duration;
      video.currentTime = time;
    },
    updateProgressBar(progress) {
      const progressBar = document.getElementById('progress');
      progressBar.value = progress;
      const gradient = `-webkit-linear-gradient(left, rgba(147, 93, 253, 1) ${progress}%, rgba(255, 255, 255, 0.3) ${progress}%, rgba(255, 255, 255, 0.3) 100%)`;
      progressBar.style.background = gradient;
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        if (this.containerPlayer.requestFullscreen) {
          this.containerPlayer.requestFullscreen().catch((err) => {
            console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
          });
        } else if (this.containerPlayer.webkitRequestFullscreen) {
          // Safari
          this.containerPlayer.webkitRequestFullscreen();
        } else if (this.containerPlayer.mozRequestFullScreen) {
          // Firefox
          this.containerPlayer.mozRequestFullScreen();
        } else if (this.containerPlayer.msRequestFullscreen) {
          // IE/Edge
          this.containerPlayer.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen().catch((err) => {
            console.log(`Error attempting to disable full-screen mode: ${err.message} (${err.name})`);
          });
        } else if (document.webkitExitFullscreen) {
          // Safari
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // Firefox
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          // IE/Edge
          document.msExitFullscreen();
        }
      }
    },
    openVideoWindow() {
      if ('pictureInPictureEnabled' in document) {
        this.$refs.videoPlayer.requestPictureInPicture();
      }
    },
    handleFullscreenChange() {
      this.isFullscreen = document.fullscreenElement !== null;
      if (this.isFullscreen) {
        this.showControls();
        this.containerPlayer.classList.add('fullscreen');
      } else {
        this.controlsVisible = false;
        this.containerPlayer.classList.remove('fullscreen');
        this.containerPlayer.style.cursor = 'default';
      }
    },
    showControls() {
      this.controlsVisible = true;
      this.containerPlayer.style.cursor = 'default';
      clearTimeout(this.hideControlsTimeout);
      if (this.isFullscreen) {
        this.hideControlsTimeout = setTimeout(() => {
          this.controlsVisible = false;
          this.containerPlayer.style.cursor = 'none';
        }, 2000); // Скрыть контролы после 2 секунд бездействия
      }
    },
    hideControls() {
      if (!this.isFullscreen) {
        this.controlsVisible = false;
        clearTimeout(this.hideControlsTimeout);
      }
    },
  },
  beforeDestroy() {
    if (this.hls) {
      this.hls.destroy();
    }
  },
};
</script>

<style lang="sass" scoped>
.player-interface
  z-index: 10
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)

.player-controls-center__dbClick
  position: absolute
  display: flex
  width: 100%
  inset: 0
  z-index: -1
  & > div
    flex: 1 0 50%
    cursor: pointer

.player-controls-center
  position: relative
  z-index: 1


.video-container
  width: 100%
  height: 466px
  position: relative
  margin: 0 auto 22px

  video
    border-radius: 0 0 10px 10px

.video-container video
  object-fit: cover

.player-controls-center_left, .player-controls-center_right
  cursor: pointer

.video-js
  width: 100%
  height: 100%
  border-radius: 0 0 10px 10px

.hidden
  display: none

.video-container.fullscreen
  cursor: none

.volume-icon-container
  width: 21px
  aspect-ratio: 1 / 1

@media (max-width: 650px)
  .video-container
    height: 430px

    video
      border-radius: 10px
  .time
    height: 20px !important
    font-size: 14px
    font-weight: 500
    line-height: 17px
    letter-spacing: 0
    max-width: fit-content
    margin-right: 10px
  .video-desc
    left: 15px
    right: 15px
    bottom: 62px

    h5
      width: auto
      -webkit-line-clamp: 1
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-box-orient: vertical
      white-space: normal
      font-size: 18px
      font-weight: 500
      line-height: 22px
      letter-spacing: 0

    p
      font-size: 14px
      font-weight: 500
      line-height: 17px
      letter-spacing: 0
  .controls
    padding: 0px
    bottom: 15px
    left: 15px
    right: 15px
  .block-right
    gap: 10px
    margin-left: 10px
  .progress
    display: block
  .player-controls-center_center
    width: 58.33px
    height: 58.33px

    img
      width: 50px
      height: 50px
  .player-controls-center
    gap: 28.33px
  .player-controls-center_right
    img
      width: 20px
      height: 20px
  .player-controls-center_left
    img
      width: 20px
      height: 20px
  .header-controls
    top: 15px
    left: 15px
    right: 15px

    img
      width: 20px
      height: 20px
  .select-quality
    select
      font-size: 14px
      font-weight: 500
      line-height: 17px
      letter-spacing: 0
      padding: 0
  .video-block-quality
    align-items: normal
</style>
  