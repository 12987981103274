<script setup>
import ReleaseTodayPosterBg from "@/components/ReleaseToday/ReleaseTodayPosterBg.vue";
import ReleaseTodayPosterItem from "@/components/ReleaseToday/ReleaseTodayPosterItem.vue";
import ReleaseTodayCardItem from "@/components/ReleaseToday/ReleaseTodayCardItem.vue";
import axios from "axios";
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";

const data = ref([
  // {id: 1, title: "title1", next_episode_at: Date.now(), poster_url: null, episodes_aired: 2},
  // {id: 2, title: "title2", next_episode_at: Date.now(), poster_url: null, episodes_aired: 3},
  // {id: 3, title: "title3", next_episode_at: Date.now(), poster_url: null, episodes_aired: 4},
])
const store = useStore()
const posters = computed(() => [...data.value, ...Array(6).fill({id: null})].slice(0, 6))



const getData = () => axios.get(store.getters.BACKENDURL + '/api/animes/released-today/').then((response) => {
  data.value = response.data
}).catch((errors) => {
  console.log(errors);
});

onMounted(() => {
  getData()
})
</script>

<template>
  <section v-if="data.length" class="ReleaseToday">
    <h3>Сегодня&nbsp;выйдет</h3>
    <div class="ReleaseToday__posters">
      <div class="ReleaseToday__postersBgWrap">
        <ReleaseTodayPosterBg/>
      </div>
      <div class="ReleaseToday__postersList">
        <ReleaseTodayPosterItem v-for="(item, idx) of posters" :data="item" :key="idx"/>
      </div>
    </div>
    <div class="ReleaseToday__itemList">
      <ReleaseTodayCardItem v-for="(i, idx) of data" :data="i" :key="idx"/>
    </div>
  </section>
</template>

<style lang="sass">
.ReleaseToday
  color: white

  border-radius: 10px
  background: rgb(31, 30, 35)
  margin: 100px 0 114px
  min-height: 600px
  position: relative

  h3
    position: absolute
    top: 30px
    left: 50%
    transform: translateX(-50%)
    color: rgb(255, 255, 255)
    font-size: 38px
    font-weight: 600
    line-height: 46px
    text-align: left

.ReleaseToday__posters
  position: relative
  overflow: hidden
  height: clamp(231px, 45vw, 280px)
  border-radius: 0 0 60px 60px

.ReleaseToday__postersBgWrap
  position: absolute
  z-index: 0
  inset: 0

.ReleaseToday__postersList
  padding: 40px 30px 0 30px
  display: flex
  gap: 20px
  justify-content: space-between

.ReleaseToday__itemList
  padding: 40px 30px
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  gap: 30px

@media (max-width: 1000px)
  .ReleaseToday__postersList
    padding: 40px 0 0
    margin: 0 -5%
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 20px
    justify-content: space-between

@media (max-width: 689px)
  .ReleaseToday__postersList
    height: 100%
    margin: 0 -3%
    grid-template-columns: repeat(3, 1fr)

  .ReleaseToday
    margin: 100px 0 114px
    h3
      font-size: 21px
      line-height: 26px
  .ReleaseToday__itemList
    padding: 25px 20px
    display: flex
    flex-direction: column
    align-items: center
    gap: 20px

@media (max-width: 499px)
  .ReleaseToday__postersList
    margin: 0 -5%

</style>