<template>
  <section class="footer">
    <div class="footer-inner-wraper">
      <div class="footer-logo">
        <svg
            id="enshi_logo"
            width="58.000000"
            height="58.000000"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              id="Vector"
              d="M48.93 58L9.06 58C4.05 58 0 53.93 0 48.93L0 9.06C0 4.06 4.05 0 9.06 0L48.93 0C53.94 0 58 4.06 58 9.06L58 48.93C58 53.93 53.94 58 48.93 58Z"
              fill="#343338"
              fill-opacity="1.000000"
              fill-rule="nonzero"
          />
          <path
              id="Vector"
              d="M15 29C15 27.03 15.37 25.2 16.09 23.5C16.82 21.79 17.82 20.32 19.1 19.05C20.38 17.79 21.84 16.81 23.53 16.09C25.21 15.36 27.01 15 28.94 15C30.87 15 32.69 15.36 34.4 16.09C36.11 16.81 37.61 17.81 38.89 19.05C40.17 20.32 41.17 21.79 41.9 23.5C42.62 25.2 43 27.03 43 29C43 30.02 42.89 30.99 42.67 31.89C41.47 31.39 40.22 31.02 38.92 30.8C37.63 30.59 36.35 30.48 35.07 30.48C33.46 30.48 31.89 30.65 30.33 30.97C28.79 31.29 27.26 31.85 25.76 32.6L25.76 28.19C28.65 27.13 31.61 26.6 34.68 26.6C35.27 26.6 35.84 26.62 36.41 26.67C36.98 26.7 37.55 26.76 38.14 26.83C37.87 25.71 37.46 24.7 36.86 23.81C36.25 22.91 35.54 22.16 34.73 21.55C33.91 20.93 33 20.45 32 20.1C30.99 19.76 29.97 19.58 28.96 19.58C27.68 19.58 26.47 19.82 25.35 20.32C24.22 20.81 23.23 21.47 22.4 22.3C21.57 23.13 20.89 24.13 20.38 25.3C19.87 26.47 19.59 27.7 19.59 29C19.59 30.31 19.85 31.54 20.36 32.68C20.87 33.83 21.55 34.83 22.41 35.68C23.28 36.54 24.27 37.2 25.39 37.66C26.52 38.14 27.71 38.37 28.94 38.37C30.04 38.37 31.07 38.2 32.06 37.87C33.04 37.55 33.96 37.04 34.79 36.35C35.34 36.94 35.87 37.52 36.41 38.09C36.93 38.67 37.46 39.27 37.97 39.89C36.69 40.9 35.28 41.67 33.72 42.21C32.18 42.73 30.58 43 28.94 43C27.2 43 25.52 42.69 23.94 42.07C22.35 41.46 20.95 40.6 19.7 39.49C18.47 38.38 17.43 37.08 16.63 35.6C15.84 34.11 15.33 32.49 15.12 30.74C15.04 30.45 15.01 30.16 15.01 29.86C15 29.59 15 29.29 15 29Z"
              fill="#828282"
              fill-opacity="1.000000"
              fill-rule="nonzero"
          />
        </svg>

        <svg
            id="enshi_logo2"
            width="49.458008"
            height="13.706543"
            viewBox="0 0 49.458 13.7065"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              id="Enshi."
              d="M48.3 13.6C48.93 13.6 49.45 13.08 49.45 12.44C49.45 11.81 48.93 11.3 48.3 11.3C47.67 11.3 47.15 11.81 47.15 12.44C47.15 13.08 47.67 13.6 48.3 13.6Z"
              fill="#8C53FD"
              fill-opacity="1.000000"
              fill-rule="evenodd"
          />
          <path
              id="Enshi."
              d="M44.41 1.02C44.41 1.58 43.93 2.04 43.34 2.04C42.75 2.04 42.27 1.58 42.27 1.02C42.27 0.46 42.75 0 43.34 0C43.93 0 44.41 0.46 44.41 1.02ZM8 13.5L0 13.5L0 0.4L7.9 0.4L7.9 1.81L1.58 1.81L1.58 6.24L7.48 6.24L7.48 7.64L1.58 7.64L1.58 12.09L8 12.09L8 13.5ZM35.67 4.9C34.18 4.9 33.08 5.87 33.08 7.59L33.08 13.5L31.58 13.5L31.58 0.4L33.08 0.4L33.08 5.21L33.22 5.21C33.67 4.2 34.56 3.55 36.1 3.55C38.11 3.55 39.45 4.73 39.45 7.25L39.45 13.5L37.94 13.5L37.94 7.36C37.94 5.8 37.09 4.9 35.67 4.9ZM14.93 4.9C13.48 4.9 12.42 5.87 12.42 7.59L12.42 13.5L10.91 13.5L10.91 3.68L12.37 3.68L12.37 5.21L12.5 5.21C12.96 4.21 13.88 3.55 15.36 3.55C17.35 3.55 18.69 4.75 18.69 7.25L18.69 13.5L17.18 13.5L17.18 7.36C17.18 5.82 16.33 4.9 14.93 4.9ZM25.28 3.55C27.23 3.55 28.29 4.48 28.76 5.87L27.41 6.26C27.15 5.58 26.6 4.85 25.28 4.85C24.08 4.85 23.19 5.47 23.19 6.31C23.19 7.07 23.71 7.45 24.87 7.74L26.33 8.1C28.09 8.53 28.94 9.39 28.94 10.76C28.94 12.45 27.39 13.7 25.11 13.7C23.1 13.7 21.72 12.81 21.37 11.14L22.8 10.78C23.07 11.84 23.85 12.37 25.08 12.37C26.49 12.37 27.38 11.68 27.38 10.84C27.38 10.16 26.91 9.68 25.92 9.46L24.29 9.07C22.48 8.65 21.68 7.74 21.68 6.39C21.68 4.72 23.21 3.55 25.28 3.55ZM44.08 13.5L42.57 13.5L42.57 3.68L44.08 3.68L44.08 13.5Z"
              fill="#FFFFFF"
              fill-opacity="1.000000"
              fill-rule="evenodd"
          />
        </svg>
        <p>&copy;&nbsp;2022&nbsp;&mdash;&nbsp;{{ new Date().getFullYear() }}</p>
      </div>
      <div class="footer__social">
        <a href="https://vk.com/enshi" target="_blank">
          <svg
              width="31.058594"
              height="20.705811"
              viewBox="0 0 31.0586 20.7058"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                id="Vector"
                d="M30.32 1.43C30.55 0.62 30.32 0.03 29.3 0.03L25.91 0.03C25.06 0.03 24.67 0.55 24.44 1.1C24.44 1.1 22.69 5.82 20.26 8.87C19.47 9.76 19.11 10.05 18.68 10.05C18.45 10.05 18.15 9.76 18.15 8.95L18.15 1.39C18.15 0.44 17.89 0 17.16 0L11.84 0C11.31 0 10.98 0.44 10.98 0.88C10.98 1.8 12.2 2.02 12.33 4.6L12.33 10.2C12.33 11.42 12.13 11.64 11.7 11.64C10.55 11.64 7.76 6.89 6.08 1.47C5.75 0.4 5.42 0 4.57 0L1.15 0C0.16 0 0 0.51 0 1.06C0 2.06 1.15 7.07 5.36 13.7C8.15 18.23 12.13 20.66 15.72 20.66C17.89 20.66 18.15 20.11 18.15 19.19L18.15 15.76C18.15 14.66 18.35 14.47 19.04 14.47C19.53 14.47 20.42 14.77 22.43 16.94C24.73 19.52 25.13 20.7 26.41 20.7L29.8 20.7C30.78 20.7 31.24 20.15 30.98 19.08C30.69 18.01 29.57 16.46 28.12 14.62C27.33 13.59 26.15 12.45 25.78 11.9C25.29 11.16 25.42 10.86 25.78 10.2C25.75 10.2 29.9 3.64 30.32 1.43L30.32 1.43Z"
                fill="#FFFFFF"
                fill-opacity="1.000000"
                fill-rule="evenodd"
            />
          </svg>
        </a>
        <a href="https://t.me/enshianime">
          <svg
              width="25.882812"
              height="23.293945"
              viewBox="0 0 25.8828 23.2939"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                id="Vector"
                d="M21.94 21.76L21.94 21.76L21.97 21.7L25.88 1.5L25.88 1.43C25.88 0.93 25.7 0.49 25.3 0.23C24.96 0 24.56 -0.02 24.28 0C24.02 0.03 23.76 0.08 23.51 0.16C23.41 0.2 23.3 0.24 23.2 0.28L23.18 0.29L1.37 9.05L1.37 9.05C1.3 9.08 1.24 9.1 1.17 9.13C1.02 9.21 0.87 9.29 0.73 9.39C0.45 9.59 -0.09 10.05 0 10.79C0.08 11.4 0.49 11.79 0.77 11.99C0.93 12.11 1.11 12.21 1.29 12.28L1.34 12.3L1.35 12.31L1.36 12.31L5.17 13.63C5.16 13.87 5.18 14.12 5.25 14.37L7.16 21.8C7.26 22.2 7.49 22.56 7.8 22.83C8.12 23.1 8.51 23.26 8.92 23.28C9.32 23.31 9.73 23.21 10.08 22.99C10.43 22.77 10.7 22.45 10.86 22.06L13.84 18.79L18.97 22.81L19.04 22.85C19.5 23.06 19.94 23.12 20.34 23.07C20.73 23.01 21.05 22.84 21.29 22.65C21.56 22.42 21.78 22.13 21.93 21.8L21.94 21.77L21.94 21.77L21.94 21.76ZM7.14 13.86C7.12 13.78 7.12 13.69 7.15 13.61C7.18 13.53 7.24 13.46 7.31 13.42L20.25 5C20.25 5 21.01 4.53 20.98 5C20.98 5 21.12 5.08 20.71 5.47C20.33 5.84 11.49 14.58 10.59 15.47C10.54 15.52 10.51 15.58 10.49 15.65L9.05 21.29L7.14 13.86L7.14 13.86Z"
                fill="#FFFFFF"
                fill-opacity="1.000000"
                fill-rule="evenodd"
            />
          </svg>
        </a> 
<!--        <a inert href="">-->
<!--          <svg-->
<!--              width="28.470703"-->
<!--              height="20.705811"-->
<!--              viewBox="0 0 28.4707 20.7058"-->
<!--              fill="none"-->
<!--              xmlns="http://www.w3.org/2000/svg"-->
<!--          >-->
<!--            <path-->
<!--                id="Vector"-->
<!--                d="M27.87 3.23C27.7 2.6 27.39 2.03 26.95 1.57C26.5 1.12 25.96 0.79 25.35 0.62C23.14 0 14.23 0 14.23 0C14.23 0 5.32 0 3.11 0.62C2.5 0.79 1.96 1.12 1.52 1.57C1.08 2.03 0.76 2.6 0.6 3.23C0.18 5.58 -0.02 7.96 0 10.35C-0.02 12.74 0.18 15.12 0.6 17.47C0.76 18.09 1.08 18.66 1.52 19.12C1.96 19.58 2.5 19.91 3.11 20.08C5.32 20.7 14.23 20.7 14.23 20.7C14.23 20.7 23.14 20.7 25.35 20.08C25.96 19.91 26.5 19.58 26.95 19.12C27.39 18.66 27.7 18.09 27.87 17.47C28.28 15.12 28.48 12.74 28.46 10.35C28.48 7.96 28.28 5.58 27.87 3.23ZM11.38 14.79L11.38 5.91L18.78 10.35L11.38 14.79Z"-->
<!--                fill="#FFFFFF"-->
<!--                fill-opacity="1.000000"-->
<!--                fill-rule="nonzero"-->
<!--            />-->
<!--          </svg>-->
<!--        </a>-->
      </div>
      <a href="https://vk.com/im?media=&sel=-168163353" target="_blank" class="footer__support">
        Связаться с нами
      </a>
    </div>
  </section>
</template>

<style lang="sass">
.footer
  background: rgb(31, 30, 35)
  border-radius: 100px 100px 0 0
  padding: 46px 50px


.footer-inner-wraper
  display: flex
  align-items: center
  margin-inline: auto
  justify-content: space-between
  max-width: 1200px


.footer__support
  display: block
  padding: 10.5px 30px
  box-sizing: border-box
  border: 1px solid #fff
  border-radius: 10px
  text-decoration: none
  color: white
  text-align: center
  width: max-content
  transition: border-color .2s ease-in-out

  &:hover
    border-color: darken(#fff, 25%)

.footer-logo
  display: grid
  grid-template-columns: 58px 1fr
  grid-template-areas: 'a b' 'a c'
  align-items: center
  width: max-content
  max-width: 195px
  gap: 2px 16px

  #enshi_logo
    grid-area: a

  #enshi_logo2
    grid-area: b
    margin-top: 8px

  p
    margin-bottom: 3px
    color: rgb(130, 130, 130)
    font-family: Montserrat
    font-size: 16px
    grid-area: c
    font-weight: 600
    line-height: 130%
    letter-spacing: 0
    text-align: left

.footer__social
  display: flex
  gap: 50px
  justify-content: center
  align-items: center

  [inert]
    svg path
      fill: darken(#fff, 50%)

  a svg path
    transition: fill .2s ease-in-out

  a:hover
    svg path
      fill: darken(#fff, 25%)


@media (max-width: 720px)
  .footer
    background: rgb(31, 30, 35)
    border-radius: 50px 50px 0 0
    padding: 44px 48px


  .footer-inner-wraper
    width: 100%
    flex-direction: column
    gap: 30px
    margin: 0

  .footer__social
    order: 1

  .footer-logo
    width: 100%
    max-width: 100%
    grid-template-areas: 'b a' 'c a'
    grid-template-columns: repeat(2, 1fr)
    order: 3
    padding-top: 30px
    border-top: 3px solid rgba(130, 130, 130, 0.2)

    #enshi_logo
      justify-self: end

  .footer__support
    width: 100%
    order: 2

</style>