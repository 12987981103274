<template>
    <section>
        <div class="Login">
            <div class="Login_background">

            </div>
            <div class="Login_form">
                <form class="Login_form_form-block" @submit.prevent="onLogin">
                    <p>Авторизация</p>
                    <input type="text" v-model="login.email" placeholder="E-mail">
                    <input type="password" v-model="login.password" placeholder="Введите пароль">
                    <button type="sumbit">Войти</button>
                    <div class="Login_form_form-block_re-password-and-register">
                        <router-link to="#">
                            Забыли пароль?
                        </router-link>
                        <router-link to="/register">
                            Регистрация
                        </router-link>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import axios from 'axios';

export default {
    name: 'Login',
    components: {
    },
    data() {
        return {
            login: {
                email: '',
                password: '',
            },
            token: '',
        }
    },
    methods: {
        onLogin() {
            axios.post(this.$store.getters.BACKENDURL + '/api/login', this.login).then((response) => {
                const token = response.data.access_token;
                const expirationDays = 30;
                const date = new Date();
                date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
                const expires = "expires=" + date.toUTCString();
                document.cookie = `access_token=${token}; ${expires}; path=/`;
                this.$store.commit('SET_TOKEN', true)
                this.$store.commit('SET_ACCOUNT', response.data.user)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                toast("Вы успешно авторизовались!", {
                    "theme": "colored",
                    "type": "success",
                    "dangerouslyHTMLString": true
                })
                setTimeout(() => {
                    this.$router.push('/');
                }, 600)
            })
            .catch((error) => {
                console.log(error);
                let errorparse = JSON.parse(error.request.response);
                if(errorparse.message) {
                    let message = errorparse.message.replace(/\([^)]*\)/g, '');
                    toast("Ошибка! " + message, {
                        "theme": "colored",
                        "type": "error",
                        "dangerouslyHTMLString": true
                    })
                } else {
                    toast("Ошибка! Пользователя с такими данными не существует", {
                        "theme": "colored",
                        "type": "error",
                        "dangerouslyHTMLString": true
                    })
                }
            }); 
        },
    }
}
</script>

<style lang="sass" scoped>
    section
        height: calc( 100vh - 200px )
        display: flex
        align-items: center
        justify-content: center
    .Login
        display: flex
        align-items: center
        &_background
            background-image: url(../../assets/images/LoginView/LoginView.png)
            height: 508px
            width: 386px
            background-size: cover
            pointer-events: none
        &_form
            border-radius: 0px 10px 10px 0px
            height: 508px
            padding: 0px 80px
            background: #1F1E23
            display: flex
            align-items: center
            &_form-block
                display: flex
                flex-direction: column
                gap: 25px
                p
                    color: #FFF
                    font-family: Montserrat
                    font-size: 23px
                    font-style: normal
                    font-weight: 600
                    line-height: normal
                    letter-spacing: 0.46px
                    text-align: center
                    margin-bottom: 10px
                input
                    border: none
                    outline: none
                    border-radius: 5px
                    background: #2D2D2D
                    color: white
                    font-family: Montserrat
                    font-size: 14px
                    font-style: normal
                    font-weight: 500
                    line-height: normal
                    letter-spacing: 0.28px
                    padding: 19px 25px 18px 25px
                    width: 350px
                input::placeholder
                    color: #828282
                button
                    border: none
                    outline: none
                    cursor: pointer
                    border-radius: 5px
                    background: #8C53FD
                    color: #FFF
                    font-family: Montserrat
                    font-size: 16px
                    font-style: normal
                    font-weight: 500
                    line-height: 135%
                    letter-spacing: 0.32px
                    padding: 14px 0px
                    transition: 0.3s
                    margin-top: 10px
                button:active,
                button:hover
                    background: #9370d8
                &_re-password-and-register
                    display: flex
                    justify-content: space-between
                    a
                        text-decoration: none
                        color: #FFF
                        font-family: Montserrat
                        font-size: 16px
                        font-style: normal
                        font-weight: 500
                        line-height: 135%
                        letter-spacing: 0.32px
                        transition: 0.2s
                    a:active,
                    a:hover
                        opacity: 0.6

@media (max-width: 950px)
    .Login
        &_background
            display: none
        &_form
            border-radius: 10px

@media (max-width: 560px)
    .Login
        &_form
            border-radius: 5px
            height: 391px
            padding: 0px 18.5px
            &_form-block
                gap: 15px
                p
                    font-size: 18px
                    letter-spacing: 0.36px
                    margin-bottom: 10px
                input
                    width: 270px
                    border-radius: 5px
                    padding: 15px 19px 14px 19px
                    font-size: 12px
                    letter-spacing: 0.24px
                button
                    margin-top: 5px
                    padding: 12px 0px
                    font-size: 12px
                    letter-spacing: 0.24px
                &_re-password-and-register
                    a
                        font-size: 12px
                        letter-spacing: 0.24px

</style>