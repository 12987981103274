<template>
    <SearchVue />
    <Results />
</template>

<script>
import Results from '@/components/SearchView/Results.vue';
import SearchVue from '@/components/SearchView/Search.vue'

export default {
    name: 'SearchView',
    components: {
        SearchVue,
        Results,
    },
}
</script>

<style lang="sass" scoped>

</style>