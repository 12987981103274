<template>
    <div class="header">
        <div @click="burgerMenu()" class="header_menu">
            <font-awesome-icon v-if="!this.switchMenu" class="header_menu_icon" icon="fa-solid fa-bars" style="color: #ffffff;" />
            <font-awesome-icon v-else class="header_menu_icon" icon="fa-solid fa-xmark" style="color: #ffffff;"/>
        </div>
        <router-link to="/">
            <div class="header_logo">
            </div>
        </router-link>
        <form action="" class="header_formSearch">
            <input v-show="viewSearch" v-model="inputSearch" @input="sendSearch()" class="header_formSearch_input" type="text" id="mainSearch" placeholder="Поиск аниме">
            <button class="header_formSearch_button">
                <span>
                    <font-awesome-icon size="xl" style="color: #b17eff;" icon="fa-solid fa-magnifying-glass" />
                </span>
            </button>
            <SearchHeaderVue
                :animeSearch="animeSearch"
                :searchOpen="searchOpen"
                @closeSearch="closeSearch"
            />
        </form>
        <div class="header_block-button">
<!--            <div class="header_block-button_light-and-dark">-->
<!--                <font-awesome-icon icon="fa-solid fa-sun" spin size="xl" style="color: #6E6E6E;" />-->
<!--            </div>-->
            <a href="https://vk.com/enshi">
                <div class="header_block-button_vk">
                    <font-awesome-icon class="header_block-button_vk_icon" icon="fa-brands fa-vk" size="xl" style="color: #6E6E6E;" />
                </div>
            </a>
        </div>
        <router-link v-if="!$store.getters.TOKEN" to="/login">
            <div class="header_login">
                <font-awesome-icon class="header_login_icon" icon="fa-regular fa-user" size="lg" style="color: #6E6E6E;" />
                <p>Войти</p>
            </div>
        </router-link>
        <router-link v-else-if="this.$store.state.account !== ''" :to="'/account/' + this.$store.state.account.id">
            <div class="auth header_login">
                <img v-if="this.$store.getters.ACCOUNT.avatar != null" class="avatar-header" :src="this.$store.getters.BACKENDURL + '/storage/' + this.$store.getters.ACCOUNT.avatar" alt="Аватар аккаунта">
                <img v-else class="avatar-header" src="../../assets/images/AccountView/AvatarExample.png" alt="Аватар аккаунта">
                <p v-if="this.$store.state.account !== ''">{{ this.$store.state.account.name }}</p>
            </div>
        </router-link>
        <div class="search" @click="openModalSearch()">
            <font-awesome-icon size="xl" style="color: #b17eff;" icon="fa-solid fa-magnifying-glass" />
        </div>
    </div>
    <BurgerMenu
        :switchMenu="switchMenu"
        @closeBurgerMenu="closeBurgerMenu"
    />
</template>

<script>
import axios from 'axios'
import { getCookie } from "@/custom/getCookie";
import BurgerMenu from './Header/BurgerMenu.vue';
import SearchHeaderVue from './Header/SearchHeader.vue';

export default {
  name: 'Header',
  components: {
    BurgerMenu,
    SearchHeaderVue,
  },
  data() {
    return {
        switchMenu: false,
        user: '',
        token: '',
        inputSearch: '',
        animeSearch: [],
        searchOpen: false,
        viewSearch: true,
    }
  },
  mounted() {
    if(JSON.parse(localStorage.getItem('user')) != '') {
        this.user = JSON.parse(localStorage.getItem('user'));
    }
    this.handleResize()
  },
  methods: {
    burgerMenu() {
        if(this.switchMenu === false) {
            this.switchMenu = true;
            document.body.style.overflow = "hidden";
        } else {
            this.switchMenu = false;
            document.body.style.overflow = "auto";
        }
    },
    closeBurgerMenu() {
        this.switchMenu = false;
        document.body.style.overflow = "auto";
    },
    sendSearch()
    {
        if(this.inputSearch !== '') {
            this.searchOpen = true;
            axios.get(this.$store.getters.BACKENDURL + '/api/search/', {
                params: {
                    title: this.inputSearch
                }
            }).then((response) => {
                this.animeSearch = response.data;
            }).catch((errors) => {
                console.log(errors);
            });
        } else {
            this.searchOpen = false;
        }
    },
    closeSearch()
    {
        this.inputSearch = '';
        this.searchOpen = false;
        this.handleResize();
    },
    openModalSearch()
    {
        if(this.viewSearch) {
            this.viewSearch = false;
            this.searchOpen = false;
            this.inputSearch = '';
        } else {
            this.viewSearch = true;
        }
    },
    handleResize() {
        const windowWidth = window.innerWidth;
        if (windowWidth < 700) {
            if (this.viewSearch) {
                this.viewSearch = false;
                this.searchOpen = false;
                this.inputSearch = '';
            }
        } else {
            if (!this.viewSearch) {
                this.viewSearch = true;
            }
        }
    },
  }
}
</script>

<style lang="sass" scoped>
    .auth
        width: 144px !important
        justify-content: flex-start !important
    .avatar-header
        width: 48px
        height: 44px
        object-fit: cover
        object-position: center
        border-radius: 5px 0 0 5px
    .header
        display: flex
        height: 90px
        max-width: 1200px
        margin: auto
        align-items: center
        &_menu
            margin-right: 30px
            z-index: 1000
            &_icon
                padding: 15px 16.25px 15px 16.25px
                cursor: pointer
                background-color: #2D2D2D
                border-radius: 5px
                transition: 0.3s
                width: 17.5px
                height: 20px
            &_icon:active,
            &_icon:hover
                background: #4D4D4D
        &_logo
            background-image: url(../../assets/images/App/Header/enshilogo.svg)
            width: 138px
            height: 23px
            pointer-events: none
            background-size: cover
            margin-right: 175px
        &_formSearch
            margin-right: 155px
            &_input
                border: none
                border-radius: 5px
                background: #2D2D2D
                outline: none
                padding: 14px 30px 13px 30px
                color: white
                font-family: Montserrat
                font-size: 14px
                font-style: normal
                font-weight: 500
                line-height: normal
                letter-spacing: 0.28px
                width: 288px
            &_input::placeholder
                color: #6E6E6E
            &_button
                position: relative
                right: 40px
                background: none
                border: none
                outline: none
                cursor: pointer
                top: -15px
                span
                    position: absolute
        &_block-button
            display: flex
            align-items: center
            gap: 25px
            &_light-and-dark
                border-radius: 5px
                background: #2D2D2D
                backdrop-filter: blur(6.599999904632568px)
                padding: 10px
                cursor: pointer
                transition: 0.3s
            &_light-and-dark:active,
            &_light-and-dark:hover
                background: #4D4D4D
            &_vk
                border-radius: 5px
                background: #2D2D2D
                backdrop-filter: blur(6.599999904632568px)
                padding: 10px 11.5px
                transition: 0.3s
            &_vk:active,
            &_vk:hover
                background: #4D4D4D
        &_login
            display: flex
            border-radius: 5px
            background: #2D2D2D
            width: 110px
            height: 44px
            align-items: center
            justify-content: center
            gap: 12px
            margin-left: 115px
            transition: 0.3s
            p
                color: #FFF
                leading-trim: both
                text-edge: cap
                font-family: Montserrat
                font-size: 13px
                font-style: normal
                font-weight: 500
                line-height: normal
        a
            text-decoration: none
        &_login:active,
        &_login:hover
            background: #4D4D4D
    .search
        display: none

@media (max-width: 1200px)
    .header
        &_menu
            margin-left: 25px
            &_icon
                padding: 12px 13.25px
        &_logo
            margin-right: 156px
        &_block-button
            display: none
        &_login
            display: none
        &_formSearch
            position: absolute
            right: 25px
            margin-right: 0px

@media (max-width: 745px)
    .header
        &_menu
            margin-right: 20px
        &_logo
            width: 108px
            height: 18px

@media (max-width: 700px)
    .header
        &_formSearch
            &_input
                position: absolute
                z-index: 1000
                top: 50px
                right: 0px
            &_button
                display: none
        &_logo
            margin-right: 0px
    .search
        display: block
        border-radius: 5px
        background: #2D2D2D
        padding: 10px
        position: absolute
        right: 25px

@media (max-width: 375px)
    .header
        &_formSearch
            &_input
                width: calc(100vw - 100px)
                right: -11px
    .search
        right: 15px

@media (max-width: 281px)
    .search
        position: static
        margin-right: 15px
</style>