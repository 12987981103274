<template>
    <div class="anime">
        <div class="anime_main">
            <div class="anime_main_header">
                <p class="anime_main_header_title">Смотреть аниме</p>
                <p @click="showFilters()" class="anime_main_header_btn-filters">
                    Фильтры
                    <font-awesome-icon v-if="onFilters === false" icon="fa-solid fa-chevron-down" size="xs" />
                    <font-awesome-icon v-else icon="fa-solid fa-chevron-up" size="xs" />
                </p>
            </div>
            <div class="anime_main_block">
                <div class="anime_main_block_item" v-for="anime in AnimeList.data" :key="anime.id">
                    <router-link :to="'/view/anime-' + anime.id">
                        <img v-if="anime.poster_url" :src="anime.poster_url" alt="Постер аниме" draggable="false">
                        <img v-if="!anime.poster_url" src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
                    </router-link>
                    <p v-if="anime.shikimori_rating >= 7" class="anime_main_block_item_rating green-rating">
                        <font-awesome-icon icon="fa-solid fa-star" /> {{ anime.shikimori_rating }}
                    </p>
                    <p v-else-if="anime.shikimori_rating >= 4.5" class="anime_main_block_item_rating orange-rating">
                        <font-awesome-icon icon="fa-solid fa-star" /> {{ anime.shikimori_rating }}
                    </p>
                    <p v-else-if="anime.shikimori_rating >= 0" class="anime_main_block_item_rating red-rating">
                        <font-awesome-icon icon="fa-solid fa-star" /> {{ anime.shikimori_rating }}
                    </p>
                    <p class="anime_main_block_item_title">{{ anime.title }}</p>
                    <div class="anime_main_block_item_description">
                        <p class="anime_main_block_item_description_year">{{ anime.year }}</p>
                        <p class="anime_main_block_item_description_genres" v-if="anime.anime_genres">{{ anime.anime_genres[0] }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="filters" class="anime_shadow">
            <div class="anime_shadow_filters">
                <div class="anime_shadow_filters_genres">
                    <div class="anime_shadow_filters_genres_header">
                        <p class="anime_shadow_filters_genres_header_title">Жанры</p>
                        <p class="anime_shadow_filters_genres_header_clear" @click="clearCheckGenres()" id="clearButtonGenres">Очистить (0)</p>
                    </div>
                    <div class="anime_shadow_filters_genres_content">
                        <div class="anime_shadow_filters_genres_content_genre" v-for="genre in Genres" :key="genre.id">
                            <input type="checkbox" :id="'genre' + genre.id" :value="genre.name" name="genres" @change="updateCounterGenres()" />
                            <label :for="'genre' + genre.id">{{ genre.name }}</label>
                        </div>
                    </div>
                    <div v-show="onGenres" class="anime_shadow_filters_genres_content check-top">
                        <div class="anime_shadow_filters_genres_content_genre" v-for="genre in DownGenres" :key="genre.id">
                            <input type="checkbox" :id="'genre' + genre.id" :value="genre.name" name="genres" @change="updateCounterGenres()" />
                            <label :for="'genre' + genre.id">{{ genre.name }}</label>
                        </div>
                    </div>
                    <div @click="showGenres()" class="anime_shadow_filters_genres_all-button">
                        <p id="showGenresText">Показать все</p>
                    </div>
                </div>
                <div class="anime_shadow_filters_years">
                    <div class="anime_shadow_filters_years_header">
                        <p class="anime_shadow_filters_years_header_title">Год</p>
                        <p class="anime_shadow_filters_years_header_clear" @click="clearCheckYears()" id="clearButtonYears">Очистить (0)</p>
                    </div>
                    <div class="anime_shadow_filters_years_content">
                        <div class="anime_shadow_filters_years_content_year" v-for="year in Years" :key="year.id">
                            <input type="checkbox" :id="'year' + year.id" :value="year.name" name="years" @change="updateCounterYears()" />
                            <label :for="'year' + year.id">{{ year.name }}</label>
                        </div>
                    </div>
                    <div v-show="onYears" class="anime_shadow_filters_years_content check-top">
                        <div class="anime_shadow_filters_years_content_year" v-for="year in DownYears" :key="year.id">
                            <input type="checkbox" :id="'year' + year.id" :value="year.name" name="years" @change="updateCounterYears()" />
                            <label :for="'year' + year.id">{{ year.name }}</label>
                        </div>
                    </div>
                    <div @click="showYears()" class="anime_shadow_filters_years_all-button">
                        <p id="showYearsText">Показать все</p>
                    </div>
                </div>
                <div class="anime_shadow_filters_statuses">
                    <p class="anime_shadow_filters_statuses_title">Статус</p>
                    <div class="anime_shadow_filters_statuses_status" v-for="status in Statuses" :key="status.id">
                        <input type="checkbox" :id="'status' + status.id" :value="status.value" name="statuses" />
                        <label :for="'status' + status.id">{{ status.name }}</label>
                    </div>
                </div>
                <div class="anime_shadow_filters_buttons">
                    <div @click="clearCheck()" class="anime_shadow_filters_buttons_reset">
                        <p>Сбросить</p>
                    </div>
                    <div @click="hookFilters()" class="anime_shadow_filters_buttons_show">
                        <p>Показать</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pagination">
        <div class="pagination_block">
            <router-link v-if="this.$route.params.page != 1" class="pagination_block_item" :to="'/anime/' + (Number(this.$route.params.page) - 1)">
                <font-awesome-icon icon="fa-solid fa-chevron-left" size="xs" style="color: #fff;" />
            </router-link>
            <router-link v-if="this.$route.params.page != 1 && this.$route.params.page >= 4" class="pagination_block_item pag1" to="/anime/1">1</router-link>
            <p v-if="this.$route.params.page > 4">...</p>
            <router-link v-if="this.$route.params.page > 2" class="pagination_block_item" :to="'/anime/' + (Number(this.$route.params.page) - 2)">{{ Number(this.$route.params.page) - 2 }}</router-link>
            <router-link v-if="this.$route.params.page > 1" class="pagination_block_item" :to="'/anime/' + (Number(this.$route.params.page) - 1)">{{ Number(this.$route.params.page) - 1 }}</router-link>
            <router-link class="pagination_block_item active" :to="'/anime/' + this.$route.params.page">{{ this.$route.params.page }}</router-link>
            <router-link v-if="this.$route.params.page <= (AnimeList.last_page - 1)" class="pagination_block_item" :to="'/anime/' + (Number(this.$route.params.page) + 1)">{{ Number(this.$route.params.page) + 1 }}</router-link>
            <router-link v-if="this.$route.params.page <= (AnimeList.last_page - 2)" class="pagination_block_item" :to="'/anime/' + (Number(this.$route.params.page) + 2)">{{ Number(this.$route.params.page) + 2 }}</router-link>
            <p v-if="this.$route.params.page < (AnimeList.last_page - 3)">...</p>
            <router-link v-if="this.$route.params.page != AnimeList.last_page && this.$route.params.page <= (AnimeList.last_page - 3)" :class="'pagination_block_item pag' + AnimeList.last_page" :to="'/anime/' + AnimeList.last_page">{{ AnimeList.last_page }}</router-link>
            <router-link v-if="this.$route.params.page != AnimeList.last_page" class="pagination_block_item" :to="'/anime/' + (Number(this.$route.params.page) + 1)">
                <font-awesome-icon icon="fa-solid fa-chevron-right" size="xs" style="color: #fff;" />
            </router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'AnimeList',
    components: {

    },
    data() {
        return {
            AnimeList: {},
            Genres: [
                {
                    id: 1,
                    name: 'Военное'
                },
                {
                    id: 2,
                    name: 'Комедия'
                },
                {
                    id: 3,
                    name: 'Драма'
                },
                {
                    id: 4,
                    name: 'Школа'
                },
                {
                    id: 5,
                    name: 'Демоны'
                },
                {
                    id: 6,
                    name: 'Гарем'
                },
            ],
            DownGenres: [
                {
                    id: 7,
                    name: 'Спорт'
                },
                {
                    id: 8,
                    name: 'Сёнен'
                },
                {
                    id: 9,
                    name: 'Фэнтези'
                },
                {
                    id: 10,
                    name: 'Демоны'
                },
                {
                    id: 11,
                    name: 'Космос'
                },
                {
                    id: 12,
                    name: 'Вампиры'
                },
                {
                    id: 13,
                    name: 'Игры'
                },
                {
                    id: 14,
                    name: 'Гурман'
                },
                {
                    id: 15,
                    name: 'Меха'
                },
                {
                    id: 16,
                    name: 'Экшен'
                },
            ],
            Years: [
                {
                    id: 1,
                    name: '2024'
                },
                {
                    id: 2,
                    name: '2023'
                },
                {
                    id: 3,
                    name: '2022'
                },
                {
                    id: 4,
                    name: '2021'
                },
                {
                    id: 5,
                    name: '2020'
                },
                {
                    id: 6,
                    name: '2019'
                },
                {
                    id: 7,
                    name: '2018'
                },
                {
                    id: 8,
                    name: '2017'
                },
                {
                    id: 9,
                    name: '2016'
                },
            ],
            DownYears: [
                {
                    id: 10,
                    name: '2015'
                },
                {
                    id: 11,
                    name: '2014'
                },
                {
                    id: 12,
                    name: '2013'
                },
                {
                    id: 13,
                    name: '2012'
                },
                {
                    id: 14,
                    name: '2011'
                },
                {
                    id: 15,
                    name: '2010'
                },
                {
                    id: 16,
                    name: '2009'
                },
                {
                    id: 17,
                    name: '2008'
                },
                {
                    id: 18,
                    name: '2007'
                },
                {
                    id: 19,
                    name: '2006'
                },
                {
                    id: 19,
                    name: '2005'
                },
                {
                    id: 20,
                    name: '2004'
                },
                {
                    id: 21,
                    name: '2003'
                },
                {
                    id: 22,
                    name: '2002'
                },
                {
                    id: 23,
                    name: '2001'
                },
                {
                    id: 24,
                    name: '2000'
                },
                {
                    id: 25,
                    name: '1999'
                },
                {
                    id: 26,
                    name: '1998'
                },
            ],
            Statuses: [
                {
                    id: 1,
                    name: 'Завершено',
                    value: 'released'
                },
                {
                    id: 2,
                    name: 'Онгоинг',
                    value: 'ongoing'
                },
                {
                    id: 3,
                    name: 'Анонс',
                    value: 'anons'
                },
            ],
            onFilters: false,
            onGenres: false,
            onYears: false,
        }
    },
    watch: {
        '$route.params.page': {
            handler(newVal, oldVal) {
                this.sendFilters(newVal);
            },
            immediate: true
        }
    },
    mounted() {
        this.sendFilters()
    },
    methods: {
        showFilters()
        {
            if(this.onFilters === false)
            {
                document.getElementById('filters').style.display = 'block'
                this.onFilters = true
            }
            else
            {
                document.getElementById('filters').style.display = 'none'
                this.onFilters = false 
            }
        },
        showGenres()
        {
            if(this.onGenres === false) {
                document.getElementById('showGenresText').innerHTML = 'Скрыть'
                this.onGenres = true
            } else {
                document.getElementById('showGenresText').innerHTML = 'Показать все'
                this.onGenres = false
            }
        },
        updateCounterGenres()
        {
            const checkboxes = document.querySelectorAll('input[type="checkbox"][name="genres"]');
            const checkedCount = Array.from(checkboxes).filter(checkbox => checkbox.checked).length;
            document.getElementById('clearButtonGenres').textContent = `Очистить (${checkedCount})`;
        },
        clearCheckGenres()
        {
            const checkboxes = document.querySelectorAll('input[type="checkbox"][name="genres"]');
            checkboxes.forEach(checkbox => checkbox.checked = false);
            this.updateCounterGenres();
        },
        showYears()
        {
            if(this.onYears === false) {
                document.getElementById('showYearsText').innerHTML = 'Скрыть'
                this.onYears = true
            } else {
                document.getElementById('showYearsText').innerHTML = 'Показать все'
                this.onYears = false
            }
        },
        updateCounterYears()
        {
            const checkboxes = document.querySelectorAll('input[type="checkbox"][name="years"]');
            const checkedCount = Array.from(checkboxes).filter(checkbox => checkbox.checked).length;
            document.getElementById('clearButtonYears').textContent = `Очистить (${checkedCount})`;
        },
        clearCheckYears()
        {
            const checkboxes = document.querySelectorAll('input[type="checkbox"][name="years"]');
            checkboxes.forEach(checkbox => checkbox.checked = false);
            this.updateCounterYears();
        },
        clearCheck()
        {
            const checkboxesyears = document.querySelectorAll('input[type="checkbox"][name="years"]');
            checkboxesyears.forEach(checkbox => checkbox.checked = false);
            const checkboxesgenres = document.querySelectorAll('input[type="checkbox"][name="genres"]');
            checkboxesgenres.forEach(checkbox => checkbox.checked = false);
            const checkboxesstatuses = document.querySelectorAll('input[type="checkbox"][name="statuses"]');
            checkboxesstatuses.forEach(checkbox => checkbox.checked = false);
            this.updateCounterYears();
            this.updateCounterGenres();
        },
        hookFilters()
        {
            this.$router.push('/anime/1');
            this.sendFilters();
        },
        sendFilters(page)
        {
            const checkboxesyears = document.querySelectorAll('input[type="checkbox"]:checked[name="years"]');
            const checkboxesgenres = document.querySelectorAll('input[type="checkbox"]:checked[name="genres"]');
            const checkboxesstatuses = document.querySelectorAll('input[type="checkbox"]:checked[name="statuses"]');
            let valueYears = [];
            let valueGenres = [];
            let valueStatuses = [];
            checkboxesyears.forEach(function(checkbox) {
                valueYears.push(checkbox.value);
            });
            checkboxesgenres.forEach(function(checkbox) {
                valueGenres.push(checkbox.value);
            });
            checkboxesstatuses.forEach(function(checkbox) {
                valueStatuses.push(checkbox.value);
            });

            this.$nextTick(() => {
                setTimeout(() => {
                    if(document.getElementById('pag' + this.$route.params.page)) {
                        document.getElementById('pag' + this.$route.params.page).style.background = 'rgb(140, 83, 253)';
                    } 
                }, 800)
            });

            axios.get(this.$store.getters.BACKENDURL + '/api/filter-search/', {
                params: {
                    genres: valueGenres,
                    years: valueYears,
                    statuses: valueStatuses,
                    page: page,
                }
            }).then((response) => {
                console.log(response.data),
                this.AnimeList = response.data;
            }).catch((errors) => {
                console.log(errors);
            });
        }
    },
}
</script>

<style lang="sass" scoped>
    .check-top
        margin-top: 10px !important
    .active
        background: rgb(140, 83, 253) !important
    .pagination
        color: rgb(245, 245, 245)
        font-family: Montserrat
        font-size: 16px
        font-weight: 500
        line-height: 20px
        letter-spacing: 2%
        display: flex
        justify-content: center
        margin-top: 60px
        margin-bottom: 135px
        gap: 20px
        &_block
            display: flex
            flex-direction: row
            gap: 20px
            &_item
                color: rgb(255, 255, 255)
                font-family: Montserrat
                font-size: 16px
                font-weight: 500
                line-height: 20px
                letter-spacing: 2%
                text-decoration: none
                border-radius: 5px
                padding: 4px 11px
                transition: 0.2s
            &_item:active,
            &_item:hover
                background: rgb(140, 83, 253)

    .anime
        margin-top: 80px
        display: flex
        min-height: calc(100vh)
        justify-content: space-between
        &_main
            &_header
                display: flex
                justify-content: space-between
                &_title
                    color: rgb(245, 245, 245)
                    font-family: Montserrat
                    font-size: 23px
                    font-weight: 700
                    line-height: 28px
                    letter-spacing: 4%
                &_btn-filters
                    border-radius: 5px
                    background: rgb(45, 45, 45)
                    color: rgb(255, 255, 255)
                    font-family: Montserrat
                    font-size: 18px
                    font-weight: 500
                    line-height: 22px
                    letter-spacing: 2%
                    cursor: pointer
                    width: 154px
                    height: 44px
                    display: flex
                    justify-content: center
                    align-items: center
                    gap: 10px
                    display: none
            &_block
                margin-top: 43px
                display: flex
                flex-wrap: wrap
                justify-content: center
                gap: 35px
                &_item
                    max-width: 196px
                    a
                        img
                            width: 196px
                            height: 285px
                            border-radius: 20px
                            animation: animateImg 0.5s
                        img:active,
                        img:hover
                            opacity: 0.8
                    &_rating
                        color: rgb(255, 255, 255)
                        font-family: Montserrat
                        font-size: 14px
                        font-weight: 600
                        line-height: 17px
                        letter-spacing: 2%
                        display: flex
                        flex-direction: row
                        justify-content: center
                        align-items: center
                        border-radius: 0px 20px 0px 20px
                        width: 100px
                        height: 32px
                        gap: 5px
                        margin-top: -36px
                        position: relative
                    &_title
                        color: rgb(245, 245, 245)
                        font-family: Montserrat
                        font-size: 15px
                        font-weight: 600
                        line-height: 18px
                        letter-spacing: 2%
                        margin-top: 15px
                        -webkit-line-clamp: 2
                        overflow: hidden
                        text-overflow: ellipsis
                        display: -webkit-box
                        -webkit-box-orient: vertical
                    &_description
                        margin-top: 10px
                        color: rgb(155, 155, 157)
                        font-family: Montserrat
                        font-size: 14px
                        font-weight: 500
                        line-height: 17px
                        letter-spacing: 2%
                        display: flex
                        justify-content: space-between
                        &_genres
                            display: flex
                            flex-wrap: wrap
                            max-width: 10.8ch
                            white-space: nowrap
                            overflow: hidden
                            text-overflow: ellipsis
        &_shadow
            margin-left: 35px
            &_filters
                border-radius: 20px
                background: rgb(31, 30, 35)
                width: 276px
                padding: 30px 0px
                &_genres
                    margin-left: 20px
                    margin-right: 20px
                    &_header
                        display: flex
                        flex-wrap: wrap
                        justify-content: space-between
                        &_title
                            color: rgb(255, 255, 255)
                            font-family: Montserrat
                            font-size: 18px
                            font-weight: 500
                            line-height: 22px
                            letter-spacing: 0%
                        &_clear
                            color: rgb(110, 110, 110)
                            font-family: Montserrat
                            font-size: 14px
                            font-weight: 500
                            line-height: 17px
                            letter-spacing: 0%
                            cursor: pointer
                            transition: 0.2s
                        &_clear:active,
                        &_clear:hover
                            opacity: 0.5
                    &_content
                        margin-top: 20px
                        display: flex
                        flex-wrap: wrap
                        gap: 10px
                        justify-content: space-between
                        &_genre
                            display: flex
                            input
                                display: none
                            label
                                color: rgb(255, 255, 255)
                                font-family: Montserrat
                                font-size: 14px
                                font-weight: 500
                                line-height: 17px
                                letter-spacing: 0%
                                cursor: pointer
                                width: 110px
                            label:before
                                content: " "
                                display: inline-block
                                margin-right: 15px
                                position: relative
                                width: 20px
                                height: 20px
                                background: rgb(31, 30, 35)
                                border: 1px solid rgb(60, 60, 60)
                                border-radius: 5px
                                text-align: center
                                border-image: initial
                                vertical-align: middle
                    &_all-button
                        color: rgb(110, 110, 110)
                        font-family: Montserrat
                        font-size: 14px
                        font-weight: 500
                        line-height: 17px
                        letter-spacing: 0%
                        margin-top: 20px
                        cursor: pointer
                        border-radius: 0px 0px 5px 5px
                        background: rgb(45, 45, 45)
                        text-align: center
                        width: 100%
                        padding: 14px 0px
                        transition: 0.3s
                    &_all-button:active,
                    &_all-button:hover
                        background: #4D4D4D
                &_years
                    margin-top: 35px
                    margin-left: 20px
                    margin-right: 20px
                    &_header
                        display: flex
                        flex-wrap: wrap
                        justify-content: space-between
                        &_title
                            color: rgb(255, 255, 255)
                            font-family: Montserrat
                            font-size: 18px
                            font-weight: 500
                            line-height: 22px
                            letter-spacing: 0%
                        &_clear
                            color: rgb(110, 110, 110)
                            font-family: Montserrat
                            font-size: 14px
                            font-weight: 500
                            line-height: 17px
                            letter-spacing: 0%
                            cursor: pointer
                            transition: 0.2s
                        &_clear:active,
                        &_clear:hover
                            opacity: 0.5
                    &_content
                        margin-top: 20px
                        display: flex
                        flex-wrap: wrap
                        gap: 10px
                        justify-content: space-between
                        &_year
                            display: flex
                            input
                                display: none
                            label
                                color: rgb(255, 255, 255)
                                font-family: Montserrat
                                font-size: 14px
                                font-weight: 500
                                line-height: 17px
                                letter-spacing: 0%
                                cursor: pointer
                                width: 72px
                                display: flex
                            label:before
                                content: " "
                                display: inline-block
                                margin-right: 15px
                                position: relative
                                width: 20px
                                height: 20px
                                background: rgb(31, 30, 35)
                                border: 1px solid rgb(60, 60, 60)
                                border-radius: 5px
                                text-align: center
                                border-image: initial
                                vertical-align: middle
                    &_all-button
                        color: rgb(110, 110, 110)
                        font-family: Montserrat
                        font-size: 14px
                        font-weight: 500
                        line-height: 17px
                        letter-spacing: 0%
                        margin-top: 20px
                        cursor: pointer
                        border-radius: 0px 0px 5px 5px
                        background: rgb(45, 45, 45)
                        text-align: center
                        width: 100%
                        padding: 14px 0px
                        transition: 0.3s
                    &_all-button:active,
                    &_all-button:hover
                        background: #4D4D4D
                &_statuses
                    margin-top: 35px
                    margin-left: 20px
                    margin-right: 20px
                    &_title
                        color: rgb(255, 255, 255)
                        font-family: Montserrat
                        font-size: 18px
                        font-weight: 500
                        line-height: 22px
                        letter-spacing: 0%
                        margin-bottom: 20px
                    &_status
                        margin-top: 10px
                        margin-bottom: 10px
                        input
                            display: none
                        label
                            color: rgb(255, 255, 255)
                            font-family: Montserrat
                            font-size: 14px
                            font-weight: 500
                            line-height: 17px
                            letter-spacing: 0%
                            cursor: pointer
                        label:before
                            content: " "
                            display: inline-block
                            margin-right: 15px
                            position: relative
                            width: 20px
                            height: 20px
                            background: rgb(31, 30, 35)
                            border: 1px solid rgb(60, 60, 60)
                            border-radius: 5px
                            text-align: center
                            border-image: initial
                            vertical-align: middle
                &_buttons
                    color: rgb(255, 255, 255)
                    font-family: Montserrat
                    font-size: 14px
                    font-weight: 500
                    line-height: 17px
                    letter-spacing: 0%
                    margin-left: 20px
                    margin-right: 20px
                    display: flex
                    justify-content: space-between
                    margin-top: 54px
                    &_reset
                        border-radius: 5px
                        background: rgb(45, 45, 45)
                        cursor: pointer
                        width: 104px
                        height: 38px
                        display: flex
                        justify-content: center
                        align-items: center
                        transition: 0.3s
                    &_reset:active,
                    &_reset:hover
                        background: #4D4D4D
                    &_show
                        border-radius: 5px
                        background: rgb(140, 83, 253)
                        cursor: pointer
                        width: 112px
                        height: 38px
                        display: flex
                        justify-content: center
                        align-items: center
                        transition: 0.3s
                    &_show:active,
                    &_show:hover
                        background-color: rgb(177, 126, 255)

@media (max-width: 1200px)
    .anime
        padding: 0px 25px

@media (max-width: 768px)
    .anime
        &_main
            &_header
                &_btn-filters
                    display: flex
        &_shadow
            display: none
            position: absolute
            right: 25px
            margin-top: 70px

@media (max-width: 480px)
    .anime
        padding: 0px 10px
        margin-top: 62px
        &_main
            &_header
                margin-left: 5px
                margin-right: 5px
                flex-wrap: wrap
                gap: 20px
                &_btn-filters
                    font-size: 14px
                    line-height: 17px
                    width: 118px
                    height: 37px
            &_block
                gap: 12px
                margin-top: 25px
                &_item
                    max-width: 110px
                    margin-top: 18px
                    a
                        img
                            width: 110px
                            height: 160px
                            border-radius: 10px
                    &_rating
                        font-size: 8px
                        line-height: 10px
                        width: 52px
                        height: 18px
                        border-radius: 0px 10px 0px 10px
                        margin-top: -22px
                    &_title
                        margin-top: 10px
                        font-size: 12.5px
                        line-height: 15px
                    &_description
                        margin-top: 5px
                        font-size: 10.5px
                        line-height: 11px
        &_shadow
            right: 15px
    .pagination
        &_block
            gap: 2px
</style>