<template>
    <div v-if="searchOpen" class="search-anime">
        <div v-for="anime in animeSearch" :key="anime.id" class="search-anime_item">
            <router-link :to="'/view/anime-' + anime.id" @click="closeSearch()">
                <img v-if="anime.poster_url" :src="anime.poster_url" alt="Постер аниме">
                <img v-else src="../../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме">
                <div class="search-anime_item_desc">
                    <p class="search-anime_item_desc_title">{{ anime.title }}</p>
                    <div class="search-anime_item_desc_content">
                        <p class="search-anime_item_desc_content_rating">
                            <font-awesome-icon icon="fa-solid fa-star" />
                            {{ anime.shikimori_rating }}
                        </p>
                        <p class="search-anime_item_desc_content_year">{{ anime.year }}</p>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchHeader',
    components: {

    },
    props: [
        'searchOpen',
        'animeSearch',
    ],
    data() {
        return {
        }
    },
    methods: {
        closeSearch() {
            this.$emit('closeSearch', false);
        }
    }
}
</script>

<style lang="sass" scoped>
    p
        color: white
    .search-anime::-webkit-scrollbar
        width: 5px
        background: none
    .search-anime::-webkit-scrollbar-thumb
        background: #2D2D2D
        width: 3px
        border-radius: 20px
    .search-anime::-webkit-scrollbar-track
        background: none
    .search-anime
        width: 348px
        max-height: 452px
        overflow: auto
        background: rgb(45, 45, 45)
        border-radius: 5px
        position: absolute
        z-index: 1000
        margin-top: 10px
        &_item
            display: flex
            justify-content: center
            padding: 10px 0px
            border-bottom: 1.5px solid rgb(58, 58, 58)
            border-top: 1.5px solid rgb(58, 58, 58)
            transition: 0.2s
            a
                text-decoration: none
                display: flex
                gap: 13px
                img
                    width: 64px
                    height: 93px
                    border-radius: 5px
            &_desc
                width: 211px
                display: flex
                flex-direction: column
                gap:  10px
                justify-content: center
                &_title
                    font-size: 15px
                    font-weight: 600
                    line-height: 18px
                    letter-spacing: 2%
                &_content
                    display: flex
                    justify-content: space-between
                    &_rating
                        font-size: 14px
                        font-weight: 600
                        line-height: 17px
                        letter-spacing: 2%
                        color: rgb(28, 186, 110)
                    &_year
                        font-size: 14px
                        font-weight: 500
                        line-height: 17px
                        letter-spacing: 2%
                        color: rgb(155, 155, 157)
        &_item:first-child
            border-top: none
        &_item:last-child
            border-top: none
        &_item:active,
        &_item:hover
            opacity: 0.8
@media (max-width: 700px)
    .search-anime
        right: 0px
        top: 90px

@media (max-width: 375px)
    .search-anime
        width: calc(100vw - 40px)
        right: -11px
</style>