<template>
    <AnimePage />
    <SimilarAnime />
    <AkinAnime />
    <AnimeCommentsVue />
</template>

<script>
import AnimePage from '../components/AnimePageView/AnimePage.vue';
import SimilarAnime from '@/components/AnimePageView/SimilarAnime.vue';
import AkinAnime from '@/components/AnimePageView/AkinAnime.vue';
import AnimeCommentsVue from '@/components/AnimePageView/AnimeComments.vue';

export default {
    name: 'AnimePageView',
    components: {
        AnimePage,
        SimilarAnime,
        AkinAnime,
        AnimeCommentsVue,
    },
    mounted() {
        window.scrollTo(0, 0);
    },
}
</script>

<style lang="sass" scoped>

</style>