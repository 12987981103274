<template>
    <div class="akin">
        <div class="akin_header">
            <div class="akin_header_titleblock">
                <p>
                    Похожее
                </p>
            </div>
        </div>
        <div class="akin_swiper">
            <div class="swiper-akin">
                <div class="swiper-wrapper">
                    <div v-for="slide in Swiper" :key="slide.id" class="swiper-slide">
                        <router-link style="display: block;" :to="'/view/anime-' + slide.id">
                            <img v-if="slide.poster_url" :src="slide.poster_url" alt="Постер аниме">
                        </router-link>
                        <p v-if="slide.shikimori_rating >= 7" class="anime-rating green-rating">
                            <font-awesome-icon icon="fa-solid fa-star" />
                            {{ slide.shikimori_rating }}
                        </p>
                        <p v-else-if="slide.shikimori_rating >= 4.5" class="anime-rating orange-rating">
                            <font-awesome-icon icon="fa-solid fa-star" />
                            {{ slide.shikimori_rating }}
                        </p>
                        <p v-else-if="slide.shikimori_rating >= 0" class="anime-rating red-rating">
                            <font-awesome-icon icon="fa-solid fa-star" />
                            {{ slide.shikimori_rating }}
                        </p>
                        <div class="description">
                            <p class="description_title-anime">{{ slide.title }}</p>
                            <div class="description_year-and-genres">
                                <p class="description_year-and-genres_year">{{ slide.year }}</p>
                                <p v-if="slide.anime_genres" class="description_year-and-genres_genres">{{ slide.anime_genres[0] }}/<span>{{ slide.anime_genres[1] }}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-akin-button-next">
                <font-awesome-icon icon="fa-solid fa-chevron-right" size="xl" style="color: #fff;" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import Swiper from 'swiper/bundle';

import 'swiper/css';

import 'swiper/css/pagination'

export default {
    name: 'AkinAnime',
    components: {
    
    },
    data() {
        return {
            Swiper: [],
        }
    },
    watch: {
        '$route.params.id': {
            handler(newVal, oldVal) {
                this.akinAnime(newVal);
            },
            immediate: true
        }
    },
    created() {
    },
    mounted() {
        const swiper = new Swiper('.swiper-akin', {
        direction: 'horizontal',
        breakpoints: {
            0: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            350: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            470: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            700: {
                slidesPerView: 3.50,
                spaceBetween: 35,
            },
            950: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            1200: {
                slidesPerView: 5,
                spaceBetween: 50,
            },

        },

        navigation: {
            nextEl: '.swiper-akin-button-next',
            prevEl: '.swiper-akin-button-prev',
        },
        });
    },
    methods: {
        akinAnime(id)
        {
            axios.get(`${this.$store.getters.BACKENDURL}/api/akinAnime/${this.$route.params.id}`).then(response => (
                this.Swiper = response.data
            )).catch((errors) => {
                console.log(errors)
            });
        }
    }
}
</script>

<style lang="sass" scoped>

    .swiper-akin
        overflow: hidden
        margin-right: 25px
    .swiper-slide
        max-width: 200px
        margin-right: 50px
        img
            width: 196px
            height: 285px
            border-radius: 20px
        .anime-rating
            border-radius: 0px 20px 0px 20px
            color: rgb(255, 255, 255)
            font-family: Montserrat
            font-size: 14px
            font-weight: 600
            line-height: 17px
            letter-spacing: 2%
            text-align: left
            width: 100px
            height: 32px
            display: flex
            justify-content: center
            align-items: center
            gap: 1.5px
            margin-top: -36px
            z-index: 999
            position: relative
        .description
            &_title-anime
                color: rgb(245, 245, 245)
                font-family: Montserrat
                font-size: 15px
                font-weight: 600
                line-height: 18px
                letter-spacing: 2%
                text-align: left
                margin-top: 15px
                width: 196px
                -webkit-line-clamp: 2
                overflow: hidden
                text-overflow: ellipsis
                display: -webkit-box
                -webkit-box-orient: vertical
            &_year-and-genres
                display: flex
                justify-content: space-between
                margin-top: 10px
                color: rgb(155, 155, 157)
                font-family: Montserrat
                font-size: 14px
                font-weight: 500
                line-height: 17px
                letter-spacing: 2%
                text-align: left
                width: 196px
                &_genres
                    display: flex
                    flex-wrap: wrap
                    justify-content: flex-end
    .swiper-akin-button-next
        margin-right: -40px
        margin-top: 135px
        cursor: pointer
    .akin
        margin-bottom: 100px
        &_header
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            &_titleblock
                p
                    color: rgb(245, 245, 245)
                    font-family: Montserrat
                    font-size: 23px
                    font-weight: 600
                    line-height: 28px
                    letter-spacing: 4%
                    text-align: left
        &_swiper
            margin-top: 52px
            display: flex

@media (max-width: 1300px)
    .swiper-akin-button-next
        display: none
    .swiper-akin
        margin-right: 0px

@media (max-width: 1200px)

    .swiper-akin
        width: 1010px
    .akin
        &_header
            padding: 0px 25px
        &_swiper
            justify-content: flex-end
            margin-left: 25px

@media (max-width: 950px)
    .swiper-akin
        width: 743px

@media (max-width: 700px)
    .swiper-slide
        img
            width: 110px
            height: 160px
            border-radius: 10px
        .anime-rating
            font-size: 8px
            line-height: 10px
            border-radius: 0px 10px 0px 10px
            width: 52px
            height: 18px
            margin-top: -22px
        .description
            &_title-anime
                width: 110px
                -webkit-line-clamp: 1
                font-size: 12.5px
                line-height: 15px
                margin-top: 10px
            &_year-and-genres
                width: 110px
                font-size: 10.5px
                line-height: 11px
                margin-top: 5px
                align-items: center
                &_genres
                    display: flex
                    flex-direction: column
    .swiper-akin
        width: 100%
    .akin
        margin-top: 50px
        &_header
            padding: 0px 10px
            &_titleblock
                p
                    font-size: 18px
                    line-height: 22px

        &_swiper
            justify-content: center
            margin-left: 10px
            margin-right: 10px
            margin-top: 20px
</style>