<script>
import LastCommentsItem from "@/components/LastComments/LastCommentsItem.vue";

export default {
  name: 'LasCommentsList',
  components: {
    LastCommentsItem
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: []
    }
  }
}
</script>

<template>
  <div class="LastComments__list">
    <LastCommentsItem v-for="item of data" :key="item.id" :data="item" />
  </div>
</template>

<style lang="sass">
.LastComments__list
  display: flex
  box-sizing: border-box
  flex-wrap: wrap
  justify-content: center
  gap: 20px

</style>