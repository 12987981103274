<template>
    <!-- <Loader 
        :cLoader="cLoader"
        id="cLoader"
    /> -->
    <AccountCover />
    <div class="content-position">
        <!-- <AccountPreview 
            @closeLoader="closeLoader"
            @openLoader="openLoader"
        /> -->
        <AccountPreview
            @dataUser="dataUser"
        />
        <AccountAnime
            :dataU="dataU"
        />
    </div>
</template>

<script>
import AccountCover from '@/components/AccountView/AccountCover.vue';
import AccountPreview from '@/components/AccountView/AccountPreview.vue';
import AccountAnime from '@/components/AccountView/AccountAnime.vue';
import Loader from '@/components/App/Loader.vue';

export default {
    name: 'AccountView',
    components: {
        AccountCover,
        AccountPreview,
        AccountAnime,
        Loader,
    },
    data() {
        return {
            cLoader: true,
            token: '',
            cFavorites: '',
            dataU: {},
        }
    },
    methods: {
        closeLoader()
        {
            let cloader = document.getElementById('cLoader');
            setTimeout(() => {
                cloader.classList.add('cLoaderDeny');
            }, 600)
            setTimeout(() => {
                document.body.style.overflow = "auto";
                this.cLoader = false
            }, 700)
        },
        openLoader()
        {
            document.body.style.overflow = "hidden";
            this.cLoader = true
        },
        dataUser(data) {
            this.dataU = data;
        }
    }
}
</script>

<style lang="sass" scoped>
    .cLoaderDeny
        opacity: 0
    .content-position
        padding-top: 340px
        display: flex
        gap: 40px
        margin-bottom: 115px

@media (max-width: 1025px)
    .content-position
        flex-wrap: wrap
        justify-content: center

@media (max-width: 850px)
    .content-position
        padding-top: 220px
</style>