<template>
  <Banner/>
  <NewSeries/>
  <ReleaseToday/>
  <RandomAnime/>
  <Recommendations/>
  <ViewAnime/>
  <LastComments/>
</template>

<script>
import Banner from '@/components/MainView/Banner.vue';
import NewSeries from '@/components/MainView/NewSeries.vue';
import RandomAnime from '@/components/MainView/RandomAnime.vue';
import Recommendations from '@/components/MainView/Recommendations.vue';
import ViewAnime from '@/components/MainView/ViewAnime.vue';
import EnshiPlayer from '@/components/VideoPlayer/EnshiPlayer.vue';
import LastComments from "@/components/MainView/LastComments.vue";
import ReleaseToday from "@/components/MainView/ReleaseToday.vue";
import EnshiPlayerWindow from '@/components/VideoPlayer/EnshiPlayerWindow1.vue';

export default {
  name: 'MainView',
  components: {
    ReleaseToday,
    LastComments,
    Banner,
    NewSeries,
    RandomAnime,
    Recommendations,
    ViewAnime,
    EnshiPlayer,
    EnshiPlayerWindow
  }
}
</script>

<style lang="sass" scoped>
.alpha-version
  padding: 60px 20px
  margin: 60px 0
  border-radius: 10px
  background: rgb(31, 30, 35)


  h1
    color: rgb(245, 245, 245)
    font-family: Montserrat
    font-size: 23px
    font-weight: 600
    line-height: 28px
    letter-spacing: 4%
    text-align: left
</style>
