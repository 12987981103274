import {createRouter, createWebHistory} from 'vue-router'
import {getCookie} from "@/custom/getCookie";
import MainView from '../views/MainView.vue'
import Error404View from '../views/Error404View.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import AnimeView from '../views/AnimeView.vue'
import AnimePageView from '../views/AnimePageView.vue'
import AccountView from '@/views/AccountView.vue'
import AccountSettingsView from '@/views/AccountSettingsView.vue'
import SearchView from '@/views/SearchView.vue'
import AdminPanelView from '@/views/AdminPanelView.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView,
    meta: {
      title: 'Enshi. | Главная'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {requiresGuest: true}
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {requiresGuest: true}
  },
  {
    path: '/anime/:page',
    name: 'anime',
    component: AnimeView
  },
  {
    path: '/anime/search',
    name: 'animesearch',
    component: SearchView
  },

  {
    path: '/view/anime-:id',
    name: 'animepage',
    component: AnimePageView
  },
  {
    path: '/account/:id',
    name: 'account',
    component: AccountView,
    meta: {requiresAuth: true}
  },
  {
    path: '/account/settings',
    name: 'accountsettings',
    component: AccountSettingsView,
    meta: {requiresAuth: true}
  },
  {
    path: '/account/adminpanel',
    name: 'adminpanel',
    component: AdminPanelView,
  },
  {
    path: '/404',
    name: '404',
    component: Error404View
  },
  {
    path: '/:catchAll(.*)', redirect: '404'
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = getCookie('access_token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({path: '/login'});
  } else if (to.matched.some(record => record.meta.requiresGuest) && token) {
    next({path: '/'});
  } else {
    next();
  }
})

export default router
