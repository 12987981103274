<template>
    <div class="avatar-cover">
        <div class="avatar-cover_change-btn-cover">
            <label for="update-cover" class="button-form">Обновить</label>
            <input type="file" @change="handleCoverUpload" id="update-cover" name="update-cover"/>
        </div>
        <div v-if="this.$store.getters.ACCOUNT.cover !== null" :style="`background-image: url(${this.$store.getters.BACKENDURL}/storage/${this.$store.getters.ACCOUNT.cover})`" class="avatar-cover_cover"></div>
        <div v-else class="avatar-cover_cover"></div>
        <div class="avatar-cover_change-btn-avatar">
            <img v-if="this.$store.getters.ACCOUNT.avatar != null" class="avatar-cover_change-btn-avatar_avatar" :src="this.$store.getters.BACKENDURL + '/storage/' + this.$store.getters.ACCOUNT.avatar" alt="Аватар профиля">
            <img v-else class="avatar-cover_change-btn-avatar_avatar" src="../../assets/images/AccountView/AvatarExample.png" alt="Аватар профиля">
            <label for="update-avatar" class="button-form">Обновить</label>
            <input type="file" @change="handleAvatarUpload" id="update-avatar" name="update-avatar"/>
        </div>
    </div>
</template>

<script>
import { getCookie } from '@/custom/getCookie';
import axios from 'axios'
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: 'ChangeAvatarCover',
    components: {

    },
    data() {
        return {
            avatar: null,
            cover: null,
        }
    },
    methods: {
        handleAvatarUpload(event)
        {
            this.avatar = event.target.files[0];

            this.uploadAvatar();
        },
        uploadAvatar() {
            let token = getCookie('access_token');
            let formData = new FormData();
            formData.append('avatar', this.avatar);

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            axios.post(this.$store.getters.BACKENDURL + '/api/upload-avatar', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                toast("Ваш аватар обновлен!", {
                    "theme": "colored",
                    "type": "success",
                    "dangerouslyHTMLString": true
                })
            })
            .catch(error => {
                console.error('Error uploading avatar');
            });
        },
        handleCoverUpload(event)
        {
            this.cover = event.target.files[0];

            this.uploadCover();
        },
        uploadCover() {
            let token = getCookie('access_token');
            let formData = new FormData();
            formData.append('cover', this.cover);

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            axios.post(this.$store.getters.BACKENDURL + '/api/upload-cover', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                toast("Ваша обложка обновлена!", {
                    "theme": "colored",
                    "type": "success",
                    "dangerouslyHTMLString": true
                })
            })
            .catch(error => {
                console.error('Error uploading avatar');
            });
        }
    }
}
</script>

<style lang="sass" scoped>
    p
        color: white
    #update-avatar
        display: none
    #update-cover
        display: none
    .avatar-cover
        border-radius: 10px
        background: rgb(31, 30, 35)
        margin-top: 84px
        height: 342px
        margin-bottom: 135px
        &_change-btn-cover
            padding-top: 60px
            display: flex
            justify-content: flex-end
            margin-right: 95px
            z-index: 1000
            position: relative
        &_cover
            margin: auto
            margin-top: -64px
            width: 1050px
            height: 160px
            border-radius: 10px
            opacity: 0.8
            background-size: cover
            background-position: 50%!important
            background-image: url(../../assets/images/AccountView/AccountCoverExample.png)
        &_change-btn-avatar
            display: flex
            margin-top: -38px
            position: relative
            z-index: 99
            gap: 40px
            align-items: flex-end
            &_avatar
                width: 100px
                height: 100px
                object-fit: cover
                object-position: center
                margin-left: 115px
                border-radius: 10px
            button
                height: 44px

@media (max-width: 1250px)
    .avatar-cover
        margin-top: 60px
        padding: 0px 25px
        margin-bottom: 115px
        &_change-btn-cover
            margin-right: 45px
            padding-top: 80px
        &_cover
            width: 100%
        &_change-btn-avatar
            &_avatar
                margin-left: 40px

@media (max-width: 530px)
    .avatar-cover
        margin-top: 25px
        border-radius: 7px
        padding: 0px
        height: 238px
        &_change-btn-cover
            margin-right: 10px
            padding-top: 60px
        &_cover
            margin-top: -44px
            border-radius: 7px
            height: 112px
        &_change-btn-avatar
            gap: 28px
            margin-top: -24px
            &_avatar
                width: 70px
                height: 70px
                margin-left: 15px
            button
                height: auto

</style>