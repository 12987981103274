<template>
    <section>
        <div class="Register">
            <div class="Register_background">

            </div>
            <div class="Register_form">
                <form class="Register_form_form-block" @submit.prevent="onRegister">
                    <p>Регистрация</p>
                    <input type="text" v-model="register.name" placeholder="Имя пользователя">
                    <input type="text" v-model="register.email" placeholder="Ваш E-mail">
                    <input type="password" v-model="register.password" placeholder="Введите пароль">
                    <input type="password" v-model="register.password_confirmation" placeholder="Повторите пароль">
                    <button type="submit">Отправить</button>
                    <div class="Register_form_form-block_re-password-and-register">
                        <span>Имеется аккаунт? Тогда жмите <router-link to="/login"> Войти</router-link></span>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import axios from 'axios';

export default {
    name: 'Register',
    components: {
    },
    data() {
        return {
            register: {
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
            },
        }
    },
    methods: {
        onRegister() {
            axios.post(this.$store.getters.BACKENDURL + '/api/register', this.register).then((response) => {
                toast("Вы успешно зарегистрировались! Теперь авторизуйтесь!", {
                "theme": "colored",
                "type": "success",
                "dangerouslyHTMLString": true
                })
                setTimeout(() => {
                    this.$router.push('/login');
                }, 800)
            })
            .catch((error) => {
                let errorparse = JSON.parse(error.request.response);
                let message = errorparse.message.replace(/\([^)]*\)/g, '');
                toast("Ошибка! " + message, {
                "theme": "colored",
                "type": "error",
                "dangerouslyHTMLString": true
                })
            });
        },
    }
}
</script>

<style lang="sass" scoped>
    section
        height: calc( 100vh )
        display: flex
        align-items: center
        justify-content: center
    .Register
        display: flex
        align-items: center
        &_background
            background-image: url(../../assets/images/RegisterView/RegisterView.png)
            height: 599px
            width: 386px
            background-size: cover
            pointer-events: none
        &_form
            border-radius: 0px 10px 10px 0px
            height: 599px
            padding: 0px 80px
            background: #1F1E23
            display: flex
            align-items: center
            &_form-block
                display: flex
                flex-direction: column
                gap: 25px
                p
                    color: #FFF
                    font-family: Montserrat
                    font-size: 23px
                    font-style: normal
                    font-weight: 600
                    line-height: normal
                    letter-spacing: 0.46px
                    text-align: center
                    margin-bottom: 10px
                input
                    border: none
                    outline: none
                    border-radius: 5px
                    background: #2D2D2D
                    color: white
                    font-family: Montserrat
                    font-size: 14px
                    font-style: normal
                    font-weight: 500
                    line-height: normal
                    letter-spacing: 0.28px
                    padding: 19px 25px 18px 25px
                    width: 430px
                input::placeholder
                    color: #828282
                button
                    border: none
                    outline: none
                    cursor: pointer
                    border-radius: 5px
                    background: #8C53FD
                    color: #FFF
                    font-family: Montserrat
                    font-size: 16px
                    font-style: normal
                    font-weight: 500
                    line-height: 135%
                    letter-spacing: 0.32px
                    padding: 14px 0px
                    transition: 0.3s
                    margin-top: 10px
                button:active,
                button:hover
                    background: #9370d8
                &_re-password-and-register
                    display: flex
                    span
                        color: #767576
                        font-family: Montserrat
                        font-size: 16px
                        font-style: normal
                        font-weight: 500
                        line-height: 135%
                        letter-spacing: 0.32px
                    a
                        text-decoration: none
                        color: #FFF
                        font-family: Montserrat
                        font-size: 16px
                        font-style: normal
                        font-weight: 500
                        line-height: 135%
                        letter-spacing: 0.32px
                        transition: 0.2s
                    a:active,
                    a:hover
                        opacity: 0.6

@media (max-width: 1025px)
    .Register
        &_background
            display: none
        &_form
            border-radius: 10px

@media (max-width: 640px)
    .Register
        &_form
            border-radius: 5px
            height: 444px
            padding: 0px 18.5px
            &_form-block
                gap: 15px
                p
                    font-size: 18px
                    letter-spacing: 0.36px
                    margin-bottom: 10px
                input
                    width: 270px
                    border-radius: 5px
                    padding: 15px 19px 14px 19px
                    font-size: 12px
                    letter-spacing: 0.24px
                button
                    margin-top: 5px
                    padding: 12px 0px
                    font-size: 12px
                    letter-spacing: 0.24px
                &_re-password-and-register
                    margin-top: 10px
                    span
                        font-size: 12px
                        letter-spacing: 0.24px
                    a
                        font-size: 12px
                        letter-spacing: 0.24px

</style>