<template>
    <div class="view-anime">
        <div class="view-anime_header">
            <p class="view-anime_header_title">
                Смотреть аниме
                <font-awesome-icon icon="fa-solid fa-chevron-right" size="xs" style="color: #fff;" />
            </p>
            <div class="view-anime_header_buttons">
                <p id="btn_popular" @click="showPopular(this.popular)" class="active-button">Популярное</p>
                <p id="btn_new" @click="showNew(this.new)">Новинки</p>
            </div>
        </div>
        <div class="view-anime_main">
            <div  v-for="anime in toAnime" :key="anime.id" class="view-anime_main_item">
                <router-link :to="'/view/anime-' + anime.id">
                    <img v-if="anime.poster_url" :src="anime.poster_url" alt="Постер аниме" draggable="false">
                    <img v-else src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
                </router-link>
                <p v-if="anime.shikimori_rating >= 7" class="view-anime_main_item_rating green-rating">
                    <font-awesome-icon icon="fa-solid fa-star" /> {{ anime.shikimori_rating }}
                </p>
                <p v-else-if="anime.shikimori_rating >= 4.5" class="view-anime_main_item_rating orange-rating">
                    <font-awesome-icon icon="fa-solid fa-star" /> {{ anime.shikimori_rating }}
                </p>
                <p v-else-if="anime.shikimori_rating >= 0" class="view-anime_main_item_rating red-rating">
                    <font-awesome-icon icon="fa-solid fa-star" /> {{ anime.shikimori_rating }}
                </p>
                <p class="view-anime_main_item_title">{{ anime.title }}</p>
                <div class="view-anime_main_item_description">
                    <p class="view-anime_main_item_description_year">{{ anime.year }}</p>
                    <p v-if="anime.anime_genres" class="view-anime_main_item_description_genres">{{ anime.anime_genres[0] }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ViewAnime',
    components: {

    },
    data() {
        return {
            toAnime: [],
            popular: true,
            new: false,
        }
    },
    created() {
        axios.get(this.$store.getters.BACKENDURL + '/api/main-popular/').then((response) => {
            this.toAnime = response.data;
        }).catch((errors) => {
            console.log(errors);
        });
    },
    methods: {
        showNew(newAnime)
        {
            if(newAnime === false)
            {
                axios.get(this.$store.getters.BACKENDURL + '/api/main-new/').then((response) => {
                    this.toAnime = response.data;
                }).catch((errors) => {
                    console.log(errors);
                });
                this.new = true;
                this.popular = false;
                document.getElementById('btn_new').classList.add('active-button');
                document.getElementById('btn_popular').classList.remove('active-button');
            }
        },
        showPopular(popular)
        {
            if(this.popular === false)
            {
                axios.get(this.$store.getters.BACKENDURL + '/api/main-popular/').then((response) => {
                    this.toAnime = response.data;
                }).catch((errors) => {
                    console.log(errors);
                });
                this.new = false;
                this.popular = true;
                document.getElementById('btn_new').classList.remove('active-button');
                document.getElementById('btn_popular').classList.add('active-button');
            }
        }
    }
}
</script>

<style lang="sass" scoped>
    .active-button
        color: rgb(110, 110, 110) !important
    .view-anime
        margin-top: 100px
        margin-bottom: 114px
        &_header
            display: flex
            justify-content: space-between
            align-items: center
            &_title
                color: rgb(245, 245, 245)
                font-family: Montserrat
                font-size: 23px
                font-weight: 700
                line-height: 28px
                letter-spacing: 4%
                text-align: left
            &_buttons
                color: rgb(180, 180, 180)
                font-family: Montserrat
                font-size: 16px
                font-weight: 500
                line-height: 20px
                letter-spacing: 0%
                text-align: center
                display: flex
                gap: 40px
                p
                    cursor: pointer
                    padding: 16.5px 25px
                    border-radius: 5px
                    background: rgb(45, 45, 45)
                    transition: 0.3s
                p:active,
                p:hover
                    background: #4D4D4D
        &_main
            margin-top: 35px
            display: flex
            gap: 55px
            flex-wrap: wrap
            justify-content: center
            &_item
                width: 196px
                a
                    img
                        width: 196px
                        height: 285px
                        border-radius: 20px
                &_rating
                    color: rgb(255, 255, 255)
                    font-family: Montserrat
                    font-size: 14px
                    font-weight: 600
                    line-height: 17px
                    letter-spacing: 2%
                    border-radius: 0px 20px 0px 20px
                    width: 100px
                    height: 32px
                    display: flex
                    justify-content: center
                    align-items: center
                    gap: 5px
                    margin-top: -36px
                    position: relative
                &_title
                    margin-top: 15px
                    color: rgb(245, 245, 245)
                    font-family: Montserrat
                    font-size: 15px
                    font-weight: 600
                    line-height: 18px
                    letter-spacing: 2%
                    text-align: left
                    -webkit-line-clamp: 2
                    overflow: hidden
                    text-overflow: ellipsis
                    display: -webkit-box
                    -webkit-box-orient: vertical
                &_description
                    margin-top: 10px
                    display: flex
                    flex-direction: row
                    color: rgb(155, 155, 157)
                    font-family: Montserrat
                    font-size: 14px
                    font-weight: 500
                    line-height: 17px
                    letter-spacing: 2%
                    justify-content: space-between
                    align-items: center
                    &_genres
                        display: flex
                        flex-wrap: wrap
                        max-width: 11.2ch
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis

@media (max-width: 1200px)
    .view-anime
        padding: 0px 25px

@media (max-width: 600px)
    .view-anime
        margin-top: 65px
        padding: 0px 10px
        margin-bottom: 100px
        &_header
            flex-wrap: wrap
            gap: 15px
            &_title
                font-size: 18px
                line-height: 22px
            &_buttons
                gap: 20px
                font-size: 14px
                line-height: 17px
                p
                    padding: 8px 20px
        &_main
            gap: 12px
            &_item
                width: 110px
                a
                    img
                        width: 110px
                        height: 160px
                &_rating
                    font-size: 8px
                    line-height: 10px
                    border-radius: 0px 10px 0px 10px
                    width: 52px
                    height: 18px
                    margin-top: -22px
                &_title
                    margin-top: 10px
                    font-size: 12.5px
                    line-height: 15px
                &_description
                    margin-top: 5px
                    font-size: 10.5px
                    line-height: 11px
                    p
                        display: flex
                        flex-wrap: wrap
</style>