<template>
    <div class="comments" v-if="comments != {}">
        <h1>Комментарии <span>({{ countComments }})</span></h1>
        <form @submit.prevent="sendComment">
            <textarea v-model="sendCom.text" placeholder="Написать комментарий..."></textarea>
            <div>
               <button type="sumbit">Отправить</button>
            </div>
        </form>
        <div class="comments_comment" v-for="comment in comments" :key="comment.id" :id="`comment_${comment.user.id}`">
            <div v-if="comment.user" class="comments_comment_img-block">
                <picture class="user__avatar">
                    <img v-if="comment.user.avatar !== null" :src="`${this.$store.getters.BACKENDURL}/storage/${comment.user.avatar}`" alt="Аватар пользователя" draggable="false">
                    <img v-else src="../../assets/images/AccountView/AvatarExample.png" alt="Аватар пользователя" draggable="false">
                </picture>
                <div></div>
            </div>
            <div class="comments_comment_content">
                <div class="comments_comment_content_title">
                    <p class="comments_comment_content_title_rank">{{ comment.user.rank }}</p>
                    <p class="comments_comment_content_title_nickname">{{ comment.user.name }}</p>
                    <p class="comments_comment_content_title_datatime">{{ comment.created_at_ago }}</p>
                </div>
                <p class="comments_comment_content_text">{{ comment.comment }}</p>
                <button class="comments_comment_content_answer" @click="showSendBlock(comment.id)">Ответить</button>
                <div :id="'sendblock' + comment.id" class="comments_comment_content_sendblock">
                    <textarea :id="'maintextArea-' + comment.id" placeholder="Написать комментарий..." :value="`${comment.user.name}, `" ></textarea>
                    <div>
                        <button @click="sendMainAnswer(comment.id)">Отправить</button>
                    </div>
                </div>
                <button v-if="comment.answers.length > 0" @click="showAnswers(comment.id)" :id="'btn-answers-' + comment.id" class="comments_comment_content_open-answer">
                    <font-awesome-icon icon="fa-solid fa-reply" />
                    Показать {{ comment.answers.length }} ответ(а)
                </button>
                <div class="answers" :id="'answers-' + comment.id">
                    <div v-for="answer in comment.answers" :key="answer.id" class="answer-block">
                        <img v-if="answer.user.avatar" :src="`${this.$store.getters.BACKENDURL}/storage/${answer.user.avatar}`" alt="Аватар пользователя">
                        <img v-else src="../../assets/images/AccountView/AvatarExample.png" alt="Аватар пользователя">
                        <div class="answer-block_content">
                            <div class="answer-block_content_header">
                                <p class="comments_comment_content_title_rank">{{ answer.user.rank }}</p>
                                <p class="comments_comment_content_title_nickname">{{ answer.user.name }}</p>
                                <p class="comments_comment_content_title_datatime">{{ answer.created_at_ago }}</p>
                            </div>
                            <p class="comments_comment_content_text">{{ answer.answer }}</p>
                            <button :id="'btn-answer-' + answer.id" class="answer-block_content_btn-answer" @click="openFormAnswer(answer.id)">Ответить</button>
                            <textarea :id="'textarea-' + answer.id" placeholder="Написать комментарий..." :value="`${answer.user.name}, `" ></textarea>
                            <div :id="'button-' + answer.id" class="answer-block_content_answer">
                                <button @click="sendSmallAnswer(comment.id, answer.id)">Отправить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="comments.length > 4 && comments.length <= countComments" class="next-btn">
            <button @click="addComments()">
                Показать еще <font-awesome-icon icon="fa-solid fa-chevron-down" size="xs" />
            </button>
        </div>
    </div>
</template>

<script>
import { getCookie } from "@/custom/getCookie";
import axios from 'axios'
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: 'AnimeComments',
    components: {

    },
    data() {
        return {
            comments: {},
            sendCom: {
                text: '',
            },
            countComments: 0,
        }
    },
    created() {
        this.downloadComments();
    },
    methods: {
        downloadComments()
        {
            axios.get(`${this.$store.getters.BACKENDURL}/api/anime/show-comments/${this.$route.params.id}`, {
                offset: this.comments.length
            }).then(response => (
                this.comments = response.data.comments,
                this.countComments = response.data.countComments
            )).catch((errors) => {
                console.log(errors)
            });
        },
        openFormAnswer(answer)
        {
            document.getElementById('textarea-' + answer).style.display = 'block'
            document.getElementById('button-' + answer).style.display = 'flex'
        },
        showAnswers(comment)
        {
            document.getElementById('answers-' + comment).style.display = 'block'
            document.getElementById('btn-answers-' + comment).style.display = 'none'
        },
        showSendBlock(comment)
        {
            document.getElementById('sendblock' + comment).style.display = 'block'
        },
        sendComment()
        {
            if(this.$store.getters.ACCOUNT != '') {
                axios.defaults.headers.common['Authorization'] = `Bearer ${getCookie('access_token')}`
                axios.post(this.$store.getters.BACKENDURL + '/api/anime/create-comment', {
                    anime_id: this.$route.params.id,
                    comment: this.sendCom.text
                }).then((response) => {
                    toast("Сообщение успешно отправлено!", {
                    "theme": "colored",
                    "type": "success",
                    "dangerouslyHTMLString": true
                    }),
                    this.sendCom.text = '',
                    this.downloadComments()
                }).catch((errors) => {
                    console.log(errors)
                });
            } else {
                this.$router.push('/login')
            }
        },
        sendMainAnswer(commentId)
        {
            if(this.$store.getters.ACCOUNT != '') {
                axios.defaults.headers.common['Authorization'] = `Bearer ${getCookie('access_token')}`
                axios.post(this.$store.getters.BACKENDURL + '/api/anime/create-answer', {
                    comment_id: commentId,
                    answer: document.getElementById('maintextArea-' + commentId).value
                }).then((response) => {
                    toast("Сообщение успешно отправлено!", {
                    "theme": "colored",
                    "type": "success",
                    "dangerouslyHTMLString": true
                    }),
                    document.getElementById('maintextArea-' + commentId).value = '',
                    document.getElementById('sendblock' + commentId).style.display = 'none'
                    this.downloadComments()
                }).catch((errors) => {
                    console.log(errors)
                });
            } else {
                this.$router.push('/login')
            }
        },
        sendSmallAnswer(commentId, answerId)
        {
            if(this.$store.getters.ACCOUNT != '') {
                axios.defaults.headers.common['Authorization'] = `Bearer ${getCookie('access_token')}`
                axios.post(this.$store.getters.BACKENDURL + '/api/anime/create-answer', {
                    comment_id: commentId,
                    answer: document.getElementById('textarea-' + answerId).value
                }).then((response) => {
                    toast("Сообщение успешно отправлено!", {
                    "theme": "colored",
                    "type": "success",
                    "dangerouslyHTMLString": true
                    }),
                    document.getElementById('textarea-' + answerId).style.display = 'none'
                    document.getElementById('button-' + answerId).style.display = 'none'
                    this.downloadComments()
                }).catch((errors) => {
                    console.log(errors)
                });
            } else {
                this.$router.push('/login')
            }
        },
        addComments()
        {
            axios.get(`${this.$store.getters.BACKENDURL}/api/anime/show-comments/${this.$route.params.id}`, {
                offset: this.comments.length
            }).then(response => (
                this.comments = this.comments.concat(response.data.comments),
                this.countComments = response.data.countComments
            )).catch((errors) => {
                console.log(errors)
            });
        }
    }
}
</script>

<style lang="sass" scoped>

    .user__avatar
        box-sizing: border-box
        width: 56px
        height: 56px
        border-radius: 5px
        overflow: hidden

    .next-btn
        display: flex
        justify-content: center
        button
            border: 1px solid rgb(80, 80, 80)
            border-radius: 5px
            background: rgb(51, 50, 56)
            color: rgb(160, 160, 160)
            font-family: Montserrat
            font-size: 14px
            font-weight: 500
            line-height: 17px
            letter-spacing: 6%
            padding: 0px 22px
            height: 44px
            transition: 0.2s
        button:active,
        button:hover
            background: #4D4D4D

    .answers
        display: none

    .answer-block
        display: flex
        flex-direction: row
        gap: 20px
        margin-top: 24px
        img
            width: 56px
            height: 56px
            border-radius: 5px
        &_content
            display: flex
            flex-direction: column
            width: 100vh
            margin-top: 7px
            &_header
                display: flex
                flex-direction: row
                gap: 10px
                align-items: center
                margin-bottom: 15px
            &_btn-answer
                color: rgb(130, 130, 130)
                font-family: Montserrat
                font-size: 14px
                font-weight: 500
                line-height: 17px
                letter-spacing: 0%
                outline: none
                background: none
                width: fit-content
                transition: 0.2s
            &_answer
                display: flex
                justify-content: flex-end
                display: none
                button
                    outline: none
                    border: 1px solid rgb(155, 155, 157)
                    border-radius: 5px
                    background: none
                    padding: 0px 22px
                    color: rgb(155, 155, 157)
                    font-family: Montserrat
                    font-size: 14px
                    font-weight: 500
                    line-height: 17px
                    letter-spacing: 6%
                    width: 130px
                    height: 44px
                    transition: 0.2s
                button:active,
                button:hover
                    background: rgb(66, 65, 68)
            textarea
                display: none
                resize: none
                width: calc( 100% - 60px )
                border: 1.5px solid rgb(66, 65, 68)
                background: none
                outline: none
                border: 1.13px solid rgb(66, 65, 68)
                border-radius: 5px
                color: rgb(255, 255, 255)
                font-family: Montserrat
                font-size: 14px
                font-weight: 500
                line-height: 17px
                letter-spacing: 0%
                padding: 18px 30px
                margin-bottom: 12px
                margin-top: 20px

    .comments
        margin-bottom: 135px
        h1
            font-family: Montserrat
            font-size: 23px
            font-weight: 600
            line-height: 28px
            letter-spacing: 0%
            color: white
            margin-bottom: 35px
            span
                color: #A0A0A0
        form
            margin-bottom: 30px
            textarea
                border: 1.5px solid rgb(66, 65, 68)
                max-width: 1200px
                border-radius: 5px
                width: calc( 100% - 60px )
                background: none
                outline: none
                color: white
                font-family: Montserrat
                font-size: 14px
                font-weight: 500
                line-height: 17px
                letter-spacing: 0%
                padding: 27px 30px
                resize: none
                height: 64px
                display: flex
                justify-content: center
                margin-bottom: 12px
            textarea:placeholder
                color: rgb(130, 130, 130)
            div
                display: flex
                justify-content: flex-end
                button
                    outline: none
                    border: 1px solid rgb(155, 155, 157)
                    border-radius: 5px
                    background: none
                    padding: 0px 22px
                    color: rgb(155, 155, 157)
                    font-family: Montserrat
                    font-size: 14px
                    font-weight: 500
                    line-height: 17px
                    letter-spacing: 6%
                    width: 130px
                    height: 44px
                    transition: 0.2s
                button:active,
                button:hover
                    background: rgb(66, 65, 68)
        &_comment
            display: flex
            flex-direction: row
            gap: 20px
            margin-bottom: 15px
            &_img-block
                display: flex
                flex-direction: column
                align-items: center
                gap: 15px
                position: relative
                img
                    width: 100%
                    height: 100%
                    box-sizing: border-box
                    object-fit: cover
                    object-position: center
                    position: relative
                    z-index: 999
                div
                    margin-top: 72px
                    position: absolute
                    border: 1.5px solid rgb(66, 65, 68)
                    width: 0px
                    height: calc(100% - 76px)
            &_content
                display: flex
                flex-direction: column
                margin-bottom: 44px
                width: 100vh
                margin-top: 7px
                &_sendblock
                    display: none
                    textarea
                        resize: none
                        width: calc( 100% - 60px )
                        border: 1.5px solid rgb(66, 65, 68)
                        background: none
                        outline: none
                        border: 1.13px solid rgb(66, 65, 68)
                        border-radius: 5px
                        color: rgb(255, 255, 255)
                        font-family: Montserrat
                        font-size: 14px
                        font-weight: 500
                        line-height: 17px
                        letter-spacing: 0%
                        padding: 18px 30px
                        margin-bottom: 12px
                        margin-top: 20px
                    div
                        display: flex
                        justify-content: flex-end
                        button
                            outline: none
                            border: 1px solid rgb(155, 155, 157)
                            border-radius: 5px
                            background: none
                            padding: 0px 22px
                            color: rgb(155, 155, 157)
                            font-family: Montserrat
                            font-size: 14px
                            font-weight: 500
                            line-height: 17px
                            letter-spacing: 6%
                            width: 130px
                            height: 44px
                            transition: 0.2s
                        button:active,
                        button:hover
                            background: rgb(66, 65, 68)
                &_title
                    display: flex
                    flex-direction: row
                    align-items: center
                    gap: 10px
                    margin-bottom: 15px
                    &_rank
                        color: rgb(255, 255, 255)
                        font-family: Montserrat
                        font-size: 14px
                        font-weight: 500
                        line-height: 17px
                        letter-spacing: 0%
                        border-radius: 5px
                        background: rgb(140, 83, 253)
                        width: fit-content
                        padding: 5.5px 16px
                    &_nickname
                        color: rgb(255, 255, 255)
                        font-family: Montserrat
                        font-size: 18px
                        font-weight: 600
                        line-height: 22px
                        letter-spacing: 4%
                    &_datatime
                        color: rgb(130, 130, 130)
                        font-family: Montserrat
                        font-size: 14px
                        font-weight: 500
                        line-height: 17px
                        letter-spacing: 0%
                        margin-left: 10px
                &_text
                    color: rgb(255, 255, 255)
                    font-family: Montserrat
                    font-size: 16px
                    font-weight: 500
                    line-height: 20px
                    letter-spacing: 0%
                    margin-bottom: 10px
                &_answer
                    color: rgb(130, 130, 130)
                    font-family: Montserrat
                    font-size: 14px
                    font-weight: 500
                    line-height: 17px
                    letter-spacing: 0%
                    outline: none
                    background: none
                    width: fit-content
                    transition: 0.2s
                &_answer:active,
                &_answer:hover
                    opacity: 0.7
                &_open-answer
                    width: fit-content
                    outline: none
                    background: none
                    color: rgb(200, 164, 255)
                    font-family: Montserrat
                    font-size: 14px
                    font-weight: 500
                    line-height: 18px
                    letter-spacing: 0px
                    margin-top: 20px
                    transition: 0.2s
                &_open-answer:active,
                &_open-answer:hover
                    opacity: 0.7

@media (max-width: 1380px)
    .comments
        &_comment
            &_content
                width: 610px

@media (max-width: 1200px)
    .comments
        padding: 0px 25px

@media (max-width: 720px)
    .answer-block
        gap: 15px
        img
            width: 46px
            height: 46px
        &_content
            textarea
                font-size: 12px
                line-height: 15px
                padding: 18px 12px
                width: calc(100% - 24px)
            &_btn-answer
                font-size: 12px
                line-height: 15px
            &_answer
                button
                    font-size: 12px
                    line-height: 15px
                    padding: 0px 15px
                    height: 34px
                    width: 104px
    .comments
        padding: 0px 10px
        h1
            font-size: 18px
            line-height: 22px
            margin-bottom: 20px
        form
            textarea
                font-size: 12px
                line-height: 15px
                padding: 18px 12px
                width: calc(100% - 24px)
            div
                button
                    font-size: 12px
                    line-height: 15px
                    padding: 0px 15px
                    height: 34px
                    width: 104px
        &_comment
            gap: 15px
            &_img-block
                img
                    width: 46px
                    height: 46px
            &_content
                width: 295px
                &_sendblock
                    textarea
                        font-size: 12px
                        line-height: 15px
                        padding: 18px 12px
                        width: calc(100% - 24px)
                    div
                        button
                            font-size: 12px
                            line-height: 15px
                            padding: 0px 15px
                            height: 34px
                            width: 104px
                &_text
                    font-size: 14px
                    line-height: 20px
                    margin-bottom: 8px
                &_answer
                    font-size: 12px
                    line-height: 15px
                &_open-answer
                    font-size: 12px
                    line-height: 14px
                &_title
                    margin-bottom: 12px
                    &_rank
                        font-size: 12px
                        line-height: 15px
                        padding: 4px 12px
                    &_nickname
                        font-size: 14px
                        line-height: 17px
                    &_datatime
                        font-size: 12px
                        line-height: 15px
    .next-btn
        button
            font-size: 12px
            line-height: 15px
            padding: 0px 14px
            height: 34px
</style>