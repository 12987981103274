<template>
  <div class="favourites">
    <router-link v-for="favorite in favorites" :key="favorite.id" :to="'/view/anime-' + favorite.anime.id"
                 class="anime-block">
      <img v-if="favorite.anime.poster_url" :src="favorite.anime.poster_url" alt="Постер аниме" draggable="false">
      <img v-else src="../../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
      <div class="anime-block_block-raiting">
        <img src="../../../assets/images/MainView/RandomAnime/rating_icon.svg" alt="Рейтинг">
        <p>{{ favorite.anime.enshi_rating }}</p>
      </div>
      <p class="anime-block_title">{{ favorite.anime.title }}</p>
      <div class="anime-block_desc">
        <p>{{ favorite.anime.year }}</p>
        <p class="anime-block_desc_genres" v-if="favorite.anime.anime_genres">{{
            favorite.anime.anime_genres[0]
          }}/<span>{{ favorite.anime.anime_genres[1] }}</span></p>
      </div>
    </router-link>
  </div>
  <div class="favourites-paginate">
    <div v-if="pagination.prev_page_url" @click="downloadFavorites(pagination.prev_page_url)">
      <font-awesome-icon icon="fa-solid fa-chevron-left"/>
    </div>
    <div v-if="pagination.next_page_url" @click="downloadFavorites(pagination.next_page_url)">
      <font-awesome-icon icon="fa-solid fa-chevron-right"/>
    </div>
  </div>
</template>

<script>
import {getCookie} from '@/custom/getCookie';
import axios from 'axios'

export default {
  name: 'Favourites',
  components: {},
  data() {
    return {
      favorites: {},
      pagination: {},
    }
  },
  mounted() {
    this.downloadFavorites(this.$store.getters.BACKENDURL + '/api/user/show-favorite');
  },
  methods: {
    downloadFavorites(page) {
      let token = getCookie('access_token');

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      axios.post(`${page}`).then(response => (
          this.favorites = response.data.data,
              this.pagination = response.data.pagination
      )).catch((errors) => {
        console.log(errors)
      });
    }
  },
}
</script>

<style lang="sass" scoped>
.favourites-paginate
  display: flex
  flex-direction: row
  justify-content: flex-end
  margin-top: 35px
  gap: 40px

  div
    cursor: pointer
    border-radius: 10px
    background: rgb(45, 45, 45)
    width: 50px
    height: 50px
    color: #6E6E6E
    display: flex
    justify-content: center
    align-items: center
    transition: 0.3s

    svg
      width: 20px
      height: 20px

  div:active,
  div:hover
    background: #4D4D4D

.favourites
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(172px, 1fr))
  gap: 35px

@media (max-width: 580px)
  .favourites-paginate
    gap: 20px
    justify-content: center

    div
      width: 40px
      height: 40px

      svg
        width: 15px
        height: 15px
  .favourites
    gap: 12px
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr))
</style>