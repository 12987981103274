<template>
    <div class="results">
        <router-link to="#" class="banime">
            <img src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
            <div class="banime_raiting-block">
                <font-awesome-icon icon="fa-solid fa-star" />
                <p>8.9</p>
            </div>
            <p class="banime_title">О моём перерождении в слизь: Алые узы</p>
            <div class="banime_desc">
                <p>2023</p>
                <p>
                    Комедия/
                    <span>Экшен</span>
                </p>
            </div>
        </router-link>

        <router-link to="#" class="banime">
            <img src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
            <div class="banime_raiting-block">
                <font-awesome-icon icon="fa-solid fa-star" />
                <p>8.9</p>
            </div>
            <p class="banime_title">О моём перерождении в слизь: Алые узы</p>
            <div class="banime_desc">
                <p>2023</p>
                <p>
                    Комедия/
                    <span>Экшен</span>
                </p>
            </div>
        </router-link>
        <router-link to="#" class="banime">
            <img src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
            <div class="banime_raiting-block">
                <font-awesome-icon icon="fa-solid fa-star" />
                <p>8.9</p>
            </div>
            <p class="banime_title">О моём перерождении в слизь: Алые узы</p>
            <div class="banime_desc">
                <p>2023</p>
                <p>
                    Комедия/
                    <span>Экшен</span>
                </p>
            </div>
        </router-link>
        <router-link to="#" class="banime">
            <img src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
            <div class="banime_raiting-block">
                <font-awesome-icon icon="fa-solid fa-star" />
                <p>8.9</p>
            </div>
            <p class="banime_title">О моём перерождении в слизь: Алые узы</p>
            <div class="banime_desc">
                <p>2023</p>
                <p>
                    Комедия/
                    <span>Экшен</span>
                </p>
            </div>
        </router-link>
        <router-link to="#" class="banime">
            <img src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
            <div class="banime_raiting-block">
                <font-awesome-icon icon="fa-solid fa-star" />
                <p>8.9</p>
            </div>
            <p class="banime_title">О моём перерождении в слизь: Алые узы</p>
            <div class="banime_desc">
                <p>2023</p>
                <p>
                    Комедия/
                    <span>Экшен</span>
                </p>
            </div>
        </router-link>
        <router-link to="#" class="banime">
            <img src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
            <div class="banime_raiting-block">
                <font-awesome-icon icon="fa-solid fa-star" />
                <p>8.9</p>
            </div>
            <p class="banime_title">О моём перерождении в слизь: Алые узы</p>
            <div class="banime_desc">
                <p>2023</p>
                <p>
                    Комедия/
                    <span>Экшен</span>
                </p>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'Results',
    components: {

    }
}
</script>

<style lang="sass" scoped>
    .results
        display: flex
        flex-wrap: wrap
        gap: 55px
        margin-bottom: 123px
        justify-content: center
        height: 100%

@media (max-width: 530px)
    .results
        gap: 12px
</style>