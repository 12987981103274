<script setup>
import {onMounted, onUnmounted, ref} from "vue";

class Particle {
  constructor({x, y, radius, opacity}) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.opacity = opacity;
  }

  draw(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
    ctx.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
    ctx.fill();
  }
}

class ParticleGenerator {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.particles = [];
    this.animationFrameId = null;
    this.resize(canvas)()
  }

  resetParticles() {
    this.particles = [];
  }

  resize(canvas) {
    return () => {
      const {width, height} = canvas.parentElement?.getBoundingClientRect()
      this.canvas.width = width;
      this.canvas.height = height;
    }
  }

  resetAnimation() {
    cancelAnimationFrame(this.animationFrameId);
  }

  generateRandomParticle() {
    const x = Math.random() * this.canvas.width;
    const radius = Math.random() * 1.2;
    const opacity = Math.random() / 1.2;
    return new Particle({x, y: this.canvas.height, radius, opacity});
  }

  animate() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.particles.forEach((particle, index) => {
      particle.y -= Math.random() * 2 + 2;
      particle.draw(this.ctx);

      if (particle.y < 0) {
        this.particles.splice(index, 1);
      }
    });

    const gradient = this.ctx.createLinearGradient(0, 0, 0, this.canvas.height);
    gradient.addColorStop(1, 'rgba(200, 143, 255, 1)');
    gradient.addColorStop(0.8, 'rgba(200, 143, 255, 0.4)');
    gradient.addColorStop(0.6, 'rgba(200, 143, 255, 0.2)');
    gradient.addColorStop(0.4, 'rgba(200, 143, 255, 0)');

    this.ctx.fillStyle = gradient;
    this.ctx.filter = 'blur(50px)';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.filter = 'none';

    this.animationFrameId = requestAnimationFrame(() => this.animate());
  }

  start() {
    this.generateParticles();
    this.animate();
  }

  generateParticles() {
    setInterval(() => {
      const particle = this.generateRandomParticle();
      this.particles.push(particle);
    }, 200);
  }
}

const canvas_ref = ref()
const particleGenerator = ref()

onMounted(() => {
  particleGenerator.value = new ParticleGenerator(canvas_ref.value)
  particleGenerator.value.start()
  window.addEventListener('resize', particleGenerator.value.resize(canvas_ref.value))
})

onUnmounted(() => {
  particleGenerator.value.resetParticles()
  particleGenerator.value.resetAnimation()
  window.removeEventListener('resize', particleGenerator.value.resize(canvas_ref.value))
})
</script>

<template>
  <canvas ref="canvas_ref" class="ReleaseTodayPosterBg"/>
</template>

<style lang="sass">
.ReleaseTodayPosterBg

  pointer-events: none
  display: block
</style>