<template>
  <Header />
  <main style="flex-grow: 1">
      <router-view/>
  </main>
  <Footer />
</template>

<script>
import Header from '@/components/App/Header.vue'
import Footer from '@/components/App/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
    }
  },
  mounted() {
    this.$store.dispatch('getToken');
  }
}
</script>

<style lang="sass">
  @import url(assets/css/main.css)
  @import url(assets/css/anime.css)
  @import url(assets/player/player.css)
</style>