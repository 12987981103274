<template>
    <div v-show="openEditForm" class="edit-anime">
        <div class="edit-anime_image-block">
            <img v-if="EditAnime.poster_url" :src="EditAnime.poster_url" alt="Постер аниме">
            <img v-else src="../../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме">
            <label for="poster">
                <font-awesome-icon icon="fa-regular fa-images" />
            </label>
            <input type="file" @change="onFileChange" id="poster" name="poster" accept="image/png, image/jpeg" />
        </div>
        <div class="edit-anime_content">
            <input v-model="formAnime.title" class="edit-anime_content_title" type="text" name="title" placeholder="Название аниме">
            <input v-model="formAnime.player_link" class="edit-anime_content_title" type="text" name="kodik" placeholder="Ссылка на видео">
            <div class="edit-anime_content_param">
                <input type="text" name="year" :value="EditAnime.year" placeholder="Год">
                <input type="text" name="episodes_total" :value="EditAnime.episodes_total" placeholder="Кол-во серий">
                <input type="text" name="genres" placeholder="Жанры">
            </div>
            <textarea v-model="formAnime.description" name="description" id="" cols="30" rows="10" placeholder="Описание..."></textarea>
            <div class="edit-anime_content_buttons">
                <button class="edit-anime_content_buttons_cancel" @click="cancelEdit()">Отменить</button>
                <button class="edit-anime_content_buttons_save" @click="saveAnime()">Сохранить</button>
            </div>
        </div>
    </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { getCookie } from "@/custom/getCookie";
import axios from 'axios'

export default {
    name: 'EditAnime',
    components: {

    },
    props: [
        'openEditForm',
        'EditAnime'
    ],
    watch: {
        EditAnime(newVal) {
            this.formAnime.anime_id = newVal.id;
            this.formAnime.title = newVal.title;
            this.formAnime.player_link = newVal.player_link;
            this.formAnime.description = newVal.description;
        }
    },
    data() {
        return {
            formAnime: {
                anime_id: this.EditAnime.id,
                title: this.EditAnime.title,
                player_link: this.EditAnime.player_link,
                description: this.EditAnime.description,
                poster: null,
            }
        }
    },
    methods: {
        cancelEdit() {
            this.$emit('closeEdit', false);
        },
        onFileChange(e) {
            this.formAnime.poster = e.target.files[0];
        },
        saveAnime() {

            const formData = new FormData();
            formData.append('poster', this.formAnime.poster);
            formData.append('anime_id', this.formAnime.anime_id);
            formData.append('title', this.formAnime.title);
            formData.append('player_link', this.formAnime.player_link);
            formData.append('description', this.formAnime.description);

            console.log(formData.append('poster', this.formAnime.poster))
            let token = getCookie('access_token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            axios.post(this.$store.getters.BACKENDURL + '/api/admin/anime/save-edit', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                toast("Данные успешно обновлены!", {
                        "theme": "colored",
                        "type": "success",
                        "dangerouslyHTMLString": true
                    })
            }).catch((errors) => {
                console.log(errors);
            });
        }
    }
}
</script>

<style lang="sass">
    .edit-anime
        margin: -40px 40px 40px 40px
        border: 1.5px solid rgb(46, 45, 50)
        padding: 30px 40px 30px 30px
        border-radius: 5px
        display: flex
        gap: 30px
        &_image-block
            display: flex
            flex-direction: column
            gap: 15px
            img
                width: 100px
                height: 134px
                border-radius: 10px
            label
                svg
                    color: rgb(160, 160, 160)
                    width: 18px
                    height: 18px
                    border-radius: 5px
                    background: rgb(46, 45, 50)
                    padding: 10px
                    cursor: pointer
                    transition: 0.2s
                svg:active,
                svg:hover
                    background: #4D4D4D
            input
                display: none
        &_content
            display: flex
            flex-direction: column
            gap: 20px
            &_title
                width: calc( 100% - 40px )
                border-radius: 5px !important
                background: rgb(46, 45, 50) !important
            &_param
                display: flex
                justify-content: space-between
                width: 100%
                gap: 40px
                input
                    border-radius: 5px
                    background: rgb(46, 45, 50)
                    flex-grow: 1
                    width: calc( 100% - 40px )
                input:nth-child(3)
                    flex-grow: 2
                    width: calc( 100% - 40px )
                    width: auto
            &_buttons
                color: rgb(255, 255, 255)
                font-family: Montserrat
                font-size: 14px
                font-weight: 500
                line-height: 17px
                letter-spacing: 0%
                display: flex
                gap: 20px
                justify-content: flex-end
                &_cancel
                    padding: 0px 16px
                    height: 38px
                    border-radius: 5px
                    background: rgb(45, 45, 45)
                    transition: 0.2s
                &_save
                    padding: 0px 22px
                    height: 38px
                    border-radius: 5px
                    transition: 0.2s
                    background: rgb(140, 83, 253)
            textarea
                border-radius: 5px
                background: rgb(46, 45, 50)
                outline: none
                padding: 16px 20px
                color: rgb(225, 225, 225)
                font-family: Montserrat
                font-size: 16px
                font-weight: 500
                line-height: 25px
                letter-spacing: 4%
                resize: none
                border: none

@media (max-width: 860px)
    .edit-anime
        flex-direction: column
        &_image-block
            flex-direction: row
        &_content
            &_param
                flex-wrap: wrap
                input
                    width: auto

@media (max-width: 550px)
    .edit-anime
        margin: -20px 15px 15px 15px
        padding: 20px 12px
        &_content
            gap: 15px
            &_title
                font-size: 12px
                line-height: 15px
                padding: 10px 12px
                width: calc( 100% - 30px )
            &_param
                font-size: 12px
                line-height: 15px
                gap: 15px
            textarea
                padding: 10px 12px
                font-size: 12px
                line-height: 15px
            &_buttons
                gap: 13px
                &_save
                    font-size: 12px
                    line-height: 15px
                    padding: 0px 12px
                    height: 28px
                &_cancel
                    font-size: 12px
                    line-height: 15px
                    height: 28px
                    padding: 0px 12px


</style>