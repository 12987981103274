<template>
    <div v-if="switchMenu" @click="close()" class="burger">
        <div class="burger-menu">
            <div class="burger-menu_item" v-for="menu in menus" :key="menu.id" @click="close()">
                <router-link :to="menu.link">
                    <div>
                        <p>{{ menu.title }}</p>
                    </div>
                </router-link>
            </div>
            <div class="burger-menu_item" v-if="this.$store.getters.ACCOUNT" @click="close()">
                <router-link :to="'/account/' + this.$store.getters.ACCOUNT.id">
                    <div>
                        <p>Профиль</p>
                    </div>
                </router-link>
            </div>
            <div class="burger-menu_item" v-if="this.$store.getters.ACCOUNT" @click="close()">
                <router-link to="/account/settings">
                    <div>
                        <p>Настройки</p>
                    </div>
                </router-link>
            </div>
            <div class="burger-menu_item" v-if="!this.$store.getters.ACCOUNT" @click="close()">
                <router-link to="/login">
                    <div>
                        <p>Войти</p>
                    </div>
                </router-link>
            </div>
            <div class="burger-menu_item" v-if="this.$store.getters.ACCOUNT && userHasAdminRole || userHasModerRole" @click="close()">
                <router-link to="/account/adminpanel">
                    <div>
                        <p>Панель управления</p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BurgerMenu',
    components: {

    },
    props: [
        'switchMenu',
    ],
    data() {
        return {
            menus: [
                {
                    id: 1,
                    title: 'Каталог',
                    link: '/anime/1'
                },
            ],
        }
    },
    computed: {
        userHasAdminRole() {
            if(this.$store.state.userRole !== 'user') {
                return this.$store.state.userRole.some(role => role === 'admin'); 
            }
        },
        userHasModerRole() {
            if(this.$store.state.userRole !== 'user') {
                return this.$store.state.userRole.some(role => role === 'moder');
            }
        }
    },
    methods: {
        close() {
            this.$emit('closeBurgerMenu', false);
        },
    }
}
</script>

<style lang="sass" scoped>
    .burger
        position: absolute
        animation: ani 0.4s forwards
        width: 100%
        height: 100vh
        background-color: rgba(0, 0, 0, 0.80)
        top: 0
        z-index: 999
    @keyframes ani
        0%
            opacity: 0
        100%
            opacity: 1
    @keyframes item
        0%
            height: 0px
        100%
            height: 54px
    @keyframes text
        0%
            display: none
            opacity: 0
        100%
            display: block
            opacity: 1
    .burger-menu
        max-width: 1200px
        margin: auto
        display: flex
        flex-direction: column
        gap: 10px
        margin-top: 110px
        &_item
            display: flex
            a
                color: rgb(255, 255, 255)
                font-family: Montserrat
                font-size: 18px
                font-weight: 500
                line-height: 22px
                letter-spacing: 2%
                text-decoration: none
                animation: text 0.8s forwards
                div
                    width: 320px
                    height: 54px
                    border-radius: 5px
                    background: rgb(31, 30, 35)
                    display: flex
                    justify-content: center
                    align-items: center
                    transition: 0.3s
                    animation: item 0.8s forwards
                div:active,
                div:hover
                    background: #4D4D4D

@media (max-width: 1200px)
    .burger-menu
        margin-left: 25px
</style>