<script setup>
import {ref, defineProps, computed, watch} from "vue";
import EnshiPlayerEpisode from "@/components/VideoPlayer/EnshiPlayerEpisode.vue";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  anime_id: {
    type: Number,
    required: true,
  },
  episodes: {
    type: Array,
    required: true,
    default: []
  }
});

const emit = defineEmits(['update:modelValue']);

const selectedEpisode = computed({
  get: () =>props.modelValue,
  set: (newValue) => {
    emit('update:selectedEpisode', newValue);
  }
});
</script>

<template>
  <div v-if="episodes.length" v-grab-scroll class="EnshiPlayerEpisodeList">
    <EnshiPlayerEpisode
      v-for="(item, idx) of episodes"
      :key="item.series_number"
      :episode_number="item.series_number"
      :episode_poster="item.poster_url"
      :not_viewed="selectedEpisode?.series_number !== item.series_number"
      :is_active="selectedEpisode?.series_number === item.series_number"
      @click="emit('update:modelValue', item)"
    />
  </div>
</template>

<style lang="scss">
.EnshiPlayerEpisodeList {
  overflow-x: auto;
  display: flex;
  gap: clamp(16px, 4vw, 24px);
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>