<template>
    <div v-if="userHasAdminRole || userHasModerRole" class="scomment">
        <div class="scomment_search">
            <input type="text" v-model="searchInput" placeholder="Введите имя комментатора">
            <button @click="searchComment(searchInput)">Поиск</button>
        </div>
        <div class="scomment_content">
            <div>

            </div>
        </div>
    </div>
    <div v-else class="access-error">
        <p>У вас нету доступа!</p>
        <p>У вас должен быть доступ "Администратор"</p>
    </div>
</template>

<script>
import { getCookie } from "@/custom/getCookie";
import axios from 'axios'

export default {
    name: 'SearchComments',
    components: {
    },
    data() {
        return {
            searchInput: '',
            animeSearch: '',
            openEditForm: false,
            idEditAnime: {},
        }
    },
    computed: {
        userHasAdminRole() {
            return this.$store.state.userRole.some(role => role === 'admin');
        },
        userHasModerRole() {
            return this.$store.state.userRole.some(role => role === 'moder');
        }
    },
    methods: {
        searchComment(Anime) {
        },
    },
}
</script>

<style lang="sass" scoped>

    .access-error
        width: 100%
        height: 30%
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        p
            margin: auto
            font-size: 30px
            text-align: center
            color: white
    .scomment
        border-radius: 10px
        background: rgb(31, 30, 35)
        width: 100%
        &_search
            display: flex
            flex-direction: row
            margin: 40px
            gap: 40px
            justify-content: space-between
            input
                flex-grow: 1
            button
                width: 170px
                height: 54px
                border-radius: 5px
                background: rgb(140, 83, 253)
                font-size: 18px
                font-weight: 500
                line-height: 22px
                letter-spacing: 2%
                transition: 0.2s
            button:active,
            button:hover
                background: #9370d8

@media (max-width: 1025px)
    .scomment
        margin-right: 25px

@media (max-width: 650px)
    .scomment
        border-radius: 7px
        &_search
            margin: 28px 15px
            gap: 15px
            input
                font-size: 12px
                line-height: 15px
                padding: 11.2px 15px 11.8px 15px
                border-radius: 5px
            button
                width: 90px
                height: 38px
                font-size: 12px
                line-height: 15px

@media (max-width: 550px)
    .scomment
        margin: 0px 15px
        width: auto
</style>