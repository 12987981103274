<template>
    <div class="edit-user">
        <h2>Редактирование пользователя</h2>
        <input type="text" name="name" placeholder="Имя пользователя" />
        <input type="text" name="email" placeholder="Email" />
        <input type="text" name="rank" placeholder="ранг(не менять)" />
        <input type="text" name="password" placeholder="Пароль (в след. обновлении)" />
        <div class="edit-user_photo">
            <p>Изменить обложку</p>
            <input type="file" @change="onCoverChange" id="cover" name="cover" accept="image/png, image/jpeg" />
        </div>
        <div class="edit-user_photo">
            <p>Изменить аватар</p>
            <input type="file" @change="onAvatarChange" id="avatar" name="avatar" accept="image/png, image/jpeg" />
        </div>
        <div class="edit-user_btns">
            <button class="edit-user_btns_cancel">Отменить</button>
            <button class="edit-user_btns_save">Сохранить</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EditUser',
    components: {
        
    },
}
</script>

<style lang="sass" scoped>

    h2
        color: rgb(255, 255, 255)
        font-family: Montserrat
        font-size: 23px
        font-weight: 600
        line-height: 28px
        letter-spacing: 4%
    input[type='file']
        color: rgb(255, 255, 255)
        font-family: Montserrat
    .edit-user
        display: flex
        flex-direction: column
        gap: 20px
        margin-bottom: 20px
        &_photo
            display: flex
            flex-direction: column
            gap: 10px
            p
                color: rgb(255, 255, 255)
                font-family: Montserrat
                font-size: 16px
                font-weight: 500
                line-height: 20px
                letter-spacing: 3%
        &_btns
            color: rgb(255, 255, 255)
            font-family: Montserrat
            font-size: 14px
            font-weight: 500
            line-height: 17px
            letter-spacing: 0%
            display: flex
            gap: 20px
            justify-content: flex-end
            &_cancel
                padding: 0px 16px
                height: 38px
                border-radius: 5px
                background: rgb(45, 45, 45)
                transition: 0.2s
            &_save
                padding: 0px 22px
                height: 38px
                border-radius: 5px
                transition: 0.2s
                background: rgb(140, 83, 253)
                
</style>