<template>
    <form @submit.prevent="sendSupport" style="display: flex; gap: 30px;">
        <input v-model="support.text" style="width: 100%;" type="text" placeholder="Комментарий...">
        <button class="button-form">Отправить</button>
    </form>
</template>

<script>
import { getCookie } from "@/custom/getCookie";
import axios from 'axios'
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: 'EnshiPlayerComment',
    data() {
        return {
            support: {
                text: '',
            },
        }
    },
    methods: {
        sendSupport()
        {
            if(this.$store.getters.ACCOUNT != '') {
                if(this.support.text != '') {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${getCookie('access_token')}`
                    axios.post(this.$store.getters.BACKENDURL + '/api/support/message', {
                        message: this.support.text
                    }).then((response) => {
                        toast("Сообщение успешно отправлено администрации ENSHI! Если потребуется, администрация может связаться с вами по вашей почте!", {
                        "theme": "colored",
                        "type": "success",
                        "dangerouslyHTMLString": true
                        }),
                        this.support.text = ''
                    }).catch((errors) => {
                        console.log(errors)
                    });
                } else {
                    toast("Комментарий не может быть пустым!", {
                        "theme": "colored",
                        "type": "error",
                        "dangerouslyHTMLString": true
                    });
                }
            } else {
                this.$router.push('/login')
            }
        },
    }
}
</script>