<template>
    <div class="amenu">
        <div v-for="point in menu" :key="point.id" :id="point.id" class="amenu_item" @click="menuActive(point.id)">
            <font-awesome-icon :icon="point.icon" />
            <p> {{ point.name }} </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdminMenu',
    components: {

    },
    data() {
        return {
            menu: [
                {
                    id: 0,
                    name: 'Поиск аниме',
                    icon: 'fa-solid fa-magnifying-glass',
                },
                {
                    id: 1,
                    name: 'Пользователи',
                    icon: 'fa-solid fa-user-group',
                },
                {
                    id: 2,
                    name: 'Комментарии',
                    icon: 'fa-regular fa-message',
                },
                {
                    id: 3,
                    name: 'Обновить БД',
                    icon: 'fa-solid fa-rotate-right',
                },
            ]
        }
    },
    mounted() {
        this.activeButton();
    },
    methods: {
        activeButton() {
            let elements = document.getElementsByClassName('amenu_item');

            Array.from(elements).forEach(element => {
                if (element.id === '0') {
                    element.classList.add('active');
                }
            });
        },
        menuActive(id) {
            this.$emit('activeMenu', id);
            let elements = document.getElementsByClassName('amenu_item');

            Array.from(elements).forEach(element => {
                if (element.id == id) {
                    element.classList.add('active');
                } else {
                    element.classList.remove('active');
                }
            });
        }
    },
}
</script>

<style lang="sass" scoped>
    .active
        p
            color: white !important
        svg
            color: white !important
    .amenu
        display: flex
        flex-direction: column
        border-radius: 5px
        &_item
            display: flex
            flex-direction: row
            align-items: center
            background: rgb(31, 30, 35)
            gap: 15px
            cursor: pointer
            width: 254px
            height: 87.5px
            justify-content: center
            border-bottom: 1.5px solid rgb(41, 40, 45)
            border-top: 1.5px solid rgb(41, 40, 45)
            transition: 0.2s
            svg
                width: 20px
                height: 20px
                color: #6E6E6E
                transition: 0.2s
            p
                color: rgb(110, 110, 110)
                font-family: Montserrat
                font-size: 20px
                font-weight: 500
                line-height: 120%
                letter-spacing: 0%
                transition: 0.2s
        &_item:first-child
            border-radius: 5px 5px 0px 0px
            border-top: none
        &_item:last-child
            border-radius: 0px 0px 5px 5px
            border-bottom: none
        &_item:active,
        &_item:hover
            svg
                color: white
            p
                color: white

@media (max-width: 1025px)
    .amenu
        margin-left: 25px
        &_item
            width: 50px
            p
                display: none

@media (max-width: 550px)
    .amenu
        flex-direction: row
        justify-content: center
        margin-left: 0px
        &_item
            height: 50px
            width: 86.25px
            border-bottom: none
            border-top: none
            border-right: 1.5px solid rgb(41, 40, 45)
            border-left: 1.5px solid rgb(41, 40, 45)
        &_item:first-child
            border-radius: 5px 0px 0px 5px
            border-left: none
        &_item:last-child
            border-radius: 0px 5px 5px 0px
            border-right: none
</style>