<template>
    <!-- <section v-if="BannerSlider.length > 0" class="background" :style="`background-image: url(${this.$store.getters.BACKENDURL}${BannerSlider[activeSlideIndex].slide_url})`">
    </section> -->
    <video v-if="BannerSlider.length > 0" ref="videoBanner" class="background" style="object-fit: cover;" :src="`${BannerSlider[activeSlideIndex].slide_url}`" autoplay muted />
    <div class="banner">
        <div class="banner_main-banner">
            <h1 v-if="BannerSlider.length > 0">{{ BannerSlider[activeSlideIndex].anime.title }}</h1>
            <div v-if="BannerSlider.length > 0" class="banner_main-banner_options">
                <p>
                    Год:
                    <span>{{ BannerSlider[activeSlideIndex].anime.year }} г.</span>
                </p>
                <p>
                    Эпизоды:
                    <span>{{ BannerSlider[activeSlideIndex].anime.episodes_aired }}/{{ BannerSlider[activeSlideIndex].anime.episodes_total }}</span>
                </p>
                <p>
                    Жанр:
                    <span>{{ BannerSlider[activeSlideIndex].anime.anime_genres[0] }}, {{ BannerSlider[activeSlideIndex].anime.anime_genres[1] }}</span>
                </p>
            </div>
            <p v-if="BannerSlider.length > 0" class="banner_main-banner_description">{{ BannerSlider[activeSlideIndex].anime.description }}</p>
            <div class="banner_main-banner_block-buttons">
                <router-link v-if="BannerSlider.length > 0" :to="'/view/anime-' + BannerSlider[activeSlideIndex].anime.id">Смотреть <font-awesome-icon icon="fa-solid fa-play" /></router-link>
                <font-awesome-icon class="banner_main-banner_block-buttons_favourites" style="color: #6E6E6E;" icon="fa-solid fa-bookmark" />
            </div>
        </div>
        <div class="banner_slider">
            <swiper
                v-show="BannerSlider.length > 0"
                class="swiper"
                :slides-per-view="3.60"
                :breakpoints="breakpoints"
                :loop="true"
                @slideChange="handleSlideChange"
                @swiper="onSwiper"
                ref="mySwiper"
            >
                <swiper-slide v-for="slide in BannerSlider" :key="slide.id" :id="slide.id - 1" class="swiper-slide">
                    <img v-if="slide.anime" :src="slide.anime.poster_url" alt="Слайд">
                    <img v-else src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме">
                </swiper-slide>
            </swiper>
            <div @click="goToPrevSlide" class="swiper-button-prev">
                <font-awesome-icon icon="fa-solid fa-chevron-left" size="xl" style="color: #fff;" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';


export default {
    name: 'Banner',
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            BannerSlider: [],
            activeSlideIndex: 0,
            breakpoints: {
                300: {
                    slidesPerView: 2.50,
                },
                700: {
                    slidesPerView: 2.50,
                },
                1200: {
                    slidesPerView: 3.60,
                },

            },
            swiper: null,
        }
    },
    created() {
        axios.get(`${this.$store.getters.BACKENDURL}/api/main-banner`).then(response => (
            this.BannerSlider = response.data
        )).catch((errors) => {
            console.log(errors)
        });
    },
    mounted() {
        // const swiper = new Swiper('.swiper', {
        // direction: 'horizontal',
        // loop: true,
        // slidesPerView: 3.60,
        // breakpoints: {
        //     300: {
        //         slidesPerView: 2.50,
        //     },
        //     700: {
        //         slidesPerView: 2.50,
        //     },
        //     1200: {
        //         slidesPerView: 3.60,
        //     },

        // },

        // pagination: {
        //     el: '.swiper-pagination',
        // },

        // navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev',
        // },
        // });
        const executeWithDelay = () => {
            const video = this.$refs.videoBanner;
            video?.addEventListener('ended', function() {
                this.currentTime = 0;
                this.play();
            });
        };

        setTimeout(executeWithDelay, 2000);
    },
    methods: {
        handleSlideChange() {
            let inter = setInterval(() => {
                let activeSlide = document.querySelector('.swiper-slide-active')
                if(this.BannerSlider.length > 0 && activeSlide !== null) {
                    this.activeSlideIndex = activeSlide.id
                    clearInterval(inter)
                }
            }, 500)
        },
        onSwiper(swiper) {
            this.swiper = swiper;
        },
        goToPrevSlide() {
            if (this.swiper) {
                this.swiper.slidePrev();
            }
        },
        goToNextSlide() {
            if (this.swiper) {
                this.swiper.slideNext();
            }
        }
    },
}
</script>

<style lang="sass" scoped>
    .swiper
        width: 801px
        height: 285px
        position: absolute
        right: 0px
        margin-top: 353px
    .swiper-button-prev
        width: 50px
        height: 50px
        margin-right: 801px
        margin-top: 485px
        cursor: pointer
    .swiper-slide
        img
            width: 196px !important
            height: 285px !important
            border-radius: 20px
    .background
        height: 568px
        left: 0px
        width: 100%
        background-size: cover
        background-position: 50%!important
        position: absolute
        pointer-events: none
        clip: rect(20px, 90%, 30px, 10%)
        z-index: -1
    .background::before
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-color: rgba(0, 0, 0, 0.6)
        z-index: 1
    .banner
        height: 568px
        z-index: 999
        display: flex
        &_main-banner
            h1
                color: #FFF
                font-family: Montserrat
                font-size: 45px
                font-style: normal
                font-weight: 600
                line-height: 47px
                letter-spacing: 1.35px
                text-transform: uppercase
                width: 500px
                margin-top: 134px
                -webkit-line-clamp: 2
                overflow: hidden
                text-overflow: ellipsis
                display: -webkit-box
                -webkit-box-orient: vertical
            &_options
                display: flex
                gap: 25px
                margin-top: 15px
                p
                    color: #9B9B9D
                    font-family: Montserrat
                    font-size: 16px
                    font-style: normal
                    font-weight: 500
                    line-height: normal
                    span
                        color: #FFF
                        font-family: Montserrat
                        font-size: 16px
                        font-style: normal
                        font-weight: 500
                        line-height: normal
            &_description
                color: #E1E1E1
                font-family: Montserrat
                font-size: 16px
                font-style: normal
                font-weight: 500
                line-height: 136%
                letter-spacing: 0.32px
                margin-top: 30px
                width: 505px
                -webkit-line-clamp: 3
                overflow: hidden
                text-overflow: ellipsis
                display: -webkit-box
                -webkit-box-orient: vertical
            &_block-buttons
                display: flex
                gap: 26px
                margin-top: 30px
                a
                    text-decoration: none
                    border-radius: 5px
                    background: #8C53FD
                    color: #FFF
                    leading-trim: both
                    text-edge: cap
                    font-family: Montserrat
                    font-size: 15px
                    font-style: normal
                    font-weight: 600
                    line-height: normal
                    letter-spacing: 0.9px
                    display: flex
                    align-items: center
                    justify-content: center
                    gap: 7px
                    width: 154px
                    transition: 0.2s
                a:active,
                a:hover
                    background: #9370d8
                &_favourites
                    border-radius: 5px
                    background: #2D2D2D
                    padding: 14px 16px
                    cursor: pointer
                    transition: 0.3s
                &_favourites:active,
                &_favourites:hover
                    background: #4D4D4D
        &_slider
            position: absolute
            right: 0px

@media (max-width: 1200px)
    .swiper
        width: 444px
        margin-top: 388px
    .swiper-slide
        img
            width: 165px !important
            height: 239px !important
    .swiper-button-prev
        margin-right: 430px
        margin-top: 500px
    .banner
        &_main-banner
            margin-left: 25px

@media (max-width: 700px)
    .swiper
        width: 330px
        margin-top: 400px
    .swiper-slide
        img
            width: 124px !important
            height: 180px !important
    .swiper-button-prev
        margin-right: 300px
        margin-top: 475px
    .background
        height: 459px
    .banner
        &_main-banner
            margin-left: 15px
            h1
                font-size: 32px
                line-height: 33.487px
                letter-spacing: 0.96px
                width: 355px
                margin-top: 110px
            &_options
                width: 189px
                flex-wrap: wrap
                gap: 5px 20px
                p
                    font-size: 12px
                    span
                        font-size: 12px
            &_description
                font-size: 12px
                letter-spacing: 0.24px
                margin-top: 25px
                width: 286px
            &_block-buttons
                gap: 15px
                a
                    width: 110px
                    font-size: 10px
                    letter-spacing: 0.9px
                    height: 31px
                    z-index: 998
                &_favourites
                    padding: 7.5px 9.5px

</style>