<script>
export default {
  name: 'LastCommentsItem',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
}
</script>

<template>
  <article class="LastComments__item" @click="$router.push({name: 'animepage', params:{id: data.anime.id}, hash: `#comment_${data.user_id}`})">
    <header>
      <div class="LastComments__itemHeaderWrap" @click.stop="$router.push({name: 'account', params: {id: data.user_id}})">
        <picture class="LastComments__itemAvatar">
          <img v-if="data.user.avatar" :src="this.$store.state.backendURL + '/storage/' + data.user.avatar"
               :alt="'avatar ' + this.data.user.name">
          <img v-else src="../../assets/images/AccountView/AvatarExample.png" alt="avatar_null">
        </picture>
        <div class="LastComments__itemUser">
          <p class="LastComments__itemUserName">{{ data.user.name }}</p>
          <p class="LastComments__itemUserCreatedAgo">{{ data.created_at_ago }}</p>
        </div>
      </div>
      <p class="LastComments__itemUserComment">{{ data.comment }}</p>
    </header>
    <footer @click.stop="$router.push({name: 'animepage', params:{id: data.anime.id}})">
      <svg width="22.500000" height="22.500000" viewBox="0 0 22.5 22.5" fill="none" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink">
        <path id="Vector"
              d="M19.41 0.75C20.7 0.75 21.75 1.79 21.75 3.08L21.75 19.41C21.75 20.7 20.7 21.75 19.41 21.75L3.08 21.75C1.79 21.75 0.75 20.7 0.75 19.41L0.75 3.08C0.75 1.79 1.79 0.75 3.08 0.75L19.41 0.75Z"
              stroke="#B089FF" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round"/>
        <path id="Vector"
              d="M5.41 0.75L5.41 21.75M0.75 6L5.41 6M0.75 11.25L21.75 11.25M0.75 16.5L5.41 16.5M17.08 0.75L17.08 21.75M17.08 6L21.75 6M17.08 16.5L21.75 16.5"
              stroke="#B089FF" stroke-opacity="1.000000" stroke-width="1.500000" stroke-linejoin="round"/>
      </svg>
      <p class="LastComments__itemUserAnimeTitle">{{data.anime.title}}</p>
    </footer>
  </article>
</template>

<style lang="sass">
.LastComments__item
  color: white
  min-height: clamp(192px, 35vw, 217px)
  width: clamp(220px, 35vw, 240px)
  cursor: pointer
  padding: 20px
  align-self: stretch
  box-sizing: border-box
  display: flex
  flex-direction: column
  justify-content: space-between
  border-radius: 20px
  background: linear-gradient(180.00deg, rgb(53, 52, 57), rgb(37, 36, 41) 100%)
  transition: scale .2s ease-in-out
  will-change: transform
  &:hover
    scale: 1.02


  .LastComments__itemHeaderWrap
    display: flex
    gap: 20px
    box-sizing: border-box
    align-items: center
    margin-bottom: 14px

  footer
    display: flex
    gap: 11.5px
    box-sizing: border-box
    align-items: center
    transition: all .2s ease-in-out

    &:hover
      p
        color: lighten(rgb(176, 137, 255), 10%)

      svg path
        stroke: lighten(rgb(176, 137, 255), 10%)
    svg
      flex-shrink: 0
      path
        transition: all .2s ease-in-out
    p
      transition: all .2s ease-in-out
      color: rgb(176, 137, 255)

  main
    box-sizing: border-box
    p
      color: rgb(255, 255, 255)
      font-family: Montserrat
      font-size: 14px
      font-weight: 500
      box-sizing: border-box
      line-height: 17px
      letter-spacing: 0
      text-align: left

.LastComments__itemAvatar
  box-sizing: border-box
  width: 44px
  height: 44px
  border-radius: 5px
  overflow: hidden

  img
    width: 100%
    height: 100%
    box-sizing: border-box
    object-fit: cover
    object-position: center

.LastComments__itemUser
  box-sizing: border-box
  .LastComments__itemUserName
    color: rgb(255, 255, 255)
    font-family: Montserrat
    font-size: 16px
    box-sizing: border-box
    font-weight: 600
    line-height: 20px
    letter-spacing: 0

  .LastComments__itemUserCreatedAgo
    color: rgb(130, 130, 130)
    font-family: Montserrat
    box-sizing: border-box
    font-size: 12px
    font-weight: 500
    line-height: 15px
    letter-spacing: 0


.LastComments__itemUserComment
  width: 100%
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  box-sizing: border-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  color: rgb(255, 255, 255)
  font-family: Montserrat
  font-size: 14px
  font-weight: 500
  line-height: 17px
  letter-spacing: 0
  text-align: left

.LastComments__itemUserAnimeTitle
  overflow: hidden
  text-overflow: ellipsis
  box-sizing: border-box
  display: -webkit-box
  -webkit-line-clamp: 1
  -webkit-box-orient: vertical

@media (max-width: 499px)

  .LastComments__itemUserComment, .LastComments__itemUserAnimeTitle
    font-size: 12px
    line-height: 15px

  .LastComments__itemUserName
    font-size: 14px

  .LastComments__itemUserCreatedAgo
    font-size: 10.8px
    line-height: 13px
</style>