<template>
    <div v-if="cLoader" class="loader">
        <div class="loading"></div>
    </div>
</template>

<script>
export default {
    name: 'Loader',
    components: {
        
    },
    props: [
        'cLoader'
    ],
    created() {
        document.body.style.overflow = "hidden";
    },
}
</script>

<style lang="sass" scoped>
    .loading
        margin: auto
        width: 75px
        height: 75px
        border: 10px solid rgba(162, 37, 219, 0.53)
        border-radius: 50%
        border-left-color: rgb(140, 83, 253)
        border-right-color: rgb(140, 83, 253)
        animation: loader 1.3s linear infinite
    .loader.fading-out
        opacity: 0
    .loader
        top: 0px
        bottom: 0px
        left: 0px
        right: 0px
        display: flex
        background: rgb(22, 21, 26)
        position: absolute
        transition: opacity 0.2s linear
        z-index: 9999
    @keyframes loader
        100%
            transform: rotate(360deg)
</style>