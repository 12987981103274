<template>
  <Error404/>
</template>

<script>

import Error404 from '@/components/Error404View/Error404.vue'

export default {
  name: 'Error404View',
  components: {
    Error404,
  }
}
</script>

<style lang="sass" scoped>

</style>