<template>
    <div v-if="userHasAdminRole" class="sanime">
        <div class="sanime_search">
            <input type="text" v-model="searchInput" placeholder="Введите название">
            <button @click="searchAnime(searchInput)">Поиск</button>
        </div>
        <div class="sanime_content">
            <div v-for="anime in animeSearch" :key="anime.id" class="sanime_content_item">
                <img v-if="anime.poster_url" :src="anime.poster_url" alt="Постер аниме">
                <img v-else src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме">
                <div class="sanime_content_item_desc">
                    <p>{{ anime.title }}</p>
                    <div class="sanime_content_item_desc_actions">
                        <button @click="editAnimeBtn(anime.id)">
                            <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                        </button>
                        <button v-if="anime.enabled_anime === 1">
                            <font-awesome-icon style="color: green !important;" icon="fa-solid fa-power-off" />
                        </button>
                        <button v-else>
                            <font-awesome-icon style="color: red !important;" icon="fa-solid fa-power-off" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <EditAnimeVue
            :openEditForm="openEditForm"
            :EditAnime="idEditAnime"
            @closeEdit="closeEdit"
        />
    </div>
    <div v-else class="access-error">
        <p>У вас нету доступа!</p>
        <p>У вас должен быть доступ "Администратор"</p>
    </div>
</template>

<script>
import { getCookie } from "@/custom/getCookie";
import axios from 'axios'
import EditAnimeVue from './SearchAnime/EditAnime.vue';

export default {
    name: 'SearchAnime',
    components: {
        EditAnimeVue,
    },
    data() {
        return {
            searchInput: '',
            animeSearch: '',
            openEditForm: false,
            idEditAnime: {},
        }
    },
    computed: {
        userHasAdminRole() {
            return this.$store.state.userRole.some(role => role === 'admin');
        }
    },
    methods: {
        searchAnime(Anime) {
            if(Anime !== '') {
                let token = getCookie('access_token');
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                axios.get(this.$store.getters.BACKENDURL + '/api/admin/anime/search', {
                    params: {
                        title: Anime
                    }
                }).then((response) => {
                    this.animeSearch = response.data,
                    this.openEditForm = false
                }).catch((errors) => {
                    console.log(errors);
                });
            }
        },
        editAnimeBtn(anime) {
            let token = getCookie('access_token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            axios.get(this.$store.getters.BACKENDURL + '/api/admin/anime/show-edit', {
                params: {
                    anime_id: anime
                }
            }).then((response) => {
                this.idEditAnime = response.data;
                this.openEditForm = true
                this.animeSearch = ''
            }).catch((errors) => {
                console.log(errors);
            });
        },
        closeEdit(status)
        {
            this.openEditForm = status
        }
    },
}
</script>

<style lang="sass" scoped>

    .access-error
        width: 100%
        height: 30%
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        p
            margin: auto
            font-size: 30px
            text-align: center
            color: white
    .sanime
        border-radius: 10px
        background: rgb(31, 30, 35)
        width: 100%
        &_search
            display: flex
            flex-direction: row
            margin: 40px
            gap: 40px
            justify-content: space-between
            input
                flex-grow: 1
            button
                width: 170px
                height: 54px
                border-radius: 5px
                background: rgb(140, 83, 253)
                font-size: 18px
                font-weight: 500
                line-height: 22px
                letter-spacing: 2%
                transition: 0.2s
            button:active,
            button:hover
                background: #9370d8
        &_content
            padding: 0px 40px 40px 40px
            display: flex
            flex-wrap: wrap
            gap: 40px
            &_item
                display: flex
                flex-direction: row
                align-items: center
                justify-content: center
                gap: 30px
                border: 1.5px solid rgb(46, 45, 50)
                border-radius: 10px
                width: fit-content
                max-width: 330px
                img
                    width: 100px
                    height: 134px
                    border-radius: 10px
                &_desc
                    margin-right: 20px
                    p
                        color: white
                        font-size: 21px
                        font-weight: 500
                        line-height: 26px
                        letter-spacing: 2%
                        -webkit-line-clamp: 1
                        overflow: hidden
                        text-overflow: ellipsis
                        display: -webkit-box
                        -webkit-box-orient: vertical
                    &_actions
                        margin-top: 20px
                        display: flex
                        gap: 16.3px
                        button
                            border-radius: 8.15px
                            background: rgb(46, 45, 50)
                            width: 44px
                            height: 44px
                            svg
                                height: 20px
                                width: 20px
                                color: #828282
                                transition: 0.2s
                        button:active,
                        button:hover
                            svg
                                color: white

@media (max-width: 1025px)
    .sanime
        margin-right: 25px

@media (max-width: 650px)
    .sanime
        border-radius: 7px
        &_search
            margin: 28px 15px
            gap: 15px
            input
                font-size: 12px
                line-height: 15px
                padding: 11.2px 15px 11.8px 15px
                border-radius: 5px
            button
                width: 90px
                height: 38px
                font-size: 12px
                line-height: 15px

@media (max-width: 550px)
    .sanime
        margin: 0px 15px
        width: auto
        &_content
            padding: 0px 15px 15px 15px
            gap: 15px
            &_item
                gap: 20px
                border-radius: 7px
                max-width: 251px
                img
                    width: 70px
                    height: 93.8px
                    border-radius: 7px
                &_desc
                    p
                        font-size: 14px
                        line-height: 17px
                    &_actions
                        margin-top: 15.2px
                        gap: 12.21px
                        button
                            border-radius: 5.7px
                            width: 30px
                            height: 30px
                            svg
                                width: 13.5px
                                height: 13.5px
</style>