<script setup lang="js">
  import {ref} from "vue";

  const videoPlayerRef = ref()
  const videoPlayerContainerRef = ref()
</script>

<template>
    <div ref="videoPlayerContainerRef" class="EnshiplayerWindow">
      <video ref="videoPlayerRef" id="_enshi_player" src=""></video>
      <div class="EnshiplayerWindow-interface"></div>
    </div>
</template>

<style lang="sass">
.EnshiplayerWindow
  position: relative
  width: 100%
  height: 100%
  max-height: 466px
  margin: 0 auto 22px
</style>
