<template>
    <AnimeList />
</template>

<script>
import AnimeList from '../components/AnimeView/AnimeList.vue';

export default {
    name: 'AnimeView',
    components: {
        AnimeList,
    }
}
</script>

<style lang="sass" scoped>

</style>