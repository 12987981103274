<script setup>
import {defineProps, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

import axios from 'axios';

import EnshiPlayerWindow from "@/components/VideoPlayer/EnshiPlayerWindow.vue";
import EnshiPlayerEpisodeList from "@/components/VideoPlayer/EnshiPlayerEpisodeList.vue";
import EnshiPlayerHeader from '@/components/VideoPlayer/EnshiPlayerHeader.vue';
import EnshiPlayerComment from '@/components/VideoPlayer/EnshiPlayerComment.vue';

const props = defineProps({
  kodik_url: {
    type: String,
    required: true
  },
  ep: {
    type: Boolean,
    required: true
  },
  anime_id: {
    type: Number,
    required: true
  },
  anime: {}
});

const route = useRoute()
const store = useStore()

const selectedPlayer = ref('Kodik');
const selectedVoice = ref('');
const selectedEpisode = ref(null);
const episodes = ref([]);

const fetchData = async () => {
  try {
    const response = await axios.get(`${store.getters.BACKENDURL}/api/anime/${route.params.id}/${selectedVoice.value}/eposodes`);
    episodes.value = response.data.series || [];
    if (episodes.value.length) {
      handleSelectedEpisode(episodes.value[0]);
    }
  } catch (error) {
    console.error('Ошибка при получении данных:', error);
  }
};

const handlePlayerUpdate = (newPlayer) => {
  selectedPlayer.value = newPlayer;
};

const handleVoiceUpdate = (newVoice) => {
  selectedVoice.value = newVoice;
};

const handleSelectedEpisode = (episode) => {
  selectedEpisode.value = episode;
};

const getSelectedEpisodeIndex = () => episodes.value.findIndex((episode) => episode.id === selectedEpisode.value.id)

const getCurrentEpisode = (index) => {
  selectedEpisode.value = episodes.value[Math.min(Math.max(0, index), episodes.value.length - 1)]
}

const goToNextEpisode = () => getCurrentEpisode(getSelectedEpisodeIndex() + 1);

const goToPreviousEpisode = () => getCurrentEpisode(getSelectedEpisodeIndex() - 1);

watch(selectedVoice, fetchData, {immediate: true});
</script>

<template>
  <div class="EnshiPlayer">
    <EnshiPlayerHeader
        :ep="ep"
        :selectedPlayer="selectedPlayer"
        :anime_id="+anime_id"
        :episode="selectedEpisode"
        @update-player="handlePlayerUpdate"
        @update-voice="handleVoiceUpdate"
        @next-episode="goToNextEpisode"
    />
    <iframe class="anime-page_player_kodik" v-if="selectedPlayer === `Kodik`" :src="kodik_url" frameborder="0"
            allowfullscreen allow="autoplay *; fullscreen *"></iframe>
    <EnshiPlayerWindow
        v-if="selectedPlayer === 'Enshi'"
        :episode="selectedEpisode"
        :anime="anime"
        @next-episode="goToNextEpisode"
        @previous-episode="goToPreviousEpisode"
    />
    <EnshiPlayerEpisodeList
        v-if="selectedPlayer === 'Enshi'"
        v-model="selectedEpisode"
        :anime_id="+anime_id"
        :episodes="episodes"
    />

    <br>
    <br>
    <h2 style="color: rgb(245, 245, 245); font-family: Montserrat; font-size: 15px; font-weight: 600; line-height: 18px;">
      !!! Плеер в раннем доступе, многие функции еще будут дорабатываться. О своих впечатлениях можете написать.</h2>
    <br>
    <EnshiPlayerComment/>
  </div>
</template>

<style lang="scss">
.anime-page_player_kodik {
  width: 100%;
  height: 466px;
  border-radius: 0 0 10px 10px;
}

.EnshiPlayer {

}

@media screen and (max-width: 1200px) {
  .EnshiPlayer {
    padding: 0 clamp(10px, 3.27vw, 25px);
  }
}
</style>