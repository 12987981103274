<template>
    <div v-show="showWinEval" class="shadow" @click="closeWinEval()">
        <div class="block-eval">
            <div class="block-eval_stats">
                <h1>{{ estimations.rating }}</h1>
                <p>{{ estimations.voices }} голосов</p>
            </div>
            <div class="block-eval_pick">

            </div>
            <div class="block-eval_score">
                <p>Ваша оценка</p>
                <div class="block-eval_score_block">
                    <input type="radio" id="star-10" name="rating" value="10" @click="sendEstimation(10)">
	                <label for="star-10" title="Оценка «10»" id="rating">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </label>
                    <input type="radio" id="star-9" name="rating" value="9" @click="sendEstimation(9)">
                    <label for="star-9" title="Оценка «9»" id="rating">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </label>
                    <input type="radio" id="star-8" name="rating" value="8" @click="sendEstimation(8)">
                    <label for="star-8" title="Оценка «8»" id="rating">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </label>
                    <input type="radio" id="star-7" name="rating" value="7" @click="sendEstimation(7)">
                    <label for="star-7" title="Оценка «7»" id="rating">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </label>
                    <input type="radio" id="star-6" name="rating" value="6" @click="sendEstimation(6)">
                    <label for="star-6" title="Оценка «6»" id="rating">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </label>
                    <input type="radio" id="star-5" name="rating" value="5" @click="sendEstimation(5)">
	                <label for="star-5" title="Оценка «5»" id="rating">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </label>
                    <input type="radio" id="star-4" name="rating" value="4" @click="sendEstimation(4)">
                    <label for="star-4" title="Оценка «4»" id="rating">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </label>
                    <input type="radio" id="star-3" name="rating" value="3" @click="sendEstimation(3)">
                    <label for="star-3" title="Оценка «3»" id="rating">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </label>
                    <input type="radio" id="star-2" name="rating" value="2" @click="sendEstimation(2)">
                    <label for="star-2" title="Оценка «2»" id="rating">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </label>
                    <input type="radio" id="star-1" name="rating" value="1" @click="sendEstimation(1)">
                    <label for="star-1" title="Оценка «1»" id="rating">
                        <font-awesome-icon icon="fa-solid fa-star" />
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCookie } from '@/custom/getCookie';
import axios from 'axios'

export default {
    name: 'WinEval',
    components: {

    },
    props: [
        'showWinEval'
    ],
    data() {
        return {
            estimations: {},
        }
    },
    methods: {
        closeWinEval() {
            this.$emit('closeWinEval', false);
        },
        sendEstimation(estimation_anime) {
            let token = getCookie('access_token');

            if(token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                axios.post(`${this.$store.getters.BACKENDURL}/api/anime/estimation`, {
                    anime_id: this.$route.params.id,
                    estimation: estimation_anime
                }).then(response => (
                    console.log(response)
                )).catch((errors) => {
                    console.log(errors)
                });
            }
        },
        showEstimation() {
            let token = getCookie('access_token');

            if(token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                axios.post(`${this.$store.getters.BACKENDURL}/api/anime/show-estimation`, {
                    anime_id: this.$route.params.id,
                }).then(response => (
                    this.estimations = response.data,
                    console.log(this.estimations.estimation)
                )).catch((errors) => {
                    console.log(errors)
                });
                let inter = setInterval(() => {
                    if(this.estimations.estimation) {
                        for(let i = 1; i <= this.estimations.estimation; i++) {
                            document.getElementById('star-' + i).checked = true;
                        }
                        clearInterval(inter);
                    }
                }, 500)
            }
        }
    },
    mounted() {
        this.showEstimation();
    }
}
</script>

<style lang="sass" scoped>

    .shadow
        position: fixed
        animation: ani 0.4s forwards
        background-color: rgba(0, 0, 0, 0.80)
        top: 0
        z-index: 9999
        display: flex
        align-items: center
        justify-content: center
        left: 0
        right: 0
        top: 0
        bottom: 0

    .block-eval
        position: relative
        border-radius: 20px
        background: rgb(40, 40, 40)
        width: 761px
        height: 130px
        display: flex
        align-items: center
        justify-content: center
        gap: 30px
        &_stats
            h1
                color: rgb(255, 255, 255)
                font-family: Montserrat
                font-size: 50px
                font-weight: 600
                line-height: 61px
                letter-spacing: 0%
                text-align: center
            p
                color: rgb(160, 160, 160)
                font-family: Montserrat
                font-size: 16px
                font-weight: 500
                line-height: 20px
                letter-spacing: 0%
                text-align: center
        &_pick
            border: 1.5px solid rgb(160, 160, 160)
            height: 57px
        &_score
            display: flex
            flex-direction: column
            gap: 10px
            p
                color: rgb(160, 160, 160)
                font-family: Montserrat
                font-size: 14px
                font-weight: 500
                line-height: 17px
                letter-spacing: 0%
            &_block
                overflow: hidden
                width: 495px
                margin: 0 auto
                display: flex
                flex-direction: row-reverse
                input:checked ~ label
                    color: rgb(177, 126, 255)
                input:checked + label:hover,
                input:checked + label:hover ~ label,
                input:checked ~ label:hover,
                input:checked ~ label:hover ~ label,
                label:hover ~ input:checked ~ label
                    color: rgb(177, 126, 255)
                label:active
                    position: relative
            &_block:not(:checked)
                input
                    display: none
                label
                    cursor: pointer
                    color: rgb(110, 110, 110)
                    padding: 0px 5px
                    svg
                        width: 40px
                        height: 40px
                label:hover
                    color: rgb(177, 126, 255)
                label:hover ~ label
                    color: rgb(177, 126, 255)

@media (max-width: 680px)
    .block-eval
        border-radius: 16px
        width: 346px
        height: 135px
        gap: 23px
        &_stats
            h1
                font-size: 40px
                line-height: 49px
            p
                font-size: 12px
                line-height: 16px
        &_pick
            height: 94px
        &_score
            p
                font-size: 12px
                line-height: 15px
            &_block
                flex-wrap: wrap
                width: 180px
            &_block:not(:checked)
                label
                    padding: 0px 2px
                    svg
                        width: 32px
                        height: 32px
</style>