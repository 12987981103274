<template>
    <div class="pos-block">
        <LoginAndEmail />
        <ChangePassword />
    </div>
    <ChangeAvatarCoverVue />
</template>

<script>
import { getCookie } from "@/custom/getCookie";
import LoginAndEmail from '@/components/AccountSettingsView/LoginAndEmail.vue';
import ChangePassword from '@/components/AccountSettingsView/ChangePassword.vue';
import ChangeAvatarCoverVue from '@/components/AccountSettingsView/ChangeAvatarCover.vue';

export default {
    name: 'AccountSettingsView',
    components: {
        LoginAndEmail,
        ChangePassword,
        ChangeAvatarCoverVue,
    },
}
</script>

<style lang="sass" scoped>
    .pos-block
        margin-top: 85px
        display: flex
        gap: 50px

@media (max-width: 1250px)
    .pos-block
        flex-direction: column
        align-items: center
        gap: 60px
        margin-top: 75px

@media (max-width: 530px)
    .pos-block
        gap: 40px
        margin-top: 60px
</style>