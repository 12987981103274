<template>
    <div v-if="SimilarAnime.length" class="similar">
        <p class="similar_title">Связанные релизы</p>
        <div class="similar_block">
            <div v-for="anime in SimilarAnime" :key="anime.id" class="similar_block_item">
                <router-link :to="'/view/anime-' + anime.id">
                    <img v-if="!anime.poster_url" class="similar_block_item_poster" src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
                    <img v-if="anime.poster_url" class="similar_block_item_poster" :src="anime.poster_url" alt="Постер аниме" draggable="false">
                </router-link>
                <p v-if="anime.shikimori_rating >= 7" class="similar_block_item_rating green-rating">
                    <font-awesome-icon icon="fa-solid fa-star" />
                    {{ anime.shikimori_rating }}
                </p>
                <p v-else-if="anime.shikimori_rating >= 4.5" class="similar_block_item_rating orange-rating">
                    <font-awesome-icon icon="fa-solid fa-star" />
                    {{ anime.shikimori_rating }}
                </p>
                <p v-else-if="anime.shikimori_rating >= 0" class="similar_block_item_rating red-rating">
                    <font-awesome-icon icon="fa-solid fa-star" />
                    {{ anime.shikimori_rating }}
                </p>
                <p class="similar_block_item_title">{{ anime.title }}</p>
                <div class="similar_block_item_desc">
                    <p>{{ anime.year }}</p>
                    <p v-if="anime.anime_genres" class="similar_block_item_desc_genres">{{ anime.anime_genres[0] }}/<span>{{ anime.anime_genres[1] }}</span></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'SimilarAnime',
    components: {

    },
    data() {
        return {
            SimilarAnime: {},
        }
    },
    watch: {
        '$route.params.id': {
            handler(newVal, oldVal) {
                this.similarAnime(newVal);
            },
            immediate: true
        }
    },
    created() {
        this.similarAnime();
    },
    methods: {
        similarAnime(id)
        {
            axios.get(`${this.$store.getters.BACKENDURL}/api/similar/${this.$route.params.id}`).then(response => (
                this.SimilarAnime = response.data
            )).catch((errors) => {
                console.log(errors)
            });
        }
    }
}
</script>

<style lang="sass" scoped>
    .similar
        margin-top: 100px
        margin-bottom: 100px
        &_title
            color: rgb(245, 245, 245)
            font-family: Montserrat
            font-size: 23px
            font-weight: 600
            line-height: 28px
            letter-spacing: 4%
        &_block
            margin-top: 35px
            display: flex
            flex-wrap: wrap
            gap: 55px
            &_item
                max-width: 196px
                &_poster
                    width: 196px
                    height: 285px
                    border-radius: 20px
                    opacity: 0.8
                    transition: 0.2s
                &_poster:active,
                &_poster:hover
                    opacity: 1
                &_rating
                    color: rgb(255, 255, 255)
                    font-family: Montserrat
                    font-size: 14px
                    font-weight: 600
                    line-height: 17px
                    letter-spacing: 2%
                    width: 100px
                    height: 32px
                    border-radius: 0px 20px 0px 20px
                    display: flex
                    justify-content: center
                    align-items: center
                    gap: 5px
                    margin-top: -36px
                    position: relative
                &_title
                    color: rgb(245, 245, 245)
                    font-family: Montserrat
                    font-size: 15px
                    font-weight: 600
                    line-height: 18px
                    letter-spacing: 2%
                    margin-top: 15px
                    -webkit-line-clamp: 2
                    overflow: hidden
                    text-overflow: ellipsis
                    display: -webkit-box
                    -webkit-box-orient: vertical
                &_desc
                    color: rgb(155, 155, 157)
                    font-family: Montserrat
                    font-size: 14px
                    font-weight: 500
                    line-height: 17px
                    letter-spacing: 2%
                    display: flex
                    justify-content: space-between
                    margin-top: 10px
                    &_genres
                        display: flex
                        flex-wrap: wrap
                        justify-content: flex-end

@media (max-width: 1200px)
    .similar
        &_title
            padding: 0px 25px
        &_block
            padding: 0px 25px
            justify-content: center

@media (max-width: 700px)
    .similar
        &_title
            font-size: 18px
            line-height: 22px
            padding: 0px 10px
        &_block
            gap: 12px
            &_item
                max-width: 110px
                &_poster
                    width: 110px
                    height: 160px
                    border-radius: 10px
                &_rating
                    font-size: 8px
                    line-height: 10px
                    border-radius: 0px 10px 0px 10px
                    width: 52px
                    height: 18px
                    margin-top: -22px
                &_title
                    width: 110px
                    -webkit-line-clamp: 1
                    font-size: 12.5px
                    line-height: 15px
                    margin-top: 10px
                &_desc
                    width: 110px
                    font-size: 10.5px
                    line-height: 11px
                    margin-top: 5px
                    align-items: center
</style>