<template>
    <Login />
</template>

<script>
import Login from './../components/LoginView/Login.vue'

export default {
    name: 'LoginView',
    components: {
        Login,
    },
}
</script>

<style lang="sass" scoped>

</style>