<template>
  <template v-if="loading || anime.enabled_anime">
    <Head>
      <title>{{ anime.title }}</title>
      <meta name="description" :content="anime.description + ' | ENSHI'">
    </Head>
    <div class="anime-page">
      <div class="anime-page_header">
        <div class="anime-page_header_img">
          <p class="anime-page_header_img_rating">
            <img src="../../assets/images/MainView/RandomAnime/rating_icon.svg" alt="Иконка рейтинга Enshi"
                 draggable="false">
            {{ anime.enshi_rating }}
          </p>
          <img v-if="anime.poster_url" class="anime-page_header_img_poster" :src="anime.poster_url" alt="Постер аниме"
               draggable="false">
          <img v-if="!anime.poster_url" class="anime-page_header_img_poster"
               src="../../assets/images/MainView/Banner/SlideNone.png" alt="Постер аниме" draggable="false">
        </div>
        <div class="anime-page_header_description">
          <h1 class="anime-page_header_description_title">{{ anime.title }}</h1>
          <div class="anime-page_header_description_yeg">
            <p>Год: <span>{{ anime.year }} г.</span></p>
            <p>Эпизоды: <span>{{ anime.episodes_aired }}/{{ anime.episodes_total }}</span></p>
            <p>Жанр: <span v-if="anime.anime_genres">{{ anime.anime_genres[0] }}, {{ anime.anime_genres[1] }}</span></p>
          </div>
          <div v-if="targetDate && timeUntilTarget" class="anime-page_next-episode" v-html="nextEpisodeAt"></div>
          <div class="anime-page_header_description_text">
            <p :style="`-webkit-line-clamp: ${!switchUnwrapText ? '3' : 'initial'}`"
               class="anime-page_header_description_text_main" id="unwrap_text">{{ anime.description }}</p>
            <p v-if="anime.description" class="anime-page_header_description_text_btn"
               @click="switchUnwrapText = !switchUnwrapText">
              <span id="btn_unwrap_text">{{ switchUnwrapText ? 'Свернуть' : 'Развернуть' }}</span>
              <font-awesome-icon v-if="!switchUnwrapText" icon="fa-solid fa-chevron-down" size="xs"/>
              <font-awesome-icon v-else icon="fa-solid fa-chevron-up" size="xs"/>
            </p>
          </div>
          <div class="anime-page_header_description_activities">
            <button v-if="favorite === false" class="anime-page_header_description_activities_btn-favourites"
                    @click="addFavorite(this.$route.params.id)">В избранное
            </button>
            <button v-else-if="favorite === true" class="anime-page_header_description_activities_btn-favourites-delete"
                    @click="removeFavorite(this.$route.params.id)">
              <font-awesome-icon icon="fa-solid fa-trash-can"/>
            </button>
            <button class="anime-page_header_description_activities_btn-estimate" @click="openWinEval()">
              <font-awesome-icon icon="fa-solid fa-star" style="color: #6E6E6E;"/>
            </button>
          </div>
        </div>
      </div>
      <div class="anime-page_player">
        <EnshiPlayer
          :kodik_url="anime.player_link"
          :ep="anime.enshi_player"
          :anime_id="+this.$route.params.id"
          :anime="anime"
        />
      </div>
    </div>
    <WinEval :showWinEval="showWinEval" @closeWinEval="closeWinEval"/>
  </template>
  <template v-else>
    <section class="AnimeDenied">
      <h1>Данное аниме запрещено к&nbsp;просмотру в&nbsp;связи выставленными нам претензиями. Приносим свои извинения.</h1>
    </section>
  </template>
</template>

<script>
import {toast} from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import {getCookie} from '@/custom/getCookie';
import axios from 'axios'
import WinEval from './AnimePage/WinEval.vue';
import {Head} from '@unhead/vue/components';
import {log} from "video.js";
import EnshiPlayer from "@/components/VideoPlayer/EnshiPlayer.vue";

export default {
  name: 'AnimePage',
  components: {
    WinEval,
    Head,
    EnshiPlayer,
  },
  data() {
    return {
      switchUnwrapText: false,
      anime: {},
      animeGenres: [],
      favorite: false,
      showWinEval: false,
      targetDate: null,
      loading: true,
      timeUntilTarget: '',
      inter: '',
      formattedDate: '',
      targetDateFormate: '',
    }
  },
  watch: {
    '$route.params.id': {
      handler(newVal, oldVal) {
        this.animeView(newVal);
      },
      immediate: true
    },
  },
  created() {
    this.checkFavorite();
    this.updateTimeUntilTarget();
    setInterval(this.updateTimeUntilTarget, 60000);
    this.inter = setInterval(this.updateTimeUntilTarget, 1000);
  },
  methods: {
    animeView(id) {
      this.loading = true
      axios.get(`${this.$store.getters.BACKENDURL}/api/animeview/${this.$route.params.id}`).then(response => {
          this.anime = response.data;
          this.targetDate = response.data.next_episode_at;
      }).catch((errors) => {
        console.log(errors)
      }).finally(() => {
        this.loading = false
      });
      this.checkFavorite()
    },
    addFavorite(id) {
      let token = getCookie('access_token');

      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        axios.post(`${this.$store.getters.BACKENDURL}/api/user/add-favorite`, {
          anime_id: this.$route.params.id
        }).then(response => (
            this.favorite = true,
                toast("Успешно добавлено в избранное!", {
                  "theme": "colored",
                  "type": "success",
                  "dangerouslyHTMLString": true
                })
        )).catch((errors) => {
          console.log(errors)
        });
      } else {
        this.$router.push('/login')
      }
    },
    checkFavorite() {
      let token = getCookie('access_token');

      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        axios.post(`${this.$store.getters.BACKENDURL}/api/user/check-favorite`, {
          anime_id: this.$route.params.id
        }).then(response => (
            this.favorite = response.data.check
        )).catch((errors) => {
          console.log(errors)
        });
      }
    },
    removeFavorite(id) {
      let token = getCookie('access_token');

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      axios.post(`${this.$store.getters.BACKENDURL}/api/user/remove-favorite`, {
        anime_id: id
      }).then(response => (
          this.favorite = false,
              toast("Успешно удалено из избранного!", {
                "theme": "colored",
                "type": "success",
                "dangerouslyHTMLString": true
              })
      )).catch((errors) => {
        console.log(errors)
      });
    },
    openWinEval() {
      if (this.$store.getters.ACCOUNT) {
        this.showWinEval = true
        document.body.style.overflow = "hidden";
      } else {
        this.$router.push('/login');
      }
    },
    closeWinEval(status) {
      this.showWinEval = status
      document.body.style.overflow = "auto";
    },
    updateTimeUntilTarget() {
      const currentTime = new Date();
      if (this.targetDate !== null) {
        clearInterval(this.inter);
        const dateParts = this.targetDate.split(' ')[0].split('-');
        const day = dateParts[2];
        const month = dateParts[1];

        this.targetDateFormate = new Date(this.targetDate);
        const timeDiff = this.targetDateFormate.getTime() - currentTime.getTime();
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        if (timeDiff <= 0) {
          this.timeUntilTarget = null;
        }


        if (+day === currentTime.getDate()) {
          this.timeUntilTarget = 'сегодня';
          this.formattedDate = `${day}.${month}`;
          return
        } else if (timeDiff <= 0) {
          this.timeUntilTarget = null;
          this.targetDate = null
          return;
        }

        this.timeUntilTarget = `${days} д. ${hours} ч. ${minutes} м.`;
        this.formattedDate = `${day}.${month}`;
      }
    },
  },
  computed: {
    nextEpisodeAt() {
      if (!this.targetDate || !this.timeUntilTarget) return ''
      if (this.timeUntilTarget === 'сегодня') {
        return `<p id="episode_p">След. серия: <span id="episode_span">${this.timeUntilTarget}</span></p>`
      }
      return `<p id="episode_p">След. серия через: <span id="episode_span">${this.timeUntilTarget}</span> (${this.formattedDate})</p>`
    }
  }
}
</script>

<style lang="sass" scoped>
.AnimeDenied
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  padding: 30vh 0
  h1
    margin: 0 30px
    color: #fff
    text-align: center
    font-size: 28px
    line-height: 30px
    max-width: 700px

.anime-page
  margin-top: 80px
  margin-bottom: 100px


  &_next-episode
    border-radius: 5px
    background: rgb(45, 45, 45)
    padding: 8.5px 20px
    margin-top: 30px
    width: fit-content

  &_header
    display: flex
    flex-direction: row
    gap: 45px

    &_img
      width: 225px
      pointer-events: none

      &_rating
        border-radius: 0px 10px 0px 10px
        background: rgb(140, 83, 253)
        color: rgb(255, 255, 255)
        font-family: Montserrat
        font-size: 14px
        font-weight: 600
        line-height: 17px
        letter-spacing: 2%
        display: flex
        justify-content: center
        align-items: center
        width: 100px
        height: 32px
        gap: 10px
        margin-left: auto
        position: relative
        margin-bottom: -32px

        img
          width: 20px
          height: 20px

      &_poster
        width: 100%
        height: 319px
        border-radius: 10px

    &_description
      &_title
        color: rgb(255, 255, 255)
        font-family: Montserrat
        font-size: 23px
        font-weight: 600
        line-height: 28px
        letter-spacing: 4%
        margin-top: 19px

      &_yeg
        font-family: Montserrat
        font-size: 16px
        font-weight: 500
        line-height: 20px
        letter-spacing: 0%
        color: #828282
        display: flex
        gap: 50px
        margin-top: 14px

        span
          color: #FFFFFF

      &_text
        color: rgb(225, 225, 225)
        font-family: Montserrat
        font-size: 16px
        font-weight: 500
        line-height: 25px
        letter-spacing: 4%
        margin-top: 30px
        max-width: 679px

        &_main
          -webkit-line-clamp: 3
          overflow: hidden
          text-overflow: ellipsis
          display: -webkit-box
          -webkit-box-orient: vertical
          -webkit-transition: height 0.8s
          -moz-transition: height 0.8s
          -o-transition: height 0.8s
          transition: height 0.8s

        &_btn
          cursor: pointer
          color: rgb(110, 110, 110)
          font-size: 14px
          font-weight: 500
          line-height: 18px
          letter-spacing: 0px
          display: flex
          justify-content: flex-end
          align-items: center
          gap: 10px
          margin-top: 20.95px
          transition: 0.2s
          -webkit-touch-callout: none
          -webkit-user-select: none
          -khtml-user-select: none
          -moz-user-select: none
          -ms-user-select: none
          user-select: none

        &_btn:active,
        &_btn:hover
          opacity: 0.8

      &_activities
        display: flex
        gap: 30px
        margin-top: -9.06px

        &_btn-favourites
          color: rgb(255, 255, 255)
          font-family: Montserrat
          font-size: 15px
          font-weight: 600
          line-height: 18px
          letter-spacing: 4%
          border: none
          outline: none
          border-radius: 0px 0px 5px 5px
          background: rgb(140, 83, 253)
          display: flex
          justify-content: center
          align-items: center
          width: 198px
          height: 44px
          cursor: pointer
          transition: 0.2s

        &_btn-favourites:active,
        &_btn-favourites:hover
          background: #9370d8

        &_btn-favourites-delete
          color: rgb(255, 255, 255)
          font-family: Montserrat
          font-size: 15px
          font-weight: 600
          line-height: 18px
          letter-spacing: 4%
          border: none
          outline: none
          border-radius: 5px
          background: #F04343
          display: flex
          justify-content: center
          align-items: center
          width: 44px
          height: 44px
          cursor: pointer
          transition: 0.2s

        &_btn-estimate
          border: none
          outline: none
          cursor: pointer
          border-radius: 5px
          background: rgb(45, 45, 45)
          width: 44px
          height: 44px
          display: flex
          justify-content: center
          align-items: center
          transition: 0.3s

          svg
            width: 20px
            height: 20px

        &_btn-estimate:active,
        &_btn-estimate:hover
          background: #4D4D4D

  &_player
    margin-top: 80px

    &_kodik
      width: 100%
      height: 466px
      border-radius: 10px

@media (max-width: 1200px)
  .anime-page
    &_header
      &_img
        margin-left: 25px

@media (max-width: 1000px)
  .anime-page
    &_header
      gap: 30px

      &_img
        margin-left: 25px

      &_description
        &_yeg
          flex-wrap: wrap
          gap: 15px

          p:first-child
            margin-right: 35px

          p:nth-child(2)
            margin-right: 35px

        &_text
          max-width: 448px

          &_btn
            margin-top: 10px
            justify-content: flex-start

        &_activities
          margin-top: 40px

    &_player
      width: 100%

      &_kodik
        border-radius: 0px

@media (max-width: 670px)
  .anime-page
    margin-top: 40px

    &_next-episode
      padding: 6.5px 10px

    &_header
      flex-direction: column

      &_img
        margin: auto
        width: 200px

        &_poster
          width: 200px
          height: 286px

      &_description
        margin: auto
        padding: 0px 15px

        &_title
          font-size: 18px
          line-height: 22px
          margin-top: 0px

        &_yeg
          margin-top: 10px
          font-size: 15px
          line-height: 15px
          gap: 12px

          p:first-child
            margin-right: 25.5px

          p:nth-child(2)
            margin-right: 25.5px

        &_text
          font-size: 14px
          line-height: 18.75px
          margin-top: 30px

          &_btn
            font-size: 12.5px
            line-height: 13.5px
            margin-top: 9.5px
            gap: 7.5px

        &_activities
          margin-top: 30px
          gap: 23px

          &_btn-favourites
            font-size: 11.25px
            line-height: 14px
            width: 153px
            height: 33px
            border-radius: 0px 0px 3.75px 3.75px

          &_btn-favourites-delete
            width: 34px
            height: 33px
            border-radius: 3.75px

          &_btn-estimate
            width: 34px
            height: 33px
            border-radius: 3.75px

            svg
              width: 15px
              height: 15px
</style>