<template>
  <div class="player-header">
    <div class="p-head-controls">
      <div class="p-head-select-block">
        <span>Плеер: </span>
        <div class="p-head-select">
          <select v-model="selectPlayer" @change="onPlayerChange">
            <option v-if="ep" value="Enshi">Enshi</option>
            <option value="Kodik">Kodik</option>
          </select>
        </div>
      </div>
      <span class="stick" v-if="ep && selectedPlayer === 'Enshi'">|</span>
      <div v-if="ep && selectedPlayer === 'Enshi'" class="p-head-select-block">
        <span>Озвучка: </span>
        <div class="p-head-select">
          <select v-model="selectedVoice" @change="onVoiceChange">
            <option v-for="voice in voices" :key="voice.id" :value="voice.id">{{ voice.name }}</option>
          </select>
        </div>
      </div>
      <div v-if="ep && selectedPlayer === 'Enshi' && episode" class="block-series">
        <span>Серия:</span>
        <p>{{ episode.series_number }}</p>
      </div>
      <div v-if="ep && selectedPlayer === 'Enshi'" @click="$emit('nextEpisode')" style="cursor: pointer;"
           class="p-block-next-series-button-small">
        <span>Следующая серия</span>
        <img src="../../assets/player/img/gray-right-arrow.svg" alt="Следующая серия">
      </div>
    </div>
    <div v-if="ep && selectedPlayer === 'Enshi'" @click="$emit('nextEpisode')" style="cursor: pointer;"
         class="p-block-next-series-button">
      <span>Следующая серия</span>
      <img src="../../assets/player/img/gray-right-arrow.svg" alt="Следующая серия">
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EnshiPlayerHeader',
  emits: ['nextEpisode'],
  props: {
    ep: {
      type: Boolean,
      required: true
    },
    selectedPlayer: {
      type: String,
      required: true,
    },
    anime_id: {
      type: Number,
      required: true,
    },
    episode: {}
  },
  data() {
    return {
      selectPlayer: 'Kodik',
      selectedVoice: '',
      voices: {},
    }
  },
  watch: {
    ep(newValue) {
      if (newValue === true) {
        this.selectPlayer = 'Enshi';
        this.onPlayerChange({target: {value: 'Enshi'}});
      }
    },
    selectedVoice(newValue) {
      this.$emit('update-voice', newValue);
    },
    '$route.params.id': {
      handler(newVal, oldVal) {
        this.updatePlayer();
      },
      immediate: true
    },
  },
  mounted() {
    if (this.ep) {
      this.selectPlayer = 'Enshi';
      this.onPlayerChange({target: {value: 'Enshi'}});
    }
  },
  methods: {
    updatePlayer() {
      this.selectPlayer = 'Kodik';
      this.onPlayerChange({target: {value: 'Kodik'}});
    },
    onPlayerChange(event) {
      const selectedPlayer = event.target.value;
      this.$emit('update-player', selectedPlayer);
      if (selectedPlayer === 'Enshi') {
        this.getVoices();
      }
    },
    onVoiceChange(event) {
      const selectedVoice = event.target.value;
      this.$emit('update-voice', selectedVoice);
    },
    getVoices() {
      axios.get(`${this.$store.getters.BACKENDURL}/api/anime/${this.anime_id}/voices`).then(response => {
        this.voices = response.data;

        if (this.voices.length > 0) {
          this.selectedVoice = this.voices[0].id;
        }
      }).catch((errors) => {
        console.log(errors)
      });
    }
  }
}
</script>

<style lang="sass" scoped>
.p-block-next-series-button-small
  display: none

@media (max-width: 900px)
  .p-block-next-series-button
    span
      display: none

@media (max-width: 650px)
  .player-header
    background: none
    display: flex
    flex-wrap: wrap
    height: 120px
    padding: 0px
    margin-bottom: 20px
  .p-head-controls
    display: flex
    flex-wrap: wrap
    gap: 20px
    justify-content: flex-start
  .stick
    display: none
  .p-head-select-block
    display: flex
    flex-wrap: wrap
    padding: 12.5px 14px
    border: 1px solid rgba(130, 130, 130, 0.2)
    border-radius: 10px
    background: rgb(31, 30, 35)

    span
      font-size: 14px
      font-weight: 500
      line-height: 17px
      letter-spacing: 0
  .p-head-select
    select
      font-size: 14px
      font-weight: 500
      line-height: 17px
      letter-spacing: 0
  .p-block-next-series-button
    display: none
  .p-block-next-series-button-small
    display: flex
    gap: 14.32px
    padding: 12.5px 14px
    border: 1px solid rgba(130, 130, 130, 0.2)
    border-radius: 10px
    background: rgb(31, 30, 35)

    span
      font-size: 14px
      font-weight: 500
      line-height: 17px
      letter-spacing: 0

    img
      width: 9.43px
      height: 13.36px
  .block-series
    padding: 12.5px 14px
    border: 1px solid rgba(130, 130, 130, 0.2)
    border-radius: 10px
    background: rgb(31, 30, 35)

    span
      font-size: 14px
      font-weight: 500
      line-height: 17px
      letter-spacing: 0

    p
      font-size: 14px
      font-weight: 500
      line-height: 17px
      letter-spacing: 0
</style>