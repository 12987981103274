<template>
    <div class="login-email">
        <form action="">
            <p>Логин</p>
            <input v-if="this.$store.getters.ACCOUNT" type="text" id="nickname" :placeholder="this.$store.getters.ACCOUNT.name">
            <p>Почта</p>
            <input v-if="this.$store.getters.ACCOUNT" type="text" id="email" :placeholder="this.$store.getters.ACCOUNT.email">
            <div class="pos-button">
                <button class="button-form">Заблокировано!</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'LoginAndEmail',
    components: {

    }
}
</script>

<style lang="sass" scoped>
    p
        color: white
    .pos-button
        display: flex
        justify-content: flex-end
        width: 365px
    .login-email
        form
            border-radius: 10px
            background: rgb(31, 30, 35)
            width: 525px
            display: flex
            align-items: center
            flex-direction: column
            padding: 15px 0px
            height: 390px
            p
                font-size: 20px
                width: 365px
                font-weight: 500
                line-height: 24px
                letter-spacing: 4%
                margin-top: 30px
            input[type="text"]
                width: 325px
                margin-top: 20px
            button
                margin-top: 30px

@media (max-width: 1250px)
    .login-email
        form
            width: 525px
            height: 360px

@media (max-width: 530px)
    .pos-button
        width: 246px
    .login-email
        width: 100%
        form
            border-radius: 7px
            width: 100%
            height: 256px
            p
                font-size: 16px
                line-height: 20px
                width: 246px
                margin-top: 15px
            input[type="text"]
                width: 216px
                margin-top: 15px
            button
                margin-top: 25px
</style>