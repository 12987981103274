import App from './App.vue'
import router from './router'
import store from './store'
import {createHead, VueHeadMixin} from '@unhead/vue'
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {faTelegram, faTwitter, faVk, faYoutube} from '@fortawesome/free-brands-svg-icons'
import {faImages, faMessage, faPenToSquare, faUser,} from '@fortawesome/free-regular-svg-icons'
import {
  faArrowRightFromBracket,
  faBars,
  faBookmark,
  faCalendarDays,
  faChartColumn,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faExpand,
  faGear,
  faMagnifyingGlass,
  faPause,
  faPlay,
  faPowerOff,
  faReply,
  faRotateRight,
  faStar,
  faSun,
  faTrashCan,
  faUserGroup,
  faUserMinus,
  faUserSecret,
  faUserSlash,
  faVolumeHigh,
  faVolumeLow,
  faVolumeXmark,
  faXmark,
  faMaximize,
} from '@fortawesome/free-solid-svg-icons'
import {directives} from "@/directives";
import {createApp} from "vue";

const app = createApp(App)

library.add(
  faUserSecret,
  faTwitter,
  faBars,
  faMagnifyingGlass,
  faSun,
  faVk,
  faUser,
  faTelegram,
  faYoutube,
  faChevronLeft,
  faBookmark,
  faPlay,
  faChevronRight,
  faStar,
  faCalendarDays,
  faRotateRight,
  faChevronDown,
  faChevronUp,
  faXmark,
  faGear,
  faArrowRightFromBracket,
  faChartColumn,
  faUserGroup,
  faMessage,
  faPenToSquare,
  faTrashCan,
  faReply,
  faPowerOff,
  faImages,
  faUserSlash,
  faUserMinus,
  faPause,
  faVolumeHigh,
  faVolumeLow,
  faVolumeXmark,
  faExpand,
  faMaximize,
)

const head = createHead();

directives.forEach(i => app.directive(...i))

app.use(store).use(router).component('font-awesome-icon', FontAwesomeIcon).use(head).mixin(VueHeadMixin).mount('#app')
