<template>
    <div v-if="!request">
        
    </div>
    <div v-else class="pos-adminmenu">
        <AdminMenuVue
            @activeMenu="activeMenu"
        />
        <SearchAnimeVue
            v-if="menuActive === 0"
        />
        <SearchUsersVue
            v-if="menuActive === 1"
        />
        <SearchCommentsVue
            v-if="menuActive === 2"
        />
    </div>
    <section>

    </section>
</template>

<script>
import AdminMenuVue from '@/components/AdminPanelView/AdminMenu.vue'
import SearchAnimeVue from '@/components/AdminPanelView/SearchAnime.vue'
import { getCookie } from '@/custom/getCookie';
import axios from 'axios'
import SearchUsersVue from '@/components/AdminPanelView/SearchUsers.vue';
import SearchCommentsVue from '@/components/AdminPanelView/SearchComments.vue';

export default {
    name: 'AdminPanelView',
    components: {
        AdminMenuVue,
        SearchAnimeVue,
        SearchUsersVue,
        SearchCommentsVue,
    },
    data() {
        return {
            request: false,
            menuActive: 0,
        }
    },
    created() {
        this.checkUserRole();
    },
    methods: {
        checkUserRole() {
            let token = getCookie('access_token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            axios.post(`${this.$store.getters.BACKENDURL}/api/admin/check`).then(response => (
                this.request = true
            )).catch((errors) => {
                console.log(errors)
                this.$router.push('/404')
            });
        },
        activeMenu(id) {
            this.menuActive = id;
        }
    },
}
</script>

<style lang="sass" scoped>
    .pos-adminmenu
        margin-top: 60px
        display: flex
        gap: 40px
        margin-bottom: 115px
        height: calc( 100% - 100px )
        min-height: 752px

@media (max-width: 1025px)
    .pos-adminmenu
        margin-top: 50px
        gap: 30px

@media (max-width: 550px)
    .pos-adminmenu
        margin-top: 40px
        flex-direction: column
        gap: 35px
</style>